import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AdShape from '../shapes/adShape';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  adTitle: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const AdDescription = ({ ad }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const renderAdTitle = () => {
    if (ad.isExternal) {
      return `${ad.title} (${t('by')} ${ad.source})`;
    }
    return ad.title;
  };

  return (
    <div className={classes.adTitle}>
      <Typography className={classes.adTitle} elecolor={'primary'} align={'left'} variant={'h5'}>
        {renderAdTitle()}
      </Typography>
      {ad.description && (
        <div className={classes.adTitle}>
          <Typography align={'left'} color={'primary'} variant={'h6'}>
            {ad.description}
          </Typography>
        </div>
      )}
    </div>
  );
};

AdDescription.propTypes = {
  ad: AdShape,
};

export { AdDescription };
