import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { locationFor } from '../utils/location';
import { closestCityWithLongLat, getUserLocation } from '../utils/geoLocalisation';
import { globalLocationDetected, setGlobalLocationDetected } from './locationDetector';
import { useProfileServiceContext } from '../services/ServicesProvider';

const useStyles = makeStyles(() => ({
  fullPageWidth: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LocationAwarePage = ({ children, onReady }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const locations = useSelector((state) => state.locations.locations);
  const [loading, setLoading] = useState(true);

  const profileService = useProfileServiceContext();

  useEffect(() => {
    async function doDetect() {
      if (globalLocationDetected === undefined) {
        let detectedLocation = locations[0].uid;
        if (user) {
          detectedLocation = locationFor(locations, user.location);
        } else {
          const userLocation = await getUserLocation(locations, profileService);
          /* istanbul ignore next */
          if (userLocation) {
            const { latitude, longitude } = userLocation;
            detectedLocation = closestCityWithLongLat(locations, latitude, longitude);
          }
        }
        setGlobalLocationDetected(detectedLocation);
      }
      setLoading(false);
      onReady();
    }

    doDetect();
  }, [user]);

  if (loading) {
    return (
      <div className={classes.fullPageWidth}>
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('loading')}
        </Typography>
      </div>
    );
  }
  return children;
};

LocationAwarePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onReady: PropTypes.func.isRequired,
};

export default LocationAwarePage;
