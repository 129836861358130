const fieldIsValid = (field) => field !== undefined && field !== null && field !== '';

const isProfileComplete = (profile) => {
  if (profile) {
    const { fullName, location, state, email, phone } = profile;
    return (
      fieldIsValid(fullName) &&
      fieldIsValid(location) &&
      fieldIsValid(state) &&
      (fieldIsValid(email) || fieldIsValid(phone))
    );
  }
  return false;
};

export { isProfileComplete };
