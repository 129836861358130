import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  globalNotifications: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  notificationsColumn: {
    display: 'flex',
    flexDirection: 'column  ',
    width: '100%',
  },
  notifType: {
    paddingTop: 20,
    paddingBottom: 10,
  },
}));

const NotificationsBlock = ({ enabled, notifications, setNotifications, type, testPrefix }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setNotifications(event.target.name, event.target.checked);
  };

  return (
    <FormControl disabled={!enabled} className={classes.notificationsColumn}>
      <FormLabel component="label" className={classes.notifType}>
        {type}
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ 'data-testid': `${testPrefix ? testPrefix : ''}newRentals` }}
              checked={notifications.newRentals}
              onChange={handleChange}
              name="newRentals"
            />
          }
          label={t('preferences_notifications_new_rentals')}
        />
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ 'data-testid': `${testPrefix ? testPrefix : ''}pendingPayment` }}
              checked={notifications.pendingPayment}
              onChange={handleChange}
              name="pendingPayment"
            />
          }
          label={t('preferences_notifications_payment_required')}
        />
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ 'data-testid': `${testPrefix ? testPrefix : ''}completed` }}
              checked={notifications.completed}
              onChange={handleChange}
              name="completed"
            />
          }
          label={t('preferences_notifications_rental_complete')}
        />
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ 'data-testid': `${testPrefix ? testPrefix : ''}newMessages` }}
              checked={notifications.newMessages}
              onChange={handleChange}
              name="newMessages"
            />
          }
          label={t('preferences_notifications_new_messages')}
        />
      </FormGroup>
    </FormControl>
  );
};

NotificationsBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  notifications: PropTypes.object.isRequired,
  setNotifications: PropTypes.func.isRequired,
  testPrefix: PropTypes.string,
};

export default NotificationsBlock;
