import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGIN } from './index';

const SecuredRoute = ({ path, children }) => {
  const user = useSelector((state) => state.authentication.user);
  return user ? (
    children
  ) : (
    <Redirect
      to={{
        pathname: LOGIN,
        state: { from: path },
      }}
    />
  );
};

export default SecuredRoute;
