import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import noimageavailable from '../../assets/noimageavailable.png';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { fileDropped, getCroppedImg } from './croptools';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';

const useStyles = makeStyles(() => ({
  modalWindow: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: 5,
    marginBottom: 5,
  },
  cropContainer: {
    display: 'flex',
    width: 800,
    height: 800,
    background: 'yellow',
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const SelectAndCrop = ({ opened, onImageSelected, onClose, /* for tests */ imageUploadedInitialState, uploading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  /* istanbul ignore next */
  const onDrop = useCallback((acceptedFiles) => {
    /* istanbul ignore next */
    setError(false);
    setLoading(true);
    fileDropped(acceptedFiles[0], async (binaryStr) => {
      setImageUrl(binaryStr);
      setImageUploaded(true);
      setLoading(false);
    });
  }, []);

  const [imageUploaded, setImageUploaded] = useState(imageUploadedInitialState ? imageUploadedInitialState : false);
  const [imageUrl, setImageUrl] = useState(noimageavailable);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });

  /* istanbul ignore next */
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    /* istanbul ignore next */
    setCroppedAreaPixels(croppedAreaPixels);
    getCroppedImg(imageUrl, croppedAreaPixels).then((croppedImage) => {
      setCroppedImage(croppedImage);
    });
  }, []);

  useEffect(() => {
    async function doCrop() {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels, 1);
      setCroppedImage(croppedImage);
    }

    doCrop();
  }, [imageUrl, croppedAreaPixels]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const getContainerSize = () => {
    let unit;
    /* istanbul ignore next */
    if (windowSize.width < windowSize.height) {
      /* istanbul ignore next */
      unit = windowSize.width;
    } else {
      unit = windowSize.height;
    }
    /* istanbul ignore next */
    if (uploading) {
      /* istanbul ignore next */
      return {
        width: Math.min(unit, 800) - 12,
        height: Math.min(unit, 800) - 12,
        pointerEvents: 'none',
        opacity: 0.7,
      };
    }
    return {
      width: Math.min(unit, 800) - 12,
      height: Math.min(unit, 800) - 12,
    };
  };

  const renderImageComponent = () => {
    if (!imageUploaded) {
      /* istanbul ignore next */
      if (error) {
        /* istanbul ignore next */
        return (
          <>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <img alt="" src={imageUrl} style={getContainerSize()} />
            </div>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('postad_click_to_add_image')}
            </Typography>
            <Typography color={'error'} align={'left'} variant={'subtitle1'}>
              {t('postad_unable_to_upload_image')}
            </Typography>
          </>
        );
      } /* istanbul ignore next */ else if (loading) {
        /* istanbul ignore next */
        return (
          <>
            <div>
              <img alt="" src={noimageavailable} style={getContainerSize()} />
            </div>
            <Typography color={'secondary'} align={'left'} variant={'subtitle1'}>
              {t('loading')}
            </Typography>
          </>
        );
      } else {
        return (
          <>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <img alt="" src={imageUrl} style={getContainerSize()} />
            </div>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('postad_click_to_add_image')}
            </Typography>
          </>
        );
      }
    } else {
      return (
        <div data-testid={'cropImageContainer'} style={getContainerSize()}>
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            className={classes.crop}
          />
        </div>
      );
    }
  };

  const handleOnCancel = () => {
    setCroppedImage(null);
    setImageUrl(null);
    setImageUploaded(false);
    setImageUrl(noimageavailable);
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={opened} style={customStyles} appElement={document.getElementById('root')}>
      <div data-testid={'modalWindow'} className={classes.modalWindow}>
        <div className={classes.image}>{renderImageComponent()}</div>
        <div className={classes.buttons}>
          <Button
            data-testid={'saveButton'}
            disabled={/* istanbul ignore next */ !imageUploaded || croppedImage === undefined || uploading}
            className={classes.button}
            onClick={/* istanbul ignore next */ () => onImageSelected(croppedImage)}
            variant="contained"
            color="primary"
          >
            {t('action_save')}
          </Button>
          <Button
            data-testid={'cancelButton'}
            disabled={loading || uploading}
            className={classes.button}
            onClick={handleOnCancel}
            variant="contained"
            color="primary"
          >
            {t('action_cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SelectAndCrop.propTypes = {
  opened: PropTypes.bool,
  uploading: PropTypes.bool,
  onImageSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SelectAndCrop;
