import React from 'react';
import { makeStyles } from '@material-ui/core';
import PaymentInfoShape from '../shapes/paymentInfoShape';
import BillInformationLine from './billInformationLine';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { languageToLocale } from '../utils/locale';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  billingInformation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
  },
  splitter: {
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
    width: '100%',
    height: 1,
    backgroundColor: 'gray',
  },
  subTotal: {
    paddingLeft: 10,
  },
  spacer: {
    paddingTop: 20,
  },
}));

const RentBillingInformation = ({
  paymentInfo,
  showReimburseSecurityDeposit,
  showCancellationFeesAndTotal,
  cancellationPaymentInfo,
  charge,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const locale = useSelector((state) => state.authentication.locale);
  const { price, serviceFee, taxes, total, securityDeposit, cancellationFee, creditsUsed } = paymentInfo;

  const hasCancellationFees = () => {
    return cancellationFee !== undefined && cancellationFee > 0;
  };

  const hasCredits = () => {
    return creditsUsed > 0;
  };

  return (
    <div className={classes.billingInformation}>
      <div className={classes.subTotal}>
        <BillInformationLine key={`price`} price={price} title={t('paywall_price')} />
        <BillInformationLine key={`fee`} price={serviceFee} title={t('paywall_service_fee')} />
        <BillInformationLine key={`securityDeposit`} price={securityDeposit} title={t('paywall_security_deposit')} />
        {taxes &&
          taxes.map((tax) => {
            return (
              <BillInformationLine
                key={`tax_${tax.name.en}`}
                price={tax.value}
                title={tax.name[languageToLocale(locale)]}
              />
            );
          })}
        {hasCancellationFees() && (
          <BillInformationLine
            key={`cancellation_fee`}
            price={cancellationFee}
            title={t('paywall_cancellation_fee')}
            color={'error'}
          />
        )}
      </div>
      <span className={classes.splitter} />
      <div className={classes.subTotal}>
        {hasCredits() && (
          <BillInformationLine
            key={`creditsUsed`}
            price={creditsUsed}
            title={t('paywall_used_credits')}
            color={'error'}
            isCredits={true}
          />
        )}
        <BillInformationLine key={`total`} price={total} title={t('paywall_total')} />
      </div>
      {showReimburseSecurityDeposit && (
        <>
          <span className={classes.spacer} />
          <div className={classes.subTotal}>
            {charge && <BillInformationLine key={`charge`} price={charge} title={t('dispute_charge')} />}
            <BillInformationLine
              key={`reimbursement_1`}
              price={charge ? securityDeposit - charge : securityDeposit}
              title={t('paywall_reimbursement')}
              color={'error'}
            />
          </div>
        </>
      )}
      {showCancellationFeesAndTotal && (
        <>
          <span className={classes.spacer} />
          <div className={classes.subTotal}>
            <BillInformationLine
              key={`cancellation_fee`}
              price={cancellationPaymentInfo.cancellationFee}
              title={t('paywall_cancellation_fee')}
              color={'error'}
            />
            <BillInformationLine
              key={`reimbursement_2`}
              price={cancellationPaymentInfo.refundAmount}
              title={t('paywall_reimbursement')}
            />
            {cancellationPaymentInfo.creditsRefunded > 0 && (
              <BillInformationLine
                key={`reimbursement_3`}
                price={cancellationPaymentInfo.creditsRefunded}
                title={t('paywall_reimbursement')}
                isCredits={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

RentBillingInformation.propTypes = {
  paymentInfo: PaymentInfoShape.isRequired,
  showReimburseSecurityDeposit: PropTypes.bool,
  showCancellationFeesAndTotal: PropTypes.bool,
  cancellationPaymentInfo: PaymentInfoShape,
  charge: PropTypes.string,
};

RentBillingInformation.defaultProps = {
  showReimburseSecurityDeposit: false,
  showCancellationFeesAndTotal: false,
  charge: undefined,
};

export default RentBillingInformation;
