import { createModel } from '@rematch/core';
import COLLECTIONS from '../collections';
import { firebaseService } from '../services/firebaseServiceInstance';
import SORTFIEDS from '../sortFields';
import { RENT_STATUS } from './rentStatus';
import ensureUid from '../services/firebaseTools';

const DEFAULT_STATE = {
  currentRental: undefined,
  user: undefined,
  owner: undefined,
  createdRentalId: undefined,
  rentalsOwner: [],
  rentalsUser: [],
  pendingRentalsOwner: [],
  pendingRentalsUser: [],
  disputeRentalsOwner: [],
  disputeRentalsUser: [],
  lastRentals: undefined,
  currentRentalSnapshot: undefined,
  registeredRental: undefined,
  completedRentals: [],
  cancelledRentals: [],
  completedBookings: [],
  cancelledBookings: [],
};

export const createRentModel = () =>
  createModel({
    state: { ...DEFAULT_STATE },

    reducers: {
      updateCurrentRental(state, currentRental) {
        return { ...state, currentRental };
      },
      updateOwner(state, owner) {
        return { ...state, owner };
      },
      updateUser(state, user) {
        return { ...state, user };
      },
      updateRentalsOwner(state, rentalsOwner) {
        return { ...state, rentalsOwner };
      },
      updateRentalsUser(state, rentalsUser) {
        return { ...state, rentalsUser };
      },
      updatePendingRentalsOwner(state, pendingRentalsOwner) {
        return { ...state, pendingRentalsOwner };
      },
      updatePendingRentalsUsers(state, pendingRentalsUser) {
        return { ...state, pendingRentalsUser };
      },
      updateDisputeRentalsOwner(state, disputeRentalsOwner) {
        return { ...state, disputeRentalsOwner };
      },
      updateDisputeRentalsUser(state, disputeRentalsUser) {
        return { ...state, disputeRentalsUser };
      },
      updateLastRentals(state, lastRentals) {
        const newState = {
          ...state.lastRentals,
          [lastRentals[0].ad]: lastRentals,
        };
        return { ...state, lastRentals: newState };
      },
      updateCreatedRentalId(state, createdRentalId) {
        return { ...state, createdRentalId };
      },
      updateRegisteredRental(state, registeredRental) {
        return { ...state, registeredRental };
      },
      updateCurrentRentalSnapshot(state, currentRentalSnapshot) {
        return { ...state, currentRentalSnapshot };
      },
      removeLastRentalsById(state, adId) {
        const newState = {
          ...state.lastRentals,
        };
        delete newState[adId];
        return { ...state, lastRentals: newState };
      },
      updateCompletedRentals(state, completedRentals) {
        return { ...state, completedRentals };
      },
      updateCancelledRentals(state, cancelledRentals) {
        return { ...state, cancelledRentals };
      },
      updateCompletedBookings(state, completedBookings) {
        return { ...state, completedBookings };
      },
      updateCancelledBookings(state, cancelledBookings) {
        return { ...state, cancelledBookings };
      },
      clearState() {
        return { ...DEFAULT_STATE };
      },
    },

    effects: (dispatch) => ({
      // TODO cleanup this one and use register
      async loadRental({ id }) {
        const rentalDocument = await firebaseService.getDocumentById(COLLECTIONS.rental, id);
        if (rentalDocument && rentalDocument.exists()) {
          dispatch.rent.updateCurrentRental(ensureUid(rentalDocument));
        } else {
          dispatch.rent.updateCurrentRental(undefined);
        }
      },

      async registerRental({ id }) {
        const registeredRental = await firebaseService.onSnapshotById(COLLECTIONS.rental, id, (rentalDocument) => {
          if (rentalDocument && rentalDocument.exists()) {
            dispatch.rent.updateCurrentRentalSnapshot({ ...rentalDocument.data(), uid: rentalDocument.id });
          } else {
            dispatch.rent.updateCurrentRentalSnapshot(undefined);
          }
        });
        dispatch.rent.updateRegisteredRental(registeredRental);
      },

      async loadRentals(payload, rootState) {
        async function loadSubDocuments(rent) {
          const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, rent.ad);
          const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.owner);
          const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.user);
          /* istanbul ignore next */
          if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
            return {
              ad: ensureUid(adDocument),
              owner: ensureUid(ownerDocument),
              user: ensureUid(userDocument),
              rent,
            };
          }
        }

        await firebaseService.onSnapshotByCondition(
          COLLECTIONS.rental,
          [
            { key: 'owner', value: rootState.user.user.uid, operator: '==' },
            { key: 'status', value: [RENT_STATUS.APPROVED, RENT_STATUS.IN_PROGRESS], operator: 'in' },
          ],
          SORTFIEDS.mostRecentRented,
          async (rentalsOwner) => {
            const promises = [];
            rentalsOwner.forEach((r) => {
              promises.push(loadSubDocuments(r));
            });
            const ownerRentals = await Promise.all(promises);
            dispatch.rent.updateRentalsOwner(ownerRentals);
          },
        );

        await firebaseService.onSnapshotByCondition(
          COLLECTIONS.rental,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: [RENT_STATUS.APPROVED, RENT_STATUS.IN_PROGRESS, RENT_STATUS.PENDING_OWNER_REVIEW],
              operator: 'in',
            },
          ],
          SORTFIEDS.mostRecentRented,
          async (rentalsUser) => {
            const promises = [];
            rentalsUser.forEach((r) => {
              promises.push(loadSubDocuments(r));
            });
            const userRentals = await Promise.all(promises);
            dispatch.rent.updateRentalsUser(userRentals);
          },
        );
      },

      async loadPendingRentals(payload, rootState) {
        await firebaseService.onSnapshotByCondition(
          COLLECTIONS.rental,
          [
            { key: 'owner', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: [
                RENT_STATUS.PENDING_APPROVAL,
                RENT_STATUS.PENDING_PAYMENT,
                RENT_STATUS.PENDING_OWNER_REVIEW,
                RENT_STATUS.CHARGE_ACCEPTED,
              ],
              operator: 'in',
            },
          ],
          SORTFIEDS.mostRecentRented,
          (rentalsOwner) => {
            dispatch.rent.updatePendingRentalsOwner(rentalsOwner);
          },
        );

        await firebaseService.onSnapshotByCondition(
          COLLECTIONS.rental,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: [RENT_STATUS.PENDING_APPROVAL, RENT_STATUS.PENDING_PAYMENT, RENT_STATUS.CHARGE_ACCEPTED],
              operator: 'in',
            },
          ],
          SORTFIEDS.mostRecentRented,
          (rentalsUser) => {
            dispatch.rent.updatePendingRentalsUsers(rentalsUser);
          },
        );
      },

      async loadDisputeRentals(payload, rootState) {
        await firebaseService.onSnapshotByCondition(
          COLLECTIONS.rental,
          [
            { key: 'owner', value: rootState.user.user.uid, operator: '==' },
            { key: 'status', value: [RENT_STATUS.DISPUTE_OPENED], operator: 'in' },
          ],
          SORTFIEDS.mostRecentRented,
          (disputesOwner) => {
            dispatch.rent.updateDisputeRentalsOwner(disputesOwner);
          },
        );

        await firebaseService.onSnapshotByCondition(
          COLLECTIONS.rental,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            { key: 'status', value: [RENT_STATUS.DISPUTE_OPENED], operator: 'in' },
          ],
          SORTFIEDS.mostRecentRented,
          (disputesUser) => {
            dispatch.rent.updateDisputeRentalsUser(disputesUser);
          },
        );
      },

      async loadOwner({ id }) {
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, id);
        if (userDocument.exists()) {
          dispatch.rent.updateOwner(ensureUid(userDocument));
        } else {
          dispatch.rent.updateOwner(undefined);
        }
      },

      async loadUser({ id }) {
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, id);
        if (userDocument.exists()) {
          dispatch.rent.updateUser(ensureUid(userDocument));
        } else {
          dispatch.rent.updateUser(undefined);
        }
      },

      async createRental({ ad, owner, price, startDate, startTime, endDate, endTime, unit, user, location, state }) {
        const { nextBooking } = await firebaseService.callFunction('getnextbookingnumber');
        const rentalId = await firebaseService.createDocument(COLLECTIONS.rental, {
          ad,
          owner,
          price,
          startDate,
          endDate,
          startTime,
          endTime,
          unit: unit ? unit : 'day',
          user,
          location,
          state,
          status: RENT_STATUS.UNCONFIRMED,
          bookingNumber: nextBooking,
        });
        dispatch.rent.updateCreatedRentalId(rentalId);
      },

      async loadLastRentals({ adId, maxResults }) {
        const lastRentals = await firebaseService.callUnsecuredFunction('getreviews', { id: adId, maxResults });

        if (lastRentals.length === 0) {
          dispatch.rent.removeLastRentalsById(adId);
        } else {
          const users = await Promise.all(
            lastRentals.map(async (rental) => {
              return await firebaseService.getDocumentById(COLLECTIONS.users, rental.user);
            }),
          );
          const mergedData = lastRentals.map((rental, index) => {
            const userData = users[index].data();
            return {
              ...rental,
              location: userData.location,
              image: userData.photoURL,
              displayName: userData.displayName,
            };
          });
          dispatch.rent.updateLastRentals(mergedData);
        }
      },

      async loadCompletedRentals(payload, rootState) {
        const completedRentals = await firebaseService.queryDocumentsWithOperator(
          COLLECTIONS.rental,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: RENT_STATUS.COMPLETED,
              operator: '==',
            },
          ],
          SORTFIEDS.mostRecentRented,
          100,
        );

        // TODO try to clean this
        async function loadSubDocuments(rent) {
          const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, rent.ad);
          const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.owner);
          const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.user);
          /* istanbul ignore next */
          if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
            return {
              ad: ensureUid(adDocument),
              owner: ensureUid(ownerDocument),
              user: ensureUid(userDocument),
              rent,
            };
          }
        }

        const promises = [];
        completedRentals.forEach((r) => {
          promises.push(loadSubDocuments(r));
        });
        const completedRentalsWithData = await Promise.all(promises);
        dispatch.rent.updateCompletedRentals(completedRentalsWithData);
      },

      async loadCancelledRentals(payload, rootState) {
        const completedRentals = await firebaseService.queryDocumentsWithOperator(
          COLLECTIONS.rental,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: RENT_STATUS.CANCELLED,
              operator: '==',
            },
          ],
          SORTFIEDS.mostRecentRented,
          100,
        );

        // TODO try to clean this
        async function loadSubDocuments(rent) {
          const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, rent.ad);
          const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.owner);
          const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.user);
          /* istanbul ignore next */
          if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
            return {
              ad: ensureUid(adDocument),
              owner: ensureUid(ownerDocument),
              user: ensureUid(userDocument),
              rent,
            };
          }
        }

        const promises = [];
        completedRentals.forEach((r) => {
          promises.push(loadSubDocuments(r));
        });
        const cancelledRentalsWithData = await Promise.all(promises);
        dispatch.rent.updateCancelledRentals(cancelledRentalsWithData);
      },

      async loadCompletedBookings(payload, rootState) {
        const completedBookings = await firebaseService.queryDocumentsWithOperator(
          COLLECTIONS.rental,
          [
            { key: 'owner', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: RENT_STATUS.COMPLETED,
              operator: '==',
            },
          ],
          SORTFIEDS.mostRecentRented,
          100,
        );

        async function loadSubDocuments(rent) {
          const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, rent.ad);
          const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.owner);
          const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.user);
          /* istanbul ignore next */
          if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
            return {
              ad: ensureUid(adDocument),
              owner: ensureUid(ownerDocument),
              user: ensureUid(userDocument),
              rent,
            };
          }
        }

        const promises = [];
        completedBookings.forEach((r) => {
          promises.push(loadSubDocuments(r));
        });
        const completedBookingsWithData = await Promise.all(promises);
        dispatch.rent.updateCompletedBookings(completedBookingsWithData);
      },

      async loadCancelledBookings(payload, rootState) {
        const completedBookings = await firebaseService.queryDocumentsWithOperator(
          COLLECTIONS.rental,
          [
            { key: 'owner', value: rootState.user.user.uid, operator: '==' },
            {
              key: 'status',
              value: RENT_STATUS.CANCELLED,
              operator: '==',
            },
          ],
          SORTFIEDS.mostRecentRented,
          100,
        );

        async function loadSubDocuments(rent) {
          const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, rent.ad);
          const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.owner);
          const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, rent.user);
          /* istanbul ignore next */
          if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
            return {
              ad: ensureUid(adDocument),
              owner: ensureUid(ownerDocument),
              user: ensureUid(userDocument),
              rent,
            };
          }
        }

        const promises = [];
        completedBookings.forEach((r) => {
          promises.push(loadSubDocuments(r));
        });
        const cancelledBookingsWithData = await Promise.all(promises);
        dispatch.rent.updateCancelledBookings(cancelledBookingsWithData);
      },

      async reset() {
        dispatch.rent.clearState();
      },

      async clearCreatedRentalId() {
        dispatch.rent.updateCreatedRentalId(undefined);
      },

      async unregisterCurrentRental(payload, rootState) {
        if (rootState.rent.registeredRental) {
          rootState.rent.registeredRental();
          dispatch.rent.updateRegisteredRental(undefined);
        }
      },
    }),
  });
