import qs from 'qs';
import { locationFor } from './location';
import { DEFAULT_RADIUS } from '../screens/Profile';
import { globalLocationDetected } from '../components/locationDetector';
import SORTFIELDS from '../sortFields';
import { DEFAULT_RESULTS_PER_PAGE } from '../screens/SearchResults';

const getDefaultQuery = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    if (queryParams.query) {
      return queryParams.query;
    }
  }

  return '';
};

const getDefaultCategory = (categories, location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    if (queryParams.category) {
      const existingCategory = categories.find((c) => c.code === queryParams.category);
      if (existingCategory) {
        return queryParams.category;
      }
    }
  }

  return '-1';
};

const getDefaultLocation = (locations, location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    if (queryParams.location) {
      try {
        return locationFor(locations, atob(queryParams.location));
      } catch (exception) {}
    }
  }

  return globalLocationDetected;
};

const getDefaultIncludeCommercial = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    return queryParams.includeCommercial ? queryParams.includeCommercial.toLowerCase() === 'true' : true;
  }
  return true;
};

const getDefaultSearchRadius = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    if (queryParams.searchRadius) {
      try {
        const radius = parseInt(queryParams.searchRadius);
        if ([5, 10, 25, 50, 100, 250, 500].indexOf(radius) !== -1) {
          return radius;
        }
      } catch (exception) {}
    }
  }
  return DEFAULT_RADIUS;
};

const ensureNumber = (value) => {
  if (value) {
    try {
      const nbr = parseInt(value);
      if (Number.isNaN(nbr) === false) {
        return value;
      }
    } catch (exception) {}
  }
  return undefined;
};

const getDefaultFrom = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    return ensureNumber(queryParams.from);
  }
  return undefined;
};

const getDefaultTo = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    return ensureNumber(queryParams.to);
  }
  return undefined;
};

const getDefaultSortOrder = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    if (queryParams.sortOrder === 'mostRecentRented') {
      return SORTFIELDS.mostRecentRented;
    } else if (queryParams.sortOrder === 'oldest') {
      return SORTFIELDS.oldest;
    } else if (queryParams.sortOrder === 'lowestPrice') {
      return SORTFIELDS.lowestPrice;
    } else if (queryParams.sortOrder === 'mostExpensive') {
      return SORTFIELDS.mostExpensive;
    }
  }
  return SORTFIELDS.mostRecent;
};

const getDefaultResultsPerPage = (location) => {
  if (location.search) {
    const queryParams = qs.parse(location.search.substr(1));
    if (queryParams.resultsPerPage === '10') {
      return 10;
    } else if (queryParams.resultsPerPage === '50') {
      return 50;
    } else if (queryParams.resultsPerPage === '100') {
      return 100;
    }
  }
  return DEFAULT_RESULTS_PER_PAGE;
};

const buildSearchParams = (formData) => {
  const { what, location, includeCommercial, searchRadius, priceRange, category, sortOrder, resultsPerPage } = formData;
  let queryString = `&location=${encodeURI(btoa(location.uid))}`;

  if (what) {
    queryString += `&query=${encodeURI(what)}`;
  }

  if (category && category !== '-1') {
    queryString += `&category=${encodeURI(category)}`;
  }

  if (includeCommercial === false) {
    queryString += `&includeCommercial=${!!includeCommercial}`;
  }

  if (searchRadius && searchRadius !== DEFAULT_RADIUS) {
    queryString += `&searchRadius=${searchRadius}`;
  }

  if (priceRange && priceRange.from) {
    queryString += `&from=${priceRange.from}`;
  }

  if (priceRange && priceRange.to) {
    queryString += `&to=${priceRange.to}`;
  }

  if (sortOrder && sortOrder.id !== SORTFIELDS.mostRecent.id) {
    queryString += `&sortOrder=${sortOrder.id}`;
  }

  if (resultsPerPage && resultsPerPage !== DEFAULT_RESULTS_PER_PAGE) {
    queryString += `&resultsPerPage=${resultsPerPage}`;
  }
  return queryString;
};

export {
  getDefaultQuery,
  getDefaultCategory,
  getDefaultLocation,
  getDefaultIncludeCommercial,
  getDefaultTo,
  getDefaultFrom,
  getDefaultSearchRadius,
  buildSearchParams,
  getDefaultSortOrder,
  getDefaultResultsPerPage,
};
