import { createModel } from '@rematch/core';
import categories from '../assets/categories/categories.json';

export const createCategoriesModel = () =>
  createModel({
    state: {
      categories,
    },

    reducers: {},

    effects: (dispatch) => ({}),
  });
