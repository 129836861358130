import { locationFor } from './location';
import { Map } from '../components/map';
import React from 'react';

const getCoordinates = (owner, locations, ad) => {
  let latitude;
  let longitude;
  if (owner && owner.coords) {
    latitude = owner.coords.latitude;
    longitude = owner.coords.longitude;
  } else {
    if (ad) {
      const location = locationFor(locations, ad.location);
      latitude = location.latitude;
      longitude = location.longitude;
    } else {
      const location = locationFor(locations, owner.location);
      latitude = location.latitude;
      longitude = location.longitude;
    }
  }
  return { latitude, longitude };
};

const renderMap = (owner, locations, ad) => {
  const { longitude, latitude } = getCoordinates(owner, locations, ad);
  return <Map lat={parseFloat(latitude)} lng={parseFloat(longitude)} />;
};

export { renderMap, getCoordinates };
