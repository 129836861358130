import theme from 'theme';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import logo from '../assets/logo.ico';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 220,
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    paddingLeft: 60,
    height: 200,
    width: 200,
  },
}));

const DynamicLogo = () => {
  let isSmall;

  const classes = useStyles();

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isSmall = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt={''} />
      {/* istanbul ignore next */ isSmall === false && (
        <Typography color={'primary'} align={'left'} variant={'h2'}>
          Loue-tout-ca
        </Typography>
      )}
    </div>
  );
};

export { DynamicLogo };
