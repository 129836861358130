import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { useProfileServiceContext } from '../../services/ServicesProvider';
import { EDIT_PROFILE } from '../../routes';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  profileContainer: {
    paddingBottom: 20,
  },
  mainContainer: {
    paddingTop: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    width: 150,
    height: 150,
  },
  profileInfo: {
    marginLeft: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    flexDirection: 'column',
    backgroundColor: '#ededed',
  },
  editButton: {
    height: 60,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  changeAvatar: {
    position: 'relative',
    left: 115,
    top: -15,
    width: 0,
    color: 'red',
  },
}));

const Profile = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const fileSelectorRef = useRef();

  /* istanbul ignore next */
  const changeImage = () => {
    /* istanbul ignore next */
    fileSelectorRef.current.click();
  };

  const profileService = useProfileServiceContext();

  /* istanbul ignore next */
  const onFileChange = () => {
    /* istanbul ignore next */
    profileService.uploadProfileImage(user.uid, fileSelectorRef.current.files[0]);
  };

  const goToProfile = () => {
    history.push(EDIT_PROFILE);
  };

  return (
    <div className={classes.profileContainer}>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('dashboard_my_profile')}
      </Typography>

      <div className={classes.mainContainer}>
        <div className={classes.changeAvatar}>
          <IconButton color="primary" data-testid={'changeAvatar'} onClick={changeImage}>
            <AddAPhotoIcon />
          </IconButton>
          <input type="file" ref={fileSelectorRef} style={{ display: 'none' }} onChange={onFileChange} />
        </div>

        <Avatar className={classes.avatar} src={user.photoURL} />
        <div className={classes.profileInfo}>
          <Typography color={'secondary'} align={'left'} variant={'h6'}>
            {user.displayName}
          </Typography>
          <Typography color={'primary'} align={'left'} variant={'body1'}>
            {user.email}
          </Typography>
          <Typography color={'primary'} align={'left'} variant={'body1'}>
            {user.phoneNumber}
          </Typography>
          <div className={classes.editButton}>
            <Button variant="contained" color="primary" data-testid={'edit-profile'} onClick={goToProfile}>
              {t('action_edit')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Profile);
