import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  selector: {
    paddingLeft: 10,
    width: 90,
    justifyContent: 'left',
  },
}));

const resultsPerPageChoices = [10, 25, 50, 100];

const ResultsPerPageSelector = ({ resultsPerPage, onSelectResultsPerPage }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography color={'primary'} align={'left'} variant={'caption'}>
        {t('searchresults_results_per_page')}
      </Typography>
      <Select
        align={'right'}
        className={classes.selector}
        id="resultsPerPageSelector"
        value={resultsPerPage}
        onChange={(event) => onSelectResultsPerPage(event.target.value)}
      >
        {resultsPerPageChoices.map((value) => {
          return (
            <MenuItem key={`maxResults_${value}`} value={value} style={{ backgroundColor: 'white' }}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

ResultsPerPageSelector.propTypes = {
  resultsPerPage: PropTypes.number.isRequired,
  onSelectResultsPerPage: PropTypes.func.isRequired,
};

export default ResultsPerPageSelector;
