import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import SecuredRoute from '../routes/SecuredPage';
import ProfileCheck from '../routes/profileIncompleteRedirect';
import Header from './header';
import Loading from './loading';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minWidth: 388,
  },
  mainContainer: {
    display: 'flex',
    width: '80%',
    height: '80%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
  },
}));

const BasePage = ({ loading, pagePath, children, skipSecurity }) => {
  const classes = useStyles();

  const renderContainer = () => {
    if (loading) {
      return <></>;
    }
    return (
      <div className={classes.mainContainer}>
        <Container maxWidth={'xl'} id={'mainContainer'} disableGutters={true}>
          {children}
        </Container>
      </div>
    );
  };

  if (skipSecurity) {
    return (
      <div className={classes.page}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.content}>
          <Loading isLoading={loading}>{renderContainer()}</Loading>
        </div>
      </div>
    );
  }
  return (
    <SecuredRoute path={pagePath}>
      <ProfileCheck>
        <div className={classes.page}>
          <div className={classes.header}>
            <Header />
          </div>
          <div className={classes.content}>
            <Loading isLoading={loading}>{renderContainer()}</Loading>
          </div>
        </div>
      </ProfileCheck>
    </SecuredRoute>
  );
};

BasePage.propTypes = {
  loading: PropTypes.bool.isRequired,
  pagePath: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  skipSecurity: PropTypes.bool.isRequired,
};

BasePage.defaultProps = {
  skipSecurity: false,
};

export default BasePage;
