import COLLECTIONS from '../collections';
import { RENT_STATUS } from '../models/rentStatus';

class AdService {
  constructor(firebaseService, messageService) {
    this.firebaseService = firebaseService;
    this.messageService = messageService;
  }

  postAd(ad) {
    const { user, category, title, description, price, images, location, securityDeposit, unit } = ad;
    this.firebaseService.createDocument(COLLECTIONS.ads, {
      user,
      category,
      location,
      title,
      description,
      price: parseInt(price, 10),
      unit,
      images,
      createdDate: new Date(),
      active: true,
      isExternal: false,
      securityDeposit: parseInt(securityDeposit, 10),
    });
  }

  saveAd(ad) {
    const { category, title, description, price, images, location, securityDeposit, unit } = ad;
    this.firebaseService.writeToCollection(COLLECTIONS.ads, ad.uid, {
      category,
      location,
      title,
      description,
      price: parseInt(price, 10),
      images,
      unit,
      isExternal: false,
      securityDeposit: parseInt(securityDeposit, 10),
    });
  }

  async decline(rent) {
    return await this.firebaseService.callFunction('declinerent', { rentId: rent.uid });
  }

  approve(rent) {
    this.firebaseService.writeToCollection(COLLECTIONS.rental, rent.uid, {
      status: RENT_STATUS.PENDING_PAYMENT,
      unread: true,
    });
  }

  async cancel(rent) {
    return await this.firebaseService.callFunction('cancelrent', { rentId: rent.uid });
  }

  async confirm(rent) {
    return await this.firebaseService.callFunction('confirmrent', { rentId: rent.uid });
  }

  async returnItem(rent) {
    const { id, rating, review } = rent;
    await this.firebaseService.writeToCollection(COLLECTIONS.rental, id, {
      status: RENT_STATUS.PENDING_OWNER_REVIEW,
      rating,
      review,
      unread: true,
    });
  }

  async markAsRead(rent) {
    await this.firebaseService.writeToCollection(COLLECTIONS.rental, rent.uid, {
      unread: false,
    });
  }

  uploadImage(image, imageUrlReadyCallback) {
    this.firebaseService.uploadToStorage(image, imageUrlReadyCallback);
  }

  deleteImage(imageRef) {
    this.firebaseService.removeFromStorage(imageRef);
  }

  deactivate(id) {
    this.firebaseService.writeToCollection(COLLECTIONS.ads, id, {
      active: false,
      unread: false,
    });
  }

  activate(id) {
    this.firebaseService.writeToCollection(COLLECTIONS.ads, id, {
      active: true,
    });
  }

  async completeReturn(payload) {
    const { id, rating, review } = payload;
    const response = await this.firebaseService.callFunction('completerental', { id, rating, review });

    if (response.status !== 'transfered') {
      throw new Error('Unable to complete return');
    }
  }

  async openDispute(payload) {
    const { user, from, fromName, avatar, id, charge, explanation, disputeStartDate, adId } = payload;
    const disputeId = await this.messageService.createDispute({ user, from, fromName, avatar, explanation, adId });

    this.firebaseService.writeToCollection(COLLECTIONS.rental, id, {
      status: RENT_STATUS.DISPUTE_OPENED,
      charge,
      explanation,
      disputeStartDate,
      disputeId,
      unread: true,
    });
  }

  async updateDispute(rentId, payload) {
    const { charge, explanation, disputeId, owner } = payload;
    await this.messageService.createNewMessageInThread({
      threadId: disputeId,
      user: owner,
      message: explanation,
      isOwner: true,
    });

    this.firebaseService.writeToCollection(COLLECTIONS.rental, rentId, {
      charge,
      explanation,
      unread: true,
    });
  }

  async pay(rentId, userEmail) {
    const response = await this.firebaseService.callFunction('initpayment', { rentId, userEmail });
    if (!response) {
      throw new Error(`Unable to complete payment for rent ${rentId}`);
    }

    const { id, session } = response;
    this.firebaseService.writeToCollection(COLLECTIONS.rental, rentId, {
      paymentId: id,
      unread: false,
    });
    return session;
  }

  async confirmPayment(rentId) {
    return await this.firebaseService.callFunction('confirmpayment', { rentId });
  }

  async acceptCharge(rentId) {
    this.firebaseService.writeToCollection(COLLECTIONS.rental, rentId, {
      status: RENT_STATUS.CHARGE_ACCEPTED,
      unread: true,
    });
  }

  async getPaymentInformation(rentId) {
    return await this.firebaseService.callFunction('computePayment', { rentId });
  }

  async getReimbursementInformation({ rentId, callerId }) {
    return await this.firebaseService.callFunction('computeReimbursement', { rentId, callerId });
  }

  async getUpcomingRentals(adId) {
    return await this.firebaseService.callUnsecuredFunction('upcomingRentals', { adId });
  }

  async getOwnerPayout(rentId) {
    return await this.firebaseService.callFunction('computeOwnerPayout', { rentId });
  }

  async refund({ rentId, callerId }) {
    return await this.firebaseService.callFunction('refund', { rentId, callerId });
  }
}

export default AdService;
