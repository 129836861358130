import FirebaseService from './firebaseService';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

let firebaseService;

const initFirebaseService = (firebase) => {
  firebaseService = new FirebaseService(
    getFirestore(firebase),
    getStorage(firebase),
    getAuth(firebase),
    getFunctions(firebase),
  );
};

export { firebaseService, initFirebaseService };
