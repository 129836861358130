import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AccountNotConfigured from './accountNotConfigured';
import AccountUseCredits from './accountUsingCredits';
import AccountLinkedToBank from './accountLinkedToBank';
import { LINK } from '../../payoutModes';
import { useProfileServiceContext } from '../../services/ServicesProvider';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
}));

const BillingInformation = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);

  const profileService = useProfileServiceContext();

  const handleLinkAccount = async ({ payoutMode }) => {
    const { uid, payoutsEnabled } = user;
    if (payoutMode === LINK) {
      if (payoutsEnabled === undefined || payoutsEnabled === false) {
        window.location = await profileService.linkAccount(uid);
      } else {
        await profileService.useBankAccount(uid);
      }
    } else {
      await profileService.useCreditForAccount(uid);
    }
  };

  const handleUnlinkAccount = async () => {
    await profileService.unlinkAccountId(user.uid);
  };

  const renderAccountLinkedOrNot = () => {
    if (!(user && user.payoutsEnabled)) {
      return <AccountNotConfigured onLinkAccount={handleLinkAccount} />;
    }

    const { payoutMode } = user;
    if (payoutMode === undefined || payoutMode === LINK) {
      return <AccountLinkedToBank onUseCredits={handleLinkAccount} onUnlinkAccount={handleUnlinkAccount} />;
    }
    return <AccountUseCredits onLinkAccount={handleLinkAccount} />;
  };

  return <div className={classes.mainContainer}>{renderAccountLinkedOrNot()}</div>;
};

export default BillingInformation;
