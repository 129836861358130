import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import SecuredRoute from '../../routes/SecuredPage';
import { DASHBOARD, EDIT_PROFILE } from '../../routes';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tabPanel';
import General from './general';
import { useProfileServiceContext } from '../../services/ServicesProvider';
import { useSelector } from 'react-redux';
import BillingInformation from '../../components/profile/billing';
import Loading from '../../components/loading';
import { useTranslation } from 'react-i18next';
import Preferences from './preferences';
import { isProfileComplete } from '../../utils/profile';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import Reviews from './reviews';
import SaveCancelButtonsContainer from './saveCancelButtonsContainer';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  topText: {
    width: '100%',
    flex: 1,
    paddingLeft: 25,
    boxSizing: 'border-box',
  },
  tabPanel: {
    width: '75%',
    paddingTop: 20,
  },
  test: {
    backgroundColor: 'red',
    width: '100%',
  },
}));

export const DEFAULT_RADIUS = 50;

const Profile = ({ history, location }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const [generalProfile, setGeneralProfile] = useState();
  const [somethingChanged, setSomethingChanged] = useState(false);
  const [preferences, setPreferences] = useState({ radius: DEFAULT_RADIUS });
  const [selectedTab, setSelectedTab] = useState(
    /* istanbul ignore next */ location !== undefined &&
      location.state !== undefined &&
      location.state.selectedTab !== undefined
      ? location.state.selectedTab
      : 0,
  );
  const [canSave, setCanSave] = useState(false);
  const [initialProfileComplete, setInitialProfileComplete] = useState(true);

  const { loadUser } = useRematchDispatch((dispatch) => ({
    loadUser: dispatch.user.loadUser,
  }));

  const profileService = useProfileServiceContext();

  const saveProfile = async () => {
    const { uid } = user;
    await profileService.saveProfile(uid, { generalProfile, preferences });

    // make sure to reload profile because the update snapshot is too slow
    await loadUser({ uid });

    goToDashboard();
  };

  const goToDashboard = () => {
    history.push(DASHBOARD);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const profileComplete = useCallback((profile) => {
    return isProfileComplete(profile);
  }, []);

  useEffect(() => {
    if (user) {
      setPreferences({
        radius: /* istanbul ignore next */ user.searchRadius ? user.searchRadius : DEFAULT_RADIUS,
        notifications: {
          enabled: /* istanbul ignore next */ user.notifications ? user.notifications.enabled : false,
          sms: {
            newRentals: /* istanbul ignore next */ user.notifications ? user.notifications.sms.newRentals : false,
            pendingPayment: /* istanbul ignore next */ user.notifications
              ? user.notifications.sms.pendingPayment
              : false,
            completed: /* istanbul ignore next */ user.notifications ? user.notifications.sms.completed : false,
            newMessages: /* istanbul ignore next */ user.notifications ? user.notifications.sms.newMessages : false,
          },
          email: {
            newRentals: /* istanbul ignore next */ user.notifications ? user.notifications.email.newRentals : false,
            pendingPayment: /* istanbul ignore next */ user.notifications
              ? user.notifications.email.pendingPayment
              : false,
            completed: /* istanbul ignore next */ user.notifications ? user.notifications.email.completed : false,
            newMessages: /* istanbul ignore next */ user.notifications ? user.notifications.email.newMessages : false,
          },
        },
        initial: true,
      });

      const { displayName, email, street, location, state, country, zipcode, language, phoneNumber } = user;
      const initialProfile = {
        fullName: displayName,
        email,
        phone: phoneNumber,
        street,
        location,
        state,
        country: /* istanbul ignore next */ country ? country : 'Canada',
        zipcode,
        language: /* istanbul ignore next */ language ? language : 'french',
      };

      setGeneralProfile({ ...initialProfile, initial: true });
      setInitialProfileComplete(profileComplete(initialProfile));
    }
  }, [user, profileComplete]);

  useEffect(() => {
    setSomethingChanged(
      (generalProfile && generalProfile.initial === undefined) || (preferences && preferences.initial === undefined),
    );
  }, [generalProfile, preferences]);

  useEffect(() => {
    const profComplete = profileComplete(generalProfile);
    setCanSave(profComplete);
  }, [generalProfile, profileComplete]);

  const showSaveCancelButtons = () => {
    return selectedTab < 2;
  };

  return (
    <SecuredRoute path={EDIT_PROFILE}>
      <div className={classes.header}>
        <Header />
      </div>

      <Loading isLoading={user === undefined}>
        <div className={classes.topText}>
          <Typography color={'primary'} align={'left'} variant={'h5'}>
            {t('profile_title')}
          </Typography>
        </div>
        <Container maxWidth={'lg'}>
          <div className={classes.mainContainer}>
            <Tabs data-testid={'tabs'} value={selectedTab} onChange={handleTabChange}>
              <Tab label={t('profile_tabs_general')} />
              <Tab label={t('profile_tabs_preferences')} />
              <Tab label={t('profile_tabs_billing')} />
              <Tab label={t('profile_tabs_reviews')} />
            </Tabs>

            <TabPanel className={classes.tabPanel} value={selectedTab} index={0}>
              {generalProfile && <General profile={generalProfile} onProfileChanged={setGeneralProfile} />}
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={selectedTab} index={1}>
              <Preferences preferences={preferences} onPreferenceChange={setPreferences} />
            </TabPanel>
            {showSaveCancelButtons() && (
              <SaveCancelButtonsContainer
                show={showSaveCancelButtons}
                somethingChanged={somethingChanged}
                canSave={canSave}
                initialProfileComplete={initialProfileComplete}
                onSave={saveProfile}
                onCancel={goToDashboard}
              />
            )}

            <TabPanel className={classes.tabPanel} value={selectedTab} index={2}>
              <BillingInformation />
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={selectedTab} index={3}>
              <Reviews />
            </TabPanel>
          </div>
        </Container>
      </Loading>
    </SecuredRoute>
  );
};

Profile.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default withRouter(Profile);
