/* istanbul ignore file */
import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';

const initFirebase = () => {
  const firebase = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });

  initializeFirestore(firebase, {
    experimentalForceLongPolling: true,
  });
  return firebase;
};

export { initFirebase };
