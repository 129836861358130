class SearchService {
  constructor(firebaseService) {
    this.firebaseService = firebaseService;
  }

  async search(description, category, location, sortOrder, range, priceRange, userId, includeCommercial = true) {
    return this.firebaseService.callUnsecuredFunction('search', {
      description: description.toLowerCase(),
      category,
      location,
      sortOrder: sortOrder.id,
      range,
      priceRange,
      userId,
      includeCommercial,
    });
  }

  async browseRecentPosts(location, user) {
    return this.firebaseService.callUnsecuredFunction('browserecentposts', { location, user });
  }

  async getUserAds(userId) {
    return this.firebaseService.callFunction('getuserads', { userId });
  }
}

export default SearchService;
