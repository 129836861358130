import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Header from '../../components/header';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DASHBOARD, PAY_WALL } from '../../routes';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from './cardForm';
import SecuredRoute from '../../routes/SecuredPage';
import Container from '@material-ui/core/Container';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import ResizableImageBox from '../../components/resizableImageBox';
import { languageToLocale } from '../../utils/locale';
import { useAdServiceContext } from '../../services/ServicesProvider';
import Loading from '../../components/loading';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import RentBillingInformation from '../../components/rentBillingInformation';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  topText: {
    width: '100%',
    flex: 1,
  },
  topTextPadding: {
    paddingTop: 10,
    paddingBottom: 10,
    boxSizing: 'border-box',
  },
  rentItem: {
    width: '100%',
    height: '100%',
  },
  paymentInfo: {
    width: '100%',
    height: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    boxSizing: 'border-box',
    backgroundColor: '#f7f7f5',
  },
  billInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
    height: '100%',
  },
  subContainer: {
    display: 'block',
    width: '100%',
    height: 600,
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

const PayWall = ({ history, rentId }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState();

  const adService = useAdServiceContext();

  const { loadRental, clearAd, loadAd, loadUser } = useRematchDispatch((dispatch) => ({
    loadRental: dispatch.rent.loadRental,
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
    loadUser: dispatch.rent.loadUser,
    updateRentalStatus: dispatch.rent.updateRentalStatus,
  }));
  const rental = useSelector((state) => state.rent.currentRental);
  const ad = useSelector((state) => state.ads.currentAd);
  const user = useSelector((state) => state.rent.user);
  const locale = useSelector((state) => state.authentication.locale);

  useEffect(() => {
    loadRental({ id: rentId });
  }, [rentId, loadRental]);

  useEffect(() => {
    if (rental) {
      adService.markAsRead(rental);
      loadAd({ id: rental.ad });
      loadUser({ id: rental.user });
    }
  }, [rental, loadAd, loadUser, adService]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  useEffect(() => {
    async function doComputeAmounts(rentId) {
      const paymentInfo = await adService.getPaymentInformation(rentId);
      setPaymentInfo(paymentInfo);
    }

    /* istanbul ignore next */
    if (rental) {
      doComputeAmounts(rental.uid);
    }
  }, [rental, adService, setPaymentInfo]);

  useEffect(() => {
    if (rental && ad && user && paymentInfo) {
      setLoading(false);
    }
  }, [rental, ad, user, paymentInfo]);

  // this is tested in CardForm
  /* istanbul ignore next */
  const paymentSuccess = () => {
    history.push(DASHBOARD);
  };

  const renderLoadingContent = () => {
    if (!loading) {
      return (
        <>
          <Container maxWidth={'xl'}>
            <div className={classes.topText}>
              <div className={classes.topTextPadding}>
                <Typography color={'primary'} align={'left'} variant={'h5'}>
                  {ad && ad.title}
                </Typography>
              </div>
            </div>
            <div className={classes.mainContainer}>
              <Grid container>
                <Grid item md={8}>
                  <div className={classes.rentItem}>
                    <ResizableImageBox ad={ad} supportSmallMode={true} />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.paymentInfo}>
                    <div className={classes.subContainer}>
                      <div className={classes.billInfo}>
                        <RentBillingInformation paymentInfo={paymentInfo} />
                        <div className={classes.cardInfo}>
                          <Elements stripe={stripePromise} options={{ locale: languageToLocale(locale) }}>
                            <CardForm rentId={rentId} userEmail={user.email} paymentSuccess={paymentSuccess} />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </>
      );
    }
    return <></>;
  };

  return (
    <SecuredRoute path={PAY_WALL}>
      <ProfileCheck>
        <div className={classes.header}>
          <Header />
        </div>

        <Loading isLoading={loading}>{renderLoadingContent()}</Loading>
      </ProfileCheck>
    </SecuredRoute>
  );
};

PayWall.propTypes = {
  history: PropTypes.object.isRequired,
  rentId: PropTypes.string.isRequired,
};

export default withRouter(PayWall);
