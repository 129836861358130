import { Typography } from '@material-ui/core';
import React from 'react';

export const getPriceAndDescription = (t, showUnit, price, unit) => {
  if (price) {
    let displayedUnit = '';
    if (unit === 'day' || unit === undefined) {
      displayedUnit = t('per_day');
    } else {
      displayedUnit = t('per_hour');
    }

    if (showUnit) {
      return (
        <Typography align={'left'} color={'primary'} variant={'caption'} noWrap={true}>
          {price} $ / {displayedUnit}
        </Typography>
      );
    }
    return (
      <Typography align={'left'} color={'primary'} variant={'caption'} noWrap={true}>
        {price} $
      </Typography>
    );
  }
};
