/* istanbul ignore file */
import { Client } from '@googlemaps/google-maps-services-js';

const getValueWithType = (result, addressComponentType) => {
  let value = '';
  result.address_components.forEach((addressComponent) => {
    addressComponent.types.forEach((type) => {
      if (type === addressComponentType) {
        value = addressComponent.long_name;
      }
    });
  });
  return value;
};

const closestCityWithLongLat = (cities, lat, long) => {
  let currentCity = cities[0];
  let lowestDistance = 99999;
  cities.forEach((city) => {
    const { latitude, longitude } = city;
    const distance = Math.abs(latitude - lat) + Math.abs(longitude - long);
    if (distance < lowestDistance) {
      currentCity = city;
      lowestDistance = distance;
    }
  });
  return currentCity;
};

const extractAddressFromGoogleData = (response, cities) => {
  if (response.status !== 200 || !response.data || !response.data.results || response.data.results.length < 1) {
    return {};
  }

  const result = response.data.results[0];
  const { lat, lng } = result.geometry.location;

  const city = closestCityWithLongLat(cities, lat, lng);

  return {
    city: city,
    state: city.provinceUid,
    country: 'CA',
    zipcode: getValueWithType(result, 'postal_code'),
    address: `${getValueWithType(result, 'street_number')} ${getValueWithType(result, 'route')}`,
  };
};

const extractLocationFromNavigator = async () => {
  const geo = navigator.geolocation;
  let latitude;
  let longitude;
  let success = false;

  if (geo) {
    try {
      const promise = new Promise((resolve, reject) => {
        geo.getCurrentPosition(
          ({ coords }) => {
            resolve(coords);
          },
          (error) => {
            reject(error);
          },
          { timeout: 1000 },
        );
      });

      const coords = await promise;
      latitude = coords.latitude;
      longitude = coords.longitude;
      success = true;
    } catch (e) {
      console.log('Unable to obtain location from browser', e);
    }
  }
  return { success, longitude, latitude };
};

const extractLocationFromGoogle = async () => {
  let latitude;
  let longitude;
  let success = false;

  const client = new Client({});
  try {
    const location = await client.geolocate({
      data: {
        considerIp: true,
      },
      params: {
        key: 'AIzaSyBLYq0K4otrEzvl_9OQOQ9SJbUXYdumHU8',
      },
    });
    if (location && location.data && location.data.location) {
      latitude = location.data.location.lat;
      longitude = location.data.location.lng;
      success = true;
    }
  } catch (e) {
    console.error('unable to geolocation', e);
  }
  return { success, longitude, latitude };
};

const getUserLocation = async (cities, profileService) => {
  let latitude;
  let longitude;

  const locationFromBrowser = await extractLocationFromNavigator();
  if (locationFromBrowser.success === true) {
    latitude = locationFromBrowser.latitude;
    longitude = locationFromBrowser.longitude;
  } else {
    const locationFromGoogle = await extractLocationFromGoogle();
    if (locationFromGoogle.success === true) {
      latitude = locationFromGoogle.latitude;
      longitude = locationFromGoogle.longitude;
    } else {
      const response = await profileService.geoLocate();
      latitude = response.latitude;
      longitude = response.longitude;
    }
  }

  let locationData;
  if (latitude !== undefined && longitude !== undefined) {
    try {
      const client = new Client({});
      const geoCode = await client.reverseGeocode({
        params: {
          latlng: `${latitude},${longitude}`,
          key: 'AIzaSyBLYq0K4otrEzvl_9OQOQ9SJbUXYdumHU8',
        },
        timeout: 5000, // milliseconds
      });
      locationData = extractAddressFromGoogleData(geoCode, cities);
    } catch (e) {
      /* istanbul ignore next */
      console.warn('unable to fetch localisation information', e);
    }
  }

  return {
    latitude,
    longitude,
    ...locationData,
  };
};

export { getUserLocation, closestCityWithLongLat };
