import { createModel } from '@rematch/core';
import COLLECTIONS from '../collections';
import { firebaseService } from '../services/firebaseServiceInstance';
import SORTFIEDS from '../sortFields';
import { RENT_STATUS } from './rentStatus';
import ensureUid from '../services/firebaseTools';

const DEFAULT_STATE = {
  currentAd: undefined,
  activeAds: [],
  inactiveAds: [],
  pendingApprovalAds: [],
  disputeAds: [],
  loadActiveAdListener: undefined,
  loadInactiveAdListener: undefined,
  pendingApprovalAdsListeners: [],
  disputeListeners: [],
  adStats: [],
  owner: undefined,
};

export const createAdsModel = () =>
  createModel({
    state: { ...DEFAULT_STATE },

    reducers: {
      updateActiveAds(state, activeAds) {
        return { ...state, activeAds };
      },

      updateInactiveAds(state, inactiveAds) {
        return { ...state, inactiveAds };
      },
      updateCurrentAd(state, currentAd) {
        return { ...state, currentAd };
      },
      updatePendingApprovalAd(state, pendingApprovalAd) {
        const updatedState = state.pendingApprovalAds.filter((pa) => pa.rent.uid !== pendingApprovalAd.rent.uid);
        if (
          pendingApprovalAd.rent.status === RENT_STATUS.PENDING_APPROVAL ||
          pendingApprovalAd.rent.status === RENT_STATUS.PENDING_PAYMENT ||
          pendingApprovalAd.rent.status === RENT_STATUS.PENDING_OWNER_REVIEW ||
          pendingApprovalAd.rent.status === RENT_STATUS.CHARGE_ACCEPTED
        ) {
          updatedState.push(pendingApprovalAd);
        }
        return { ...state, pendingApprovalAds: updatedState };
      },
      updatePendingApprovalAdsListeners(state, pendingApprovalAdsListeners) {
        return { ...state, pendingApprovalAdsListeners };
      },
      updateDisputeAd(state, disputeAd) {
        const updatedState = state.disputeAds.filter((da) => da.rent.uid !== disputeAd.rent.uid);
        if (disputeAd.rent.status === RENT_STATUS.DISPUTE_OPENED) {
          updatedState.push(disputeAd);
        }
        return { ...state, disputeAds: updatedState };
      },
      updateDisputeAdsListeners(state, disputeListeners) {
        return { ...state, disputeListeners };
      },
      setLoadActiveAdListener(state, loadActiveAdListener) {
        return { ...state, loadActiveAdListener };
      },
      setLoadInactiveAdListener(state, loadInactiveAdListener) {
        return { ...state, loadInactiveAdListener };
      },
      updateStats(state, adStats) {
        return { ...state, adStats };
      },
      updateOwner(state, owner) {
        return { ...state, owner };
      },
      clearState() {
        return { ...DEFAULT_STATE };
      },
    },

    effects: (dispatch) => ({
      async registerLoadActiveAds(payload, rootState) {
        const loadActiveAdListener = await firebaseService.onSnapshotByCondition(
          COLLECTIONS.ads,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            { key: 'active', value: true, operator: '==' },
          ],
          SORTFIEDS.mostRecent,
          (userActiveAds) => {
            dispatch.ads.updateActiveAds(userActiveAds);
          },
        );
        dispatch.ads.setLoadActiveAdListener(loadActiveAdListener);
      },

      async unregisterLoadActiveAds(payload, rootState) {
        if (rootState.ads.loadActiveAdListener) {
          rootState.ads.loadActiveAdListener();
        }
        dispatch.ads.setLoadActiveAdListener(undefined);
      },

      async registerLoadInactiveAds(payload, rootState) {
        const loadInactiveAdListener = await firebaseService.onSnapshotByCondition(
          COLLECTIONS.ads,
          [
            { key: 'user', value: rootState.user.user.uid, operator: '==' },
            { key: 'active', value: false, operator: '==' },
          ],
          SORTFIEDS.mostRecent,
          (userInactiveAds) => {
            dispatch.ads.updateInactiveAds(userInactiveAds);
          },
        );
        dispatch.ads.setLoadInactiveAdListener(loadInactiveAdListener);
      },

      async unregisterLoadInactiveAds(payload, rootState) {
        if (rootState.ads.loadInactiveAdListener) {
          rootState.ads.loadInactiveAdListener();
        }
        dispatch.ads.setLoadInactiveAdListener(undefined);
      },

      async loadAd(payload) {
        let loadedAd = undefined;
        if (payload && payload.id) {
          const { id } = payload;
          const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, id);
          if (adDocument.exists()) {
            loadedAd = ensureUid(adDocument);
          }
        }
        dispatch.ads.updateCurrentAd(loadedAd);
        return loadedAd !== undefined;
      },

      async deleteAd({ id }) {
        firebaseService.deleteById(COLLECTIONS.ads, id);
      },

      async registerPendingApprovalAds({ pendingApprovalItems }) {
        const pendingRentalListeners = [];
        await Promise.all(
          pendingApprovalItems.map(async (pendingRentalAd) => {
            let loadedAd;
            const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, pendingRentalAd.ad);
            const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, pendingRentalAd.owner);
            const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, pendingRentalAd.user);
            if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
              loadedAd = ensureUid(adDocument);
              const rentListener = await firebaseService.onSnapshotById(
                COLLECTIONS.rental,
                pendingRentalAd.uid,
                (rentDocument) => {
                  dispatch.ads.updatePendingApprovalAd({
                    ad: loadedAd,
                    rent: ensureUid(rentDocument),
                    owner: ensureUid(ownerDocument),
                    user: ensureUid(userDocument),
                  });
                },
              );
              pendingRentalListeners.push(rentListener);
            }
          }),
        );

        dispatch.ads.updatePendingApprovalAdsListeners(pendingRentalListeners);
      },

      async unregisterPendingApprovalAds(payload, rootState) {
        rootState.ads.pendingApprovalAdsListeners.forEach((listener) => listener());
        dispatch.ads.updatePendingApprovalAdsListeners([]);
      },

      async registerDisputeAds({ disputeItems }) {
        const disputeListeners = [];
        await Promise.all(
          disputeItems.map(async (disputeAd) => {
            let loadedAd;
            const adDocument = await firebaseService.getDocumentById(COLLECTIONS.ads, disputeAd.ad);
            const ownerDocument = await firebaseService.getDocumentById(COLLECTIONS.users, disputeAd.owner);
            const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, disputeAd.user);
            if (adDocument.exists() && ownerDocument.exists() && userDocument.exists()) {
              loadedAd = ensureUid(adDocument);
              const rentListener = await firebaseService.onSnapshotById(
                COLLECTIONS.rental,
                disputeAd.uid,
                (rentDocument) => {
                  dispatch.ads.updateDisputeAd({
                    ad: loadedAd,
                    rent: ensureUid(rentDocument),
                    owner: ensureUid(ownerDocument),
                    user: ensureUid(userDocument),
                  });
                },
              );
              disputeListeners.push(rentListener);
            }
          }),
        );

        dispatch.ads.updateDisputeAdsListeners(disputeListeners);
      },

      async loadOwner({ id }) {
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, id);
        if (userDocument.exists()) {
          dispatch.ads.updateOwner(ensureUid(userDocument));
        } else {
          dispatch.ads.updateOwner(undefined);
        }
      },

      async unregisterDisputeAds(payload, rootState) {
        rootState.ads.disputeListeners.forEach((listener) => listener());
        dispatch.ads.updateDisputeAdsListeners([]);
      },

      async reset() {
        const promises = [];
        promises.push(dispatch.ads.unregisterLoadActiveAds());
        promises.push(dispatch.ads.unregisterLoadInactiveAds());
        promises.push(dispatch.ads.unregisterPendingApprovalAds());
        promises.push(dispatch.ads.unregisterDisputeAds());
        await Promise.all(promises);
        dispatch.ads.clearState();
      },

      async loadStats({ ads }) {
        const chunk = 10;
        const stats = [];
        for (let i = 0; i < ads.length; i += chunk) {
          const temporary = ads.slice(i, i + chunk);
          const tenDocumentStats = await firebaseService.queryDocumentsWithOperator(
            COLLECTIONS.rentStats,
            [{ key: 'adId', value: temporary, operator: 'in' }],
            undefined,
          );
          stats.push(...tenDocumentStats);
        }

        dispatch.ads.updateStats(stats);
      },

      async clearAd() {
        dispatch.ads.updateCurrentAd(undefined);
      },
    }),
  });
