import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CONFIRM_RENT, DASHBOARD, EDIT_AD, LOGIN, RENT } from '../../routes';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import AdNotFoundContainer from '../../components/adNotFoundContainer';
import ResizableImageBox from '../../components/resizableImageBox';
import OwnerContactInfo from '../../components/ownerContactInfo';
import DatePickers from './datePickers';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useAdServiceContext, useMessageServiceContext } from '../../services/ServicesProvider';
import GridLastReviews from '../../components/gridLastReviews';
import ListLastReviews from '../../components/listLastReviews';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../../utils/location';
import { languageToLocale } from '../../utils/locale';
import notavailable from '../../assets/noimageavailable.png';
import { AdDescription } from '../../components/adDescription';
import { renderMap } from '../../utils/map';
import { getPriceAndDescription } from '../../utils/priceUtils';
import BasePage from '../../components/basePage';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minWidth: 388,
  },
  mainContainer: {
    display: 'flex',
    width: '80%',
    height: '80%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  adTitle: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  imageAndCalendar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  calendar: {
    width: '100%',
    height: '100%',
    padding: 10,
    boxSizing: 'border-box',
    backgroundColor: '#f7f7f5',
  },
  bottomContainer: {
    width: '100%',
    height: '100%',
  },
  reviews: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  expandReviews: {
    paddingBottom: 10,
  },
  errorMessage: {
    height: 30,
    fontFamily: 'Oswald bold',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const RentItem = ({ adId, history, location }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const currentUser = useSelector((state) => state.user.user);
  const ad = useSelector((state) => state.ads.currentAd);
  const owner = useSelector((state) => state.ads.owner);
  const createdRentalId = useSelector((state) => state.rent.createdRentalId);

  const { loadAd, clearAd, createRental, loadOwner, clearCreatedRentalId } = useRematchDispatch((dispatch) => ({
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
    createRental: dispatch.rent.createRental,
    clearCreatedRentalId: dispatch.rent.clearCreatedRentalId,
    loadOwner: dispatch.ads.loadOwner,
  }));
  const locale = useSelector((state) => state.authentication.locale);

  const [loading, setLoading] = useState(true);
  const [openDialogByDefault, setOpenDialogByDefault] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState(19);
  const [endTime, setEndTime] = useState(20);

  const [price, setPrice] = useState(0);
  const [listMode, setListMode] = useState(false);
  const [upcomingRentals, setUpcomingRentals] = useState();
  const [error, setError] = useState();

  const messageService = useMessageServiceContext();
  const adService = useAdServiceContext();

  useEffect(() => {
    async function doLoadUpcomingRentals() {
      const promises = [];
      const loadedAd = await loadAd({ id: adId });
      if (loadedAd) {
        const upcomingRentalsPromise = adService.getUpcomingRentals(adId);
        upcomingRentalsPromise.then((upcomingRentals) => {
          setUpcomingRentals(upcomingRentals);
        });

        promises.push(upcomingRentalsPromise);
        await Promise.all(promises);
      } else {
        // go back to dashboard
        console.log(`Ad ${adId} does not exists`);
        history.push(DASHBOARD);
      }
    }

    /* istanbul ignore next */
    doLoadUpcomingRentals();
  }, [adId, adService, loadAd, currentUser]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  const isBetweenOrSame = (momentDate, givenMomentStartDate, givenMomentEndDate) => {
    return (
      momentDate.isBetween(givenMomentStartDate, givenMomentEndDate) ||
      momentDate.isSame(givenMomentStartDate) ||
      momentDate.isSame(givenMomentEndDate)
    );
  };

  const isPeriodFree = useCallback(
    (givenMomentStartDate, givenMomentEndDate) => {
      if (upcomingRentals) {
        for (let i = 0; i < upcomingRentals.length; i++) {
          const rental = upcomingRentals[i];
          const { startDate, endDate } = rental;
          const momentStartDate = moment(startDate);
          const momentEndDate = moment(endDate);

          if (
            isBetweenOrSame(momentStartDate, givenMomentStartDate, givenMomentEndDate) ||
            isBetweenOrSame(momentEndDate, givenMomentStartDate, givenMomentEndDate)
          ) {
            return false;
          }
        }
      }
      return true;
    },
    [upcomingRentals],
  );

  const shouldUseHours = useCallback(() => {
    return ad.unit === 'hourly';
  }, [ad]);

  const computePrice = useCallback(() => {
    const momentStartDate = moment(startDate).startOf('day');
    const momentEndDate = moment(endDate).startOf('day');

    if (isPeriodFree(momentStartDate, momentEndDate) === false) {
      setError(t('rent_period_not_free'));
    } else {
      if (shouldUseHours()) {
        momentStartDate.add(startTime, 'hours');
        momentEndDate.add(endTime, 'hours');

        if (momentEndDate.isSameOrBefore(momentStartDate)) {
          setError(t('rent_return_date_must_be_after_start'));
        } else {
          setError(undefined);
          setPrice(ad.price * moment(momentEndDate).diff(moment(momentStartDate), 'hours'));
        }
      } else {
        if (momentEndDate.isBefore(momentStartDate)) {
          setError(t('rent_return_date_must_be_after_start'));
        } else {
          setError(undefined);
          setPrice(ad.price * (moment(momentEndDate).diff(moment(momentStartDate), 'days') + 1));
        }
      }
    }
  }, [ad, endDate, startDate, startTime, endTime, t, isPeriodFree, shouldUseHours]);

  useEffect(() => {
    /* istanbul ignore next */
    if (ad) {
      computePrice();
    }

    if (ad !== undefined && upcomingRentals !== undefined) {
      setLoading(false);
    }
  }, [ad, upcomingRentals, computePrice]);

  useEffect(() => {
    if (loading === false) {
      /* istanbul ignore next */
      if (location !== undefined && location.state !== undefined) {
        // re-open dialog if needed
        /* istanbul ignore next */
        setOpenDialogByDefault(location.state.openMessage ? location.state.openMessage : false);

        // create rental if needed
        /* istanbul ignore next */
        if (location.state.rentInfo) {
          const { ad, owner, price, startDate, startTime, unit, endDate, endTime } = location.state.rentInfo;
          createRental({
            ad,
            owner,
            user: currentUser.uid,
            price,
            startDate,
            unit,
            startTime,
            endTime,
            endDate,
            location: currentUser.location,
            state: currentUser.state,
          });
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    if (upcomingRentals) {
      let currentStartDate = moment().startOf('day');
      let currentEndDate = moment().startOf('day');

      let ok = true;
      do {
        ok = true;
        // eslint-disable-next-line no-loop-func
        upcomingRentals.forEach((ur) => {
          const { startDate, endDate } = ur;
          let momentStartDate = moment(startDate);
          const momentEndDate = moment(endDate);
          if (
            isBetweenOrSame(currentStartDate, momentStartDate, momentEndDate) ||
            isBetweenOrSame(currentEndDate, momentStartDate, momentEndDate)
          ) {
            currentStartDate = currentStartDate.add(1, 'days');
            currentEndDate = currentEndDate.add(1, 'days');
            ok = false;
          }
        });
      } while (ok === false);
      setStartDate(currentStartDate.toDate());
      setEndDate(currentEndDate.toDate());
    }
  }, [upcomingRentals]);

  useEffect(() => {
    /* istanbul ignore next */
    if (ad) {
      if (currentUser && ad.user === currentUser.uid) {
        history.push(EDIT_AD.replace(':adId', ad.uid));
      } else {
        loadOwner({ id: ad.user });
      }
    }
  }, [ad, loadOwner, currentUser, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToExternalSite = () => {
    window.location.assign(ad.link);
  };

  const requestToRent = async () => {
    /* istanbul ignore next */
    if (currentUser) {
      await createRental({
        ad: adId,
        owner: ad.user,
        user: currentUser.uid,
        price,
        startDate,
        unit: ad.unit,
        startTime,
        endTime,
        endDate: moment(endDate)
          .endOf('day')
          .toDate(),
        location: currentUser.location,
        state: currentUser.state,
      });
    } else {
      /* istanbul ignore next */
      history.push({
        pathname: LOGIN,
        state: {
          from: RENT.replace(':adId', ad.uid),
          rentInfo: {
            ad: adId,
            owner: ad.user,
            price,
            startDate,
            unit: ad.unit,
            startTime,
            endTime,
            endDate: moment(endDate)
              .endOf('day')
              .toDate(),
          },
        },
      });
    }
  };

  const sendQuestion = (question) => {
    messageService.askQuestion({
      user: ad.user,
      from: currentUser.uid,
      fromName: currentUser.displayName,
      avatar: /* istanbul ignore next */ ad && ad.images[0] ? ad.images[0] : notavailable,
      question,
      adId: ad.uid,
    });
  };

  /* istanbul ignore next */
  const needLogin = () => {
    /* istanbul ignore next */
    history.push({
      pathname: LOGIN,
      state: { from: RENT.replace(':adId', ad.uid), openMessage: true },
    });
  };

  const toggleMode = () => {
    setListMode(!listMode);
  };

  useEffect(() => {
    if (createdRentalId) {
      history.push(CONFIRM_RENT.replace(':rentId', createdRentalId));
      clearCreatedRentalId();
    }
  }, [clearCreatedRentalId, history, createdRentalId]);

  const handleDateChanged = (value) => {
    const { startDate, endDate, startTime, endTime } = value;
    setStartDate(startDate);
    setEndDate(endDate);
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const isExternal = () => {
    return ad !== undefined && ad.isExternal === true;
  };

  const renderOwnerBox = () => {
    if (isExternal() === false && owner) {
      return (
        <OwnerContactInfo
          opened={openDialogByDefault}
          owner={owner}
          onNeedLogin={needLogin}
          onSendQuestion={sendQuestion}
        />
      );
    }
  };

  const renderDatesAndSecurityDeposit = () => {
    if (isExternal() === false) {
      return (
        <>
          <div style={{ display: 'flex', width: '100%', paddingTop: 40, paddingBottom: 10 }}>
            <Typography align={'left'} color={'primary'} variant={'caption'} noWrap={true}>
              {t('rent_select_dates')}
            </Typography>
          </div>
          <DatePickers
            upcomingRentals={upcomingRentals}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            onDateChanged={handleDateChanged}
            useHours={shouldUseHours()}
          />
          <div className={classes.errorMessage}>
            {error && (
              <Typography color={'error'} align={'left'} variant={'subtitle1'}>
                {error}
              </Typography>
            )}
          </div>
          <div style={{ display: 'flex', width: '100%', paddingTop: 10, paddingBottom: 20 }}>
            <Typography align={'left'} color={'primary'} variant={'h6'} noWrap={true}>
              {`${t('rent_security_deposit')} ${ad.securityDeposit} $`}
            </Typography>
          </div>
          <Button
            disabled={error !== undefined}
            data-testid={'request_booking'}
            variant="contained"
            color="primary"
            onClick={requestToRent}
          >
            {t('rent_request_to_book')}
          </Button>
        </>
      );
    }
    return (
      <>
        <div style={{ display: 'flex', width: '100%', paddingTop: 5, paddingBottom: 20 }} />
        <Button
          disabled={error !== undefined}
          data-testid={'view_item'}
          variant="contained"
          color="primary"
          onClick={goToExternalSite}
        >
          {t('rent_view_item')}
        </Button>
      </>
    );
  };

  const renderReviews = () => {
    if (isExternal() === false) {
      return (
        <div className={classes.bottomContainer}>
          <div className={classes.expandReviews}>
            <Typography align={'left'} color={'secondary'} variant={'body1'} noWrap={true}>
              <Link data-testid={'expand-reviews'} onClick={toggleMode}>
                {listMode && t('rent_less_reviews')}
                {!listMode && t('rent_all_reviews')}
              </Link>
            </Typography>
          </div>
          {listMode && <ListLastReviews adId={adId} maxResults={25} />}
          {!listMode && <GridLastReviews adId={adId} />}
        </div>
      );
    }
  };

  const renderContainer = () => {
    if (loading || ad === undefined) {
      return <></>;
    }
    return (
      <AdNotFoundContainer ad={ad}>
        <AdDescription ad={ad} />
        <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
          {getPriceAndDescription(t, true, ad.price, ad.unit)}
        </Typography>
        <div className={classes.imageAndCalendar}>
          <div className={classes.imageContainer}>
            <ResizableImageBox ad={ad} />
          </div>
          <div className={classes.calendar}>
            {renderOwnerBox()}

            <div style={{ display: 'flex', width: '100%' }}>
              <Typography align={'left'} color={'secondary'} variant={'h5'} noWrap={true}>
                {locationFor(locations, ad.location).name[languageToLocale(locale)]}
              </Typography>
            </div>
            <div style={{ display: 'flex', width: '100%', paddingTop: 10 }}>
              <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
                {`${price} $`}
              </Typography>
            </div>

            {renderDatesAndSecurityDeposit()}
            <div style={{ display: 'flex', paddingTop: 20 }}>{renderMap(owner, locations, ad)}</div>
          </div>
        </div>
        {renderReviews()}
      </AdNotFoundContainer>
    );
  };

  return (
    <BasePage loading={loading} skipSecurity={true} pagePath={RENT}>
      {renderContainer()}
    </BasePage>
  );
};

RentItem.propTypes = {
  adId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(RentItem);
