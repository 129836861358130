import React from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import ItemDescription from './itemDescriptionWithUserAddressBlock';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import DashboardRentedButtons from './dashboardRentedButtons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { CONFIRM_RENT, EDIT_AD, RENT } from '../routes';
import { RENT_STATUS } from '../models/rentStatus';

const useStyles = makeStyles(() => ({
  border: {
    height: 25,
  },
  buttonsContainer: {
    display: 'flex',
    height: 100,
    width: '100%',
    alignItems: 'flex-end',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacing: {
    paddingRight: 10,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemDescription: {
    height: 610,
  },
}));

const DashboardRentedItems = ({ history, items }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.user.user);
  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  const getBoxTheme = () => {
    return {
      border: '1px lightgray solid',
      borderRadius: 3,
      marginBottom: 20,
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 8,
      paddingRight: /* istanbul ignore next */ isMobile ? 0 : 8,
      paddingTop: 7,
      paddingBottom: 7,
      boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.75)',
      width: 200,
      height: 710,
    };
  };

  const goToAd = (rent) => {
    const isOwner = rent.owner === currentUser.uid;
    if (isOwner) {
      history.push(EDIT_AD.replace(':adId', rent.ad));
    } else {
      if (
        rent.status === RENT_STATUS.PENDING_OWNER_REVIEW ||
        rent.status === RENT_STATUS.APPROVED ||
        rent.status === RENT_STATUS.PENDING_APPROVAL ||
        rent.status === RENT_STATUS.PENDING_PAYMENT ||
        rent.status === RENT_STATUS.DISPUTE_OPENED
      ) {
        history.push(CONFIRM_RENT.replace(':rentId', rent.uid));
      } else {
        history.push(RENT.replace(':adId', rent.ad));
      }
    }
  };

  return (
    <Grid container spacing={/* istanbul ignore next */ isMobile ? 0 : 10}>
      {items.length === 0 && (
        <Grid item>
          <Typography color={'primary'} align={'left'} variant={'h6'}>
            {t('none')}
          </Typography>
        </Grid>
      )}
      {items &&
        items
          .filter((m) => m)
          .map((item, index) => {
            const { rent, ad, owner, user } = item;
            return (
              <Grid item key={`${ad.uid} - ${index}`}>
                <div key={`${ad.uid} - ${index}`} style={getBoxTheme()}>
                  <div className={classes.cardContainer}>
                    <div className={classes.itemDescription}>
                      <ItemDescription
                        isOwner={rent.owner !== currentUser.uid}
                        rent={rent}
                        ad={ad}
                        owner={owner}
                        user={user}
                        onClick={() => goToAd(rent)}
                      />
                    </div>
                    <div className={classes.buttonsContainer}>
                      <div className={classes.buttons}>
                        <DashboardRentedButtons isOwner={rent.owner === currentUser.uid} item={item} />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
    </Grid>
  );
};

DashboardRentedItems.propTypes = {
  items: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(DashboardRentedItems);
