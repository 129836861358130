import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import RentingImage from './rentingImage';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';
import notavailable from '../assets/noimageavailable.png';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { RENT } from '../routes';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(() => ({
  results: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  resultItem: {
    display: 'block',
    backgroundColor: 'white',
    border: '1px lightgray solid',
    padding: 8,
    width: 200,
    cursor: 'pointer',
    borderRadius: 3,
  },
}));

const SearchResultsList = ({ resultsInPage, history }) => {
  const classes = useStyles();

  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const goToAd = (ad) => {
    history.push(RENT.replace(':adId', ad.uid));
  };

  const renderPage = () => {
    if (resultsInPage && resultsInPage.length > 0) {
      return (
        <div className={classes.results}>
          <Grid container spacing={3}>
            {resultsInPage.map((item) => {
              if (item) {
                return (
                  <Grid item key={item.uid}>
                    <div className={classes.resultItem} key={item.uid}>
                      <RentingImage
                        title={item.title}
                        location={locationFor(locations, item.location).name[languageToLocale(locale)]}
                        image={item.images && item.images[0] ? item.images[0] : notavailable}
                        category={item.category}
                        price={item.price}
                        unit={item.unit}
                        mainImageWidth={item.mainImageWidth}
                        mainImageHeight={item.mainImageHeight}
                        onAdClick={() => goToAd(item)}
                        testid={`result-${item.uid}`}
                      />
                    </div>
                  </Grid>
                );
              }
              return <span key={uuidv4()} />;
            })}
          </Grid>
        </div>
      );
    }
    return <></>;
  };

  return renderPage();
};

SearchResultsList.propTypes = {
  resultsInPage: PropTypes.array,
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchResultsList);
