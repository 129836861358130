import { createModel } from '@rematch/core';
import { firebaseService } from '../services/firebaseServiceInstance';
import COLLECTIONS from '../collections';
import MESSAGE_SORTFIEDS from '../messageSortFields';
import THREAD_MESSAGE_SORTFIEDS from '../threadMessageSortFields';
import ensureUid from '../services/firebaseTools';

const DEFAULT_STATE = {
  fromMessages: [],
  toMessages: [],
  threadMessages: [],
  loadToMessagesListener: undefined,
  loadFromMessagesListener: undefined,
  loadThreadMessagesListener: undefined,
  owner: undefined,
  user: undefined,
};

export const createInboxModel = () =>
  createModel({
    state: { ...DEFAULT_STATE },

    reducers: {
      updateToMessages(state, toMessages) {
        return { ...state, toMessages };
      },
      updateFromMessages(state, fromMessages) {
        return { ...state, fromMessages };
      },
      updateThreadMessages(state, threadMessages) {
        return { ...state, threadMessages };
      },
      setToLoadMessagesListener(state, loadToMessagesListener) {
        return { ...state, loadToMessagesListener };
      },
      setFromLoadMessagesListener(state, loadFromMessagesListener) {
        return { ...state, loadFromMessagesListener };
      },
      setLoadThreadMessagesListener(state, loadThreadMessagesListener) {
        return { ...state, loadThreadMessagesListener };
      },
      updateOwner(state, owner) {
        return { ...state, owner };
      },
      updateUser(state, user) {
        return { ...state, user };
      },
      clearState() {
        return { ...DEFAULT_STATE };
      },
    },

    effects: (dispatch) => ({
      async registerLoadCurrentUserMessages(payload, rootState) {
        const loadToMessagesListener = await firebaseService.onSnapshotByCondition(
          COLLECTIONS.threads,
          [{ key: 'user', value: rootState.user.user.uid, operator: '==' }],
          MESSAGE_SORTFIEDS.mostRecent,
          (messages) => {
            dispatch.inbox.updateToMessages(messages);
          },
        );
        dispatch.inbox.setToLoadMessagesListener(loadToMessagesListener);

        const loadFromMessagesListener = await firebaseService.onSnapshotByCondition(
          COLLECTIONS.threads,
          [{ key: 'from', value: rootState.user.user.uid, operator: '==' }],
          MESSAGE_SORTFIEDS.mostRecent,
          (messages) => {
            dispatch.inbox.updateFromMessages(messages);
          },
        );
        dispatch.inbox.setFromLoadMessagesListener(loadFromMessagesListener);
      },

      async unregisterLoadCurrentUserMessages(payload, rootState) {
        if (rootState.inbox.loadToMessagesListener) {
          rootState.inbox.loadToMessagesListener();
          dispatch.inbox.setToLoadMessagesListener(undefined);
        }

        if (rootState.inbox.loadFromMessagesListener) {
          rootState.inbox.loadFromMessagesListener();
          dispatch.inbox.setFromLoadMessagesListener(undefined);
        }
      },

      async registerLoadThreadMessages(payload) {
        const { threadId } = payload;
        const loadThreadMessagesListener = await firebaseService.onSnapshotByCondition(
          COLLECTIONS.messages,
          [{ key: 'threadId', value: threadId, operator: '==' }],
          THREAD_MESSAGE_SORTFIEDS.oldest,
          (messages) => {
            dispatch.inbox.updateThreadMessages(messages);
          },
        );
        dispatch.inbox.setLoadThreadMessagesListener(loadThreadMessagesListener);
      },

      async unregisterLoadThreadMessages(payload, rootState) {
        if (rootState.inbox.loadThreadMessagesListener) {
          rootState.inbox.loadThreadMessagesListener();
        }
        dispatch.inbox.setLoadThreadMessagesListener(undefined);
        dispatch.inbox.updateThreadMessages([]);
      },

      async loadOwner({ id }) {
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, id);
        if (userDocument.exists()) {
          dispatch.inbox.updateOwner(ensureUid(userDocument));
        } else {
          dispatch.inbox.updateOwner(undefined);
        }
      },

      async loadUser({ id }) {
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, id);
        if (userDocument.exists()) {
          dispatch.inbox.updateUser(ensureUid(userDocument));
        } else {
          dispatch.inbox.updateUser(undefined);
        }
      },

      async reset() {
        const promises = [];
        promises.push(dispatch.inbox.unregisterLoadCurrentUserMessages());
        promises.push(dispatch.inbox.unregisterLoadThreadMessages());
        await Promise.all(promises);
        dispatch.inbox.clearState();
      },
    }),
  });
