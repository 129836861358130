import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InputTextField from '../../components/InputTextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { citiesFor, locationFor, provinceFor, stateFrom } from '../../utils/location';
import AutoCompleteInputTextField from '../../components/AutoCompleteInputTextField';
import { getUserLocation } from '../../utils/geoLocalisation';
import { useProfileServiceContext } from '../../services/ServicesProvider';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    height: '100%',
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
  withPaddingBottom: {
    paddingBottom: 10,
  },
}));

const General = ({ profile, onProfileChanged }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const provinces = useSelector((state) => state.locations.provinces);
  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const { fullName, email, phone, street, state, zipcode, language, location, coords } = profile;

  const [provinceValue, setProvinceValue] = useState(provinceFor(provinces, state));

  // Only canada is supported for now
  const [country] = useState('Canada');
  const [cities, setCities] = useState(citiesFor(provinces, state));
  const [locationError, setLocationError] = useState(location !== undefined);

  const handleLanguageChange = (event) => {
    onProfileChanged({
      fullName,
      email,
      phone,
      street,
      location,
      state,
      country,
      zipcode,
      language: event.target.value,
      coords,
    });
  };

  const profileService = useProfileServiceContext();

  const detectLocation = async () => {
    const userLocation = await getUserLocation(locations, profileService);
    /* istanbul ignore next */
    if (userLocation.latitude !== undefined && userLocation.longitude !== undefined) {
      let state = undefined;
      let location = undefined;

      if (userLocation.city) {
        state = stateFrom(provinces, userLocation.city.uid);
        location = locationFor(citiesFor(provinces, state), userLocation.city.uid);
      }
      onProfileChanged({
        fullName,
        email,
        phone,
        street: /* istanbul ignore next */ userLocation.address ? userLocation.address : profile.street,
        location: /* istanbul ignore next */ location ? location.uid : profile.location,
        state: /* istanbul ignore next */ state ? state : profile.state,
        country,
        zipcode: /* istanbul ignore next */ userLocation.zipcode ? userLocation.zipcode : profile.zipcode,
        language,
        coords: { latitude: userLocation.latitude, longitude: userLocation.longitude },
      });
    }
  };

  useEffect(() => {
    if (user && location) {
      setLocationError(false);
    } else {
      setLocationError(true);
    }
  }, [location]);

  useEffect(() => {
    const province = provinceFor(provinces, state);
    // make a copy of the province to force an update to the value (for example if locale changed)
    setProvinceValue({ ...province });
  }, [locale, state, provinces]);

  useEffect(() => {
    const cities = citiesFor(provinces, state);
    setCities(cities);
  }, [provinces, state]);

  const handleCityChange = (city) => {
    onProfileChanged({
      fullName,
      email,
      phone,
      street,
      location: city.uid,
      state,
      country,
      zipcode,
      language,
      coords,
    });
  };

  const handleStateChange = (state) => {
    onProfileChanged({
      fullName,
      email,
      phone,
      street,
      location,
      state: state.code,
      country,
      zipcode,
      language,
      coords,
    });
  };

  const handleNameChange = (newName) => {
    onProfileChanged({
      fullName: newName,
      email,
      phone,
      street,
      location,
      state,
      country,
      zipcode,
      language,
      coords,
    });
  };

  const handleEmailChange = (newEmail) => {
    onProfileChanged({
      fullName,
      email: newEmail,
      phone,
      street,
      location,
      state,
      country,
      zipcode,
      language,
      coords,
    });
  };

  const handlePhoneChange = (newPhone) => {
    onProfileChanged({
      fullName,
      email,
      phone: newPhone,
      street,
      location,
      state,
      country,
      zipcode,
      language,
      coords,
    });
  };

  const handleStreetChange = (newStreet) => {
    onProfileChanged({
      fullName,
      email,
      phone,
      street: newStreet,
      location,
      state,
      country,
      zipcode,
      language,
      coords,
    });
  };

  const handleZipCodeChange = (newZipCode) => {
    onProfileChanged({
      fullName,
      email,
      phone,
      street,
      location,
      state,
      country,
      zipcode: newZipCode,
      language,
      coords,
    });
  };

  return (
    <div className={classes.mainContainer}>
      <InputTextField
        title={t('profile_field_fullname')}
        initialProperty={fullName}
        onChange={handleNameChange}
        testid={'fullName'}
      />
      <InputTextField
        title={t('profile_field_email')}
        initialProperty={email}
        onChange={handleEmailChange}
        testid={'email'}
      />
      <InputTextField
        title={t('profile_field_phone')}
        initialProperty={phone}
        onChange={handlePhoneChange}
        testid={'phone'}
      />
      <InputTextField
        title={t('profile_field_street')}
        initialProperty={street}
        onChange={handleStreetChange}
        testid={'street'}
      />

      <Button
        data-testid={`detectLocation`}
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={detectLocation}
      >
        {t('profile_use_my_location')}
      </Button>

      <div className={classes.withPaddingBottom}>
        <AutoCompleteInputTextField
          id={'state'}
          title={t('profile_field_state')}
          initialValue={provinceValue}
          options={provinces}
          onChange={handleStateChange}
          locale={locale}
        />
      </div>

      <div className={classes.withPaddingBottom}>
        <AutoCompleteInputTextField
          id={'city'}
          title={t('profile_field_city')}
          initialValue={locationFor(cities, location)}
          options={cities}
          onChange={handleCityChange}
          locale={locale}
        />
        {locationError && (
          <Typography color={'error'} align={'left'} variant={'subtitle1'}>
            {t('profile_location_is_required')}
          </Typography>
        )}
      </div>

      <InputTextField
        title={t('profile_field_zipcode')}
        initialProperty={zipcode}
        onChange={handleZipCodeChange}
        testid={'zipcode'}
      />

      <RadioGroup data-testid={'radio'} value={language} onChange={handleLanguageChange}>
        <FormControlLabel
          value={'english'}
          name={'english-option'}
          control={<Radio data-testid={'english'} />}
          label={t('profile_locale_english')}
        />
        <FormControlLabel
          value={'french'}
          name={'french-option'}
          control={<Radio data-testid={'french'} />}
          label={t('profile_locale_french')}
        />
      </RadioGroup>
    </div>
  );
};

General.propTypes = {
  profile: PropTypes.object.isRequired,
  onProfileChanged: PropTypes.func.isRequired,
};

export default General;
