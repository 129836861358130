import { createAuthenticationModel } from './authentication';
import { createCategoriesModel } from './categories';
import { createLocationsModel } from './locations';
import { createAdsModel } from './ads';
import { createRentModel } from './rent';
import { createInboxModel } from './inbox';
import { createHomeModel } from './home';
import { createUserModel } from './user';
import { createProfileModel } from './profile';
import { createSettingsModel } from './settings';
import { createUnitsModel } from './units';

const authentication = createAuthenticationModel();
const categories = createCategoriesModel();
const locations = createLocationsModel();
const ads = createAdsModel();
const rent = createRentModel();
const inbox = createInboxModel();
const home = createHomeModel();
const user = createUserModel();
const profile = createProfileModel();
const settings = createSettingsModel();
const units = createUnitsModel();

export { authentication, categories, locations, ads, rent, inbox, home, user, profile, settings, units };
