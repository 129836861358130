import React from 'react';
import { Typography } from '@material-ui/core';
import AdShape from '../shapes/adShape';
import { useTranslation } from 'react-i18next';

const AdNotFoundContainer = ({ ad, children }) => {
  const { t } = useTranslation();

  if (ad === undefined) {
    return (
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('component_ad_not_found')}
      </Typography>
    );
  }
  return children;
};

AdNotFoundContainer.propTypes = {
  ad: AdShape,
};

export default AdNotFoundContainer;
