import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import DashboardRentedButtons from '../../components/dashboardRentedButtons';
import { useSelector } from 'react-redux';
import moment from 'moment';

const RentalStatus = ({ item }) => {
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.user.user);

  const isInProgress = (rent) => {
    // TODO consider hours
    return moment(rent.startDate.toDate()).isSameOrBefore(moment());
  };

  const statusToString = () => {
    if (item.rent.status === 'IN_PROGRESS' && !isInProgress(item.rent)) {
      return t('ready');
    }
    return t(`rentStatus.${item.rent.status}`);
  };

  return (
    <div style={{ display: 'flex', width: '100%', paddingTop: 40, paddingBottom: 10, flexDirection: 'column' }}>
      <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
        {t('status')}
      </Typography>
      <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
        {statusToString()}
      </Typography>
      <div style={{ display: 'flex', width: '100%', paddingTop: 10 }}>
        <DashboardRentedButtons displayStatusOnly={false} isOwner={item.rent.owner === currentUser.uid} item={item} />
      </div>
    </div>
  );
};

RentalStatus.propTypes = {
  item: PropTypes.object.isRequired,
};

export default RentalStatus;
