import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { fitTextForDisplay } from '../utils/textTruncate';
import { renderStars } from '../utils/ratings';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  box: {
    display: 'block',
    backgroundColor: 'white',
    border: '1px lightgray solid',
    padding: 8,
    width: 200,
    height: 370,
    cursor: 'pointer',
    borderRadius: 3,
  },
  boxTopPart: {
    display: 'block',
    height: 250,
    width: '100%',
  },
  boxBottomPart: {
    display: 'block',
    height: 120,
    width: '100%',
  },
  commentBox: {
    display: 'block',
    width: 200,
    height: 55,
    fontFamily: 'Oswald',
    color: '#19857b',
    fontSize: 12,
    textAlign: 'left',
  },
  locationBox: {
    display: 'flex',
    width: '100%',
    paddingTop: 10,
  },
  image: {
    width: 200,
    height: 200,
  },
  textForImage: {
    boxSizing: 'border-box',
    width: 250,
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },
  cardWithAvatar: {
    position: 'relative',
    left: 53,
    top: -157,
    width: 100,
    height: 100,
    backgroundColor: 'transparent',
    borderWidth: 5,
    borderColor: 'black',
  },
  dotForAvatar: {
    position: 'relative',
    left: 50,
    top: -60,
    width: 100,
    height: 100,
    backgroundColor: 'transparent',
    borderWidth: 5,
    borderColor: 'black',
  },
  avatar: {
    width: 100,
    height: 100,
  },
  dot: {
    display: 'block',
    height: 106,
    width: 106,
    backgroundColor: 'black',
    borderRadius: '50%',
  },
  customBox: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 4,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
}));

const ReviewCard = ({ onAdClick, image, avatar, location, reviewComment, rating, id }) => {
  const classes = useStyles();

  let fittedText = '';

  /* istanbul ignore next */
  if (reviewComment) {
    fittedText = fitTextForDisplay(reviewComment, 390, '14pt Oswald');
  }

  return (
    <div className={classes.box} onClick={onAdClick} id={id}>
      <div style={{ flex: 1 }}>
        <div className={classes.boxTopPart}>
          <img src={image} className={classes.image} alt={''} />
          <div className={classes.dotForAvatar}>
            <span className={classes.dot} />
          </div>
          <div className={classes.cardWithAvatar}>
            <Avatar className={classes.avatar} src={avatar} />
          </div>
        </div>
        <div className={classes.boxBottomPart}>
          {renderStars(rating)}
          <div className={classes.commentBox}>
            <Box component="div" classes={{ root: classes.customBox }}>
              {fittedText}
            </Box>
          </div>
          <div className={classes.locationBox}>
            <Typography align={'left'} color={'secondary'} variant={'caption'} noWrap={true}>
              {location}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  onAdClick: PropTypes.func,
  reviewComment: PropTypes.string,
  location: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  rating: PropTypes.number,
  id: PropTypes.string,
};

export default ReviewCard;
