import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AdShape from '../shapes/adShape';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import { findLastImageIndex, hasImages } from '../utils/imagesHelper';
import reactImageSize from 'react-image-size';

const useStyles = makeStyles(() => ({
  dot: {
    display: 'block',
    height: '100%',
    width: '100%',
    opacity: 0.33,
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  arrowRight: {
    position: 'relative',
    left: 555,
    top: -547,
    width: 40,
    height: 40,
    color: 'grey',
  },
  rightForAvatar: {
    position: 'relative',
    left: 545,
    top: -505,
    width: 50,
    height: 50,
    backgroundColor: 'transparent',
    borderWidth: 5,
    borderColor: 'black',
  },
  rightDotOverlay: {
    display: 'block',
    position: 'relative',
    left: 544,
    top: -598,
    width: 56,
    height: 56,
    backgroundColor: 'transparent',
    borderRadius: '50%',
    cursor: 'pointer',
  },
}));

const ForRentImage = ({ ad, showArrows, imageWidth, onReady }) => {
  const classes = useStyles();

  const [imageIndex, setImageIndex] = useState(0);
  const [imageReady, setImageReady] = useState(false);
  const [imageAvailable, setImageAvailable] = useState(false);
  const [imageDimensions, setImageDimensions] = useState(undefined);

  const lastImageIndex = findLastImageIndex(ad);

  const goLeft = () => {
    if (imageIndex === 0) {
      setImageIndex(lastImageIndex - 1);
    } else {
      setImageIndex(imageIndex - 1);
    }
  };

  const goRight = () => {
    if (imageIndex === lastImageIndex - 1) {
      setImageIndex(0);
    } else {
      setImageIndex(imageIndex + 1);
    }
  };

  const getLeftDotStyle = () => {
    const dotSize = imageWidth / 12;
    return {
      position: 'absolute',
      top: imageWidth / 2 - dotSize / 2,
      width: dotSize,
      height: dotSize,
      borderWidth: 5,
      borderColor: 'black',
      left: 0,
    };
  };

  const getLeftArrowStyle = () => {
    const dotSize = imageWidth / 12;
    return {
      position: 'relative',
      top: -dotSize,
      left: dotSize / 5,
      color: '#484747',
      width: '100%',
      height: '100%',
    };
  };

  const getDotOverlayStyle = () => {
    const dotSize = imageWidth / 12;
    return {
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: dotSize,
      height: dotSize,
      backgroundColor: 'transparent',
      borderRadius: '50%',
      cursor: 'pointer',
    };
  };

  const getRightDotStyle = () => {
    const dotSize = imageWidth / 12;
    return {
      position: 'absolute',
      top: imageWidth / 2 - dotSize / 2,
      width: dotSize,
      height: dotSize,
      borderWidth: 5,
      borderColor: 'black',
      left: imageWidth - dotSize,
    };
  };

  const getRightArrowStyle = () => {
    const dotSize = imageWidth / 12;
    return {
      position: 'relative',
      top: -dotSize,
      left: dotSize / 10,
      color: '#484747',
      width: '100%',
      height: '100%',
    };
  };

  const getImageStyle = () => {
    if (imageDimensions) {
      const { width, height } = imageDimensions;
      if (width > height) {
        return {
          width: '100%',
          resizeMode: 'contain',
        };
      }
      return {
        height: '100%',
        resizeMode: 'contain',
      };
    }
  };

  useEffect(() => {
    setImageReady(false);
    setImageDimensions(undefined);
  }, [imageIndex]);

  const renderImage = () => {
    if (imageReady) {
      onReady(imageAvailable);
      return (
        <>
          <img src={ad.images[imageIndex]} style={getImageStyle()} alt={''} />
          {showArrows && (
            <>
              <div style={getLeftDotStyle()}>
                <span className={classes.dot} />
                <ArrowBackIosIcon style={getLeftArrowStyle()} />
                <span data-testid={'leftArrow'} style={getDotOverlayStyle()} onClick={goLeft} />
              </div>

              <div style={getRightDotStyle()}>
                <span className={classes.dot} />
                <ArrowForwardIosIcon style={getRightArrowStyle()} />
                <span data-testid={'rightArrow'} style={getDotOverlayStyle()} onClick={goRight} />
              </div>
            </>
          )}
        </>
      );
    } else {
      reactImageSize(ad.images[imageIndex])
        .then((response) => {
          const { width, height } = response;
          setImageReady(true);
          setImageAvailable(true);
          setImageDimensions({ width, height });
        })
        .catch(
          /* istanbul ignore next */ (e) => {
            /* istanbul ignore next */
            setImageReady(true);
            /* istanbul ignore next */
            setImageDimensions({ width: -1, height: -1 });
          },
        );
      return <></>;
    }
  };

  if (hasImages(ad)) {
    return <>{renderImage()}</>;
  }
  return <></>;
};

ForRentImage.propTypes = {
  imageWidth: PropTypes.number.isRequired,
  ad: AdShape,
  showArrows: PropTypes.bool,
  onReady: PropTypes.func.isRequired,
};

ForRentImage.defaultProps = {
  showArrows: true,
};

export default ForRentImage;
