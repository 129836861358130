import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoadingButton from '../loadingButton';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    paddingTop: 20,
    width: 150,
  },
  button: {
    width: '100%',
    height: '100%',
  },
}));

const UseCreditsButton = ({ onClick, payoutMode }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [linkingAccount, setLinkingAccount] = useState(false);

  const handleUseCredit = () => {
    setLinkingAccount(true);
    onClick();
  };

  const renderCreditsButton = () => {
    if (payoutMode === undefined || payoutMode === 'credits') {
      return (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            data-testid={'useCredits'}
            variant="contained"
            color="primary"
            onClick={handleUseCredit}
          >
            <LoadingButton loading={linkingAccount} content={t('payout_mode_credit')} />
          </Button>
        </div>
      );
    }
    return <></>;
  };

  return renderCreditsButton();
};

UseCreditsButton.propTypes = {
  payoutMode: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default UseCreditsButton;
