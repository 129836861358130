import React from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    paddingBottom: 10,
  },
  titleAndTooltip: {
    display: 'flex',
    flexDirection: 'colum',
  },
  test: {
    paddingLeft: 10,
    height: '100%',
  },
}));

const InputNumericFieldWithTooltip = ({ title, value, onChange, testid, tooltipText }) => {
  const classes = useStyles();

  const onPropertyChange = (event) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === '') {
      onChange('');
    } else if (re.test(event.target.value)) {
      onChange(event.target.value);
    }
    event.preventDefault();
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleAndTooltip}>
        <Typography color={'primary'} align={'left'} variant={'body1'}>
          {title}
        </Typography>
        <Tooltip title={tooltipText} enterTouchDelay={100} leaveTouchDelay={3000}>
          <HelpIcon className={classes.test} />
        </Tooltip>
      </div>
      <TextField
        fullWidth
        inputProps={{ 'data-testid': testid }}
        value={value}
        variant="outlined"
        onChange={onPropertyChange}
      />
    </div>
  );
};

InputNumericFieldWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  testid: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
};

InputNumericFieldWithTooltip.defaultProps = {
  value: undefined,
  large: true,
};

export default InputNumericFieldWithTooltip;
