import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CONFIRM_RENT, DASHBOARD } from '../../routes';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import RentBillingInformation from '../../components/rentBillingInformation';
import ResizableImageBox from '../../components/resizableImageBox';
import { useAdServiceContext, useMessageServiceContext } from '../../services/ServicesProvider';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { AdDescription } from '../../components/adDescription';
import { RENT_STATUS } from '../../models/rentStatus';
import { renderMap } from '../../utils/map';
import OwnerContactInfo from '../../components/ownerContactInfo';
import notavailable from '../../assets/noimageavailable.png';
import BasePage from '../../components/basePage';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingButton from '../../components/loadingButton';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rentItem: {
    width: '100%',
    height: '100%',
  },
  paymentInfo: {
    width: '100%',
    height: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    boxSizing: 'border-box',
    backgroundColor: '#f7f7f5',
  },
  billInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
    height: '100%',
  },
  subContainer: {
    display: 'block',
    width: '100%',
    height: 600,
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  buttons: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
}));

const ConfirmRent = ({ history, rentId }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [confirmingAccount, setConfirmingAccount] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState();

  const adService = useAdServiceContext();

  const { loadRental, loadAd, clearAd, loadOwner } = useRematchDispatch((dispatch) => ({
    loadRental: dispatch.rent.loadRental,
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
    loadOwner: dispatch.ads.loadOwner,
  }));
  const rental = useSelector((state) => state.rent.currentRental);
  const ad = useSelector((state) => state.ads.currentAd);
  const locations = useSelector((state) => state.locations.locations);
  const owner = useSelector((state) => state.ads.owner);
  const currentUser = useSelector((state) => state.user.user);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadRental({ id: rentId });
  }, [rentId, loadRental]);

  useEffect(() => {
    /* istanbul ignore next */
    if (rental) {
      loadAd({ id: rental.ad });
    }
  }, [rental, loadAd]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  useEffect(() => {
    /* istanbul ignore next */
    if (ad) {
      loadOwner({ id: ad.user });
    }
  }, [ad, loadOwner]);

  useEffect(() => {
    async function doComputeAmounts(rentId) {
      const paymentInfo = await adService.getPaymentInformation(rentId);
      setPaymentInfo(paymentInfo);
    }

    /* istanbul ignore next */
    if (rental) {
      doComputeAmounts(rental.uid);
    }
  }, [rental, adService, setPaymentInfo]);

  useEffect(() => {
    if (rental && ad && paymentInfo && owner) {
      setLoading(false);
    }
  }, [rental, ad, paymentInfo, owner]);

  const messageService = useMessageServiceContext();

  const handleClose = () => {
    setOpen(false);
    history.push(DASHBOARD);
  };

  const confirmRent = async () => {
    setConfirmingAccount(true);
    await adService.confirm(rental);
    setOpen(true);
  };

  const cancelRent = () => {
    adService.cancel(rental);
    history.push(DASHBOARD);
  };

  const sendQuestion = (question) => {
    messageService.askQuestion({
      user: ad.user,
      from: currentUser.uid,
      fromName: currentUser.displayName,
      avatar: /* istanbul ignore next */ ad && ad.images[0] ? ad.images[0] : notavailable,
      question,
      adId: ad.uid,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderButtons = () => {
    if (rental.status === RENT_STATUS.UNCONFIRMED) {
      return (
        <>
          <div className={classes.buttons}>
            <Button data-testid={'confirm'} type="submit" variant="contained" color="secondary" onClick={confirmRent}>
              <LoadingButton loading={confirmingAccount} content={t('confirm_rent_confirm')} />
            </Button>
          </div>
          <div className={classes.buttons}>
            <Button data-testid={'cancel'} type="submit" variant="contained" color="primary" onClick={cancelRent}>
              {t('confirm_rent_cancel')}
            </Button>
          </div>
        </>
      );
    }
  };

  const renderLoadingContent = () => {
    if (!loading) {
      return (
        <>
          <div style={{ display: 'flex', paddingTop: 20 }}>
            <OwnerContactInfo showUserName={true} showAskQuestion={true} owner={owner} onSendQuestion={sendQuestion} />
          </div>
          <AdDescription ad={ad} />
          <div className={classes.mainContainer}>
            <Grid container>
              <Grid item md={8}>
                <div className={classes.rentItem}>
                  <ResizableImageBox ad={ad} supportSmallMode={true} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.paymentInfo}>
                  <div className={classes.subContainer}>
                    <div className={classes.billInfo}>
                      <RentBillingInformation paymentInfo={paymentInfo} />
                      {renderButtons()}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', paddingTop: 20 }}>{renderMap(owner, locations, ad)}</div>
        </>
      );
    }
    return <></>;
  };

  return (
    <BasePage pagePath={CONFIRM_RENT} loading={loading}>
      {renderLoadingContent()}
      <Dialog data-testid={'request-send-alert-dialog'} open={open} onClose={handleClose}>
        <DialogTitle>{t('rent_booking_requested')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('rent_booking_successfully_requested')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid={'booking-created--alert-dialog-close'} onClick={handleClose} color="primary">
            {t('action_close')}
          </Button>
        </DialogActions>
      </Dialog>
    </BasePage>
  );
};

ConfirmRent.propTypes = {
  history: PropTypes.object.isRequired,
  rentId: PropTypes.string.isRequired,
};

export default withRouter(ConfirmRent);
