const COLLECTIONS = {
  users: 'users',
  ads: 'ads',
  rental: 'rental',
  threads: 'threads',
  messages: 'messages',
  rentStats: 'stats',
  globalStats: 'globalstats',
  cards: 'cards',
  locations: 'locations',
  provinces: 'provinces',
  categories: 'categories',
  userReviews: 'userreviews',
  globals: 'globals',
};

export default COLLECTIONS;
