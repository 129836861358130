import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AppLoading = ({ children }) => {
  const locations = useSelector((state) => state.locations.locations);

  if (locations) {
    return children;
  }
  return <></>;
};

AppLoading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { AppLoading };
