import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  row: {
    paddingTop: 5,
    display: 'flex',
    width: '100%',
  },
  title: {
    width: '100%',
  },
  price: {
    width: 120,
    height: '100%',
    textAlign: 'right',
  },
}));

const BillInformationLine = ({ title, price, color, isCredits }) => {
  const classes = useStyles();

  const renderAmount = () => {
    let currency = '$';
    if (isCredits) {
      currency = 'cr';
    }
    return (
      <Typography align={'right'} color={color} variant={'body1'} noWrap={true}>
        {price.toFixed(2)} {currency}
      </Typography>
    );
  };

  return (
    <div className={classes.row}>
      <div className={classes.title}>
        <Typography align={'left'} color={color} variant={'body1'} noWrap={true}>
          {title}
        </Typography>
      </div>
      <div className={classes.price}>{renderAmount()}</div>
    </div>
  );
};

BillInformationLine.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  color: PropTypes.string,
  isCredits: PropTypes.bool,
};

BillInformationLine.defaultProps = {
  color: 'primary',
  isCredits: false,
};

export default BillInformationLine;
