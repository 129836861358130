import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';

const ResultsHeader = ({ count }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  let isSmall;
  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isSmall = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true,
    });
  }

  const getStyle = () => {
    return {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: /* istanbul ignore next */ isSmall ? 0 : 180,
    };
  };

  const renderCount = () => {
    if (count === 0) {
      return t('searchresults_no_results');
    } else if (count === 1) {
      return `${count} ${t('searchresults_single_result')}`;
    }
    return `${count} ${t('searchresults_multiple_result')}`;
  };

  return (
    <div style={getStyle()}>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {renderCount()}
      </Typography>
    </div>
  );
};

ResultsHeader.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ResultsHeader;
