import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ReviewCardWithoutImage from './reviewCardWithoutImage';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';

const useStyles = makeStyles(() => ({
  lastRentals: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  noReviews: {
    marginLeft: 20,
    marginTop: 0,
  },
}));

const ListReviews = ({ reviews }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const renderComments = () => {
    if (reviews && reviews.length > 0) {
      return (
        <>
          {reviews.map((r, index) => {
            const { review, rating, image, location, displayName } = r;
            return (
              <ReviewCardWithoutImage
                key={`comments-${index}`}
                displayName={displayName}
                reviewComment={review}
                location={locationFor(locations, location).name[languageToLocale(locale)]}
                avatar={image}
                rating={rating}
                fullWidth={true}
              />
            );
          })}
        </>
      );
    }
    return (
      <div className={classes.noReviews}>
        <Typography align={'left'} color={'secondary'} variant={'h6'} noWrap={true}>
          {t('component_reviews_no_reviews')}
        </Typography>
      </div>
    );
  };

  return <div className={classes.lastRentals}>{renderComments()}</div>;
};

ListReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default ListReviews;
