/**
 * Use the CSS tab above to style your Element's container.
 */
import React, { useEffect, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import './CardSectionStyles.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  main: {
    height: 210,
  },
}));

const CardSection = ({ onOwnerNameChanged, onStateChange, initialCardValid }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [cardNumberValid, setCardNumberValid] = useState(initialCardValid);
  const [cardExpiryValid, setCardExpiryValid] = useState(initialCardValid);
  const [cardCvcValid, setCardCvcValid] = useState(initialCardValid);

  const onNameChange = (e) => {
    onOwnerNameChanged(e.target.value);
  };

  /* istanbul ignore next */
  const onCardNumberChange = (event) => {
    /* istanbul ignore next */
    setCardNumberValid(event.complete);
  };

  /* istanbul ignore next */
  const onCardExpiryChange = (event) => {
    /* istanbul ignore next */
    setCardExpiryValid(event.complete);
  };

  /* istanbul ignore next */
  const onCardCvcChange = (event) => {
    /* istanbul ignore next */
    setCardCvcValid(event.complete);
  };

  useEffect(() => {
    onStateChange(cardCvcValid && cardExpiryValid && cardNumberValid);
  }, [cardCvcValid, cardExpiryValid, cardNumberValid, onStateChange]);

  return (
    <div className={classes.main}>
      <TextField
        inputProps={{ 'data-testid': 'paywall_name_on_card' }}
        placeholder={t('paywall_name_on_card')}
        InputProps={{ disableUnderline: true }}
        onChange={onNameChange}
        fullWidth={true}
      />
      <CardNumberElement options={{ showIcon: true }} onChange={onCardNumberChange} />
      <CardExpiryElement onChange={onCardExpiryChange} />
      <CardCvcElement onChange={onCardCvcChange} />
    </div>
  );
};

CardSection.propTypes = {
  onOwnerNameChanged: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  initialCardValid: PropTypes.bool,
};

CardSection.defaultProps = {
  initialCardValid: false,
};

export default CardSection;
