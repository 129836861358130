import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import SecuredRoute from '../../routes/SecuredPage';
import { DASHBOARD } from '../../routes';
import { Badge, makeStyles, withStyles } from '@material-ui/core';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useAdServiceContext } from '../../services/ServicesProvider';
import DashboardRentedItems from '../../components/dashboardRentalItem';
import DashboardItems from './item';
import Profile from './profile';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tabPanel';
import Loading from '../../components/loading';
import { useTranslation } from 'react-i18next';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import { filter } from 'lodash';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(() => ({
  page: {
    paddingBottom: 50,
  },
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
  },
  tabsContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  tabs: {
    minWidth: 140,
  },
}));

const RightAlignedTab = withStyles((theme) => ({
  wrapper: {
    alignItems: 'flex-end',
    paddingRight: 15,
  },
}))(Tab);

const DashboardScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    registerLoadActiveAds,
    unregisterLoadActiveAds,
    registerLoadInactiveAds,
    unregisterLoadInactiveAds,
    loadRentals,
    deleteAd,
    loadStats,
    loadCompletedBookings,
    loadCompletedRentals,
    loadCancelledBookings,
    loadCancelledRentals,
  } = useRematchDispatch((dispatch) => ({
    registerLoadActiveAds: dispatch.ads.registerLoadActiveAds,
    unregisterLoadActiveAds: dispatch.ads.unregisterLoadActiveAds,
    registerLoadInactiveAds: dispatch.ads.registerLoadInactiveAds,
    unregisterLoadInactiveAds: dispatch.ads.unregisterLoadInactiveAds,
    loadRentals: dispatch.rent.loadRentals,
    deleteAd: dispatch.ads.deleteAd,
    loadStats: dispatch.ads.loadStats,
    loadCompletedBookings: dispatch.rent.loadCompletedBookings,
    loadCancelledBookings: dispatch.rent.loadCancelledBookings,
    loadCompletedRentals: dispatch.rent.loadCompletedRentals,
    loadCancelledRentals: dispatch.rent.loadCancelledRentals,
  }));

  const activeAds = useSelector((state) => state.ads.activeAds);
  const inactiveAds = useSelector((state) => state.ads.inactiveAds);
  const rentedItemsOwner = useSelector((state) => state.rent.rentalsOwner);
  const rentedItemsUser = useSelector((state) => state.rent.rentalsUser);
  const pendingApprovalAds = useSelector((state) => state.ads.pendingApprovalAds);
  const user = useSelector((state) => state.user.user);
  const disputeAds = useSelector((state) => state.ads.disputeAds);
  const completedRentals = useSelector((state) => state.rent.completedRentals);
  const cancelledRentals = useSelector((state) => state.rent.cancelledRentals);
  const completedBookings = useSelector((state) => state.rent.completedBookings);
  const cancelledBookings = useSelector((state) => state.rent.cancelledBookings);

  const [selectedTab, setSelectedTab] = useState(0);
  const [newPending, setNewPending] = useState(0);
  const [newDispute, setNewDispute] = useState(0);
  const [loading, setLoading] = useState(true);
  const adService = useAdServiceContext();

  useEffect(() => {
    async function doLoadAll() {
      /* istanbul ignore next */
      if (user) {
        const promises = [];
        promises.push(registerLoadInactiveAds());
        promises.push(registerLoadActiveAds());
        promises.push(loadRentals());
        promises.push(loadCompletedRentals());
        promises.push(loadCompletedBookings());
        promises.push(loadCancelledBookings());
        promises.push(loadCancelledRentals());
        await Promise.all(promises);
        setLoading(false);
      }
    }

    doLoadAll();
    return () => {
      unregisterLoadActiveAds();
      unregisterLoadInactiveAds();
    };
  }, [
    user,
    registerLoadActiveAds,
    unregisterLoadActiveAds,
    registerLoadInactiveAds,
    loadRentals,
    unregisterLoadInactiveAds,
    loadCompletedRentals,
    loadCancelledBookings,
    loadCompletedBookings,
    loadCancelledRentals,
  ]);

  useEffect(() => {
    if (user && (activeAds.length > 0 || inactiveAds.length > 0) && (selectedTab === 4 || selectedTab === 5)) {
      const activeAdsIds = activeAds.map((ad) => ad.uid);
      const inactiveAdsIds = inactiveAds.map((ad) => ad.uid);
      loadStats({ ads: [...activeAdsIds, ...inactiveAdsIds] });
    }
  }, [user, activeAds, inactiveAds, loadStats, selectedTab]);

  useEffect(() => {
    /* istanbul ignore next */
    if (pendingApprovalAds) {
      const unreads = filter(pendingApprovalAds, (o) => o.rent.unread === true);
      setNewPending(unreads.length);
    }
  }, [pendingApprovalAds]);

  useEffect(() => {
    /* istanbul ignore next */
    if (disputeAds) {
      const unreads = filter(disputeAds, (o) => o.rent.unread === true);
      setNewDispute(unreads.length);
    }
  }, [disputeAds]);

  const deleteAdById = () => {
    deleteAd({ id: selectedAdId });
    setOpenDeleteDialog(false);
  };

  const sortByBookingNumber = (item1, item2) => item2.rent.bookingNumber - item1.rent.bookingNumber;

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedAdId, setSelectedAdId] = useState();

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const deleteAdConfirm = (ad) => {
    setSelectedAdId(ad.uid);
    setOpenDeleteDialog(true);
  };

  const deactivateAd = (ad) => {
    adService.deactivate(ad.uid);
  };

  const reactivateAd = (ad) => {
    adService.activate(ad.uid);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const hasAds = () => activeAds.length > 0 || inactiveAds.length > 0;

  return (
    <SecuredRoute path={DASHBOARD}>
      <ProfileCheck>
        <div className={classes.page}>
          <div className={classes.header}>
            <Header />
          </div>

          <Loading isLoading={loading}>
            <Container maxWidth={'xl'}>
              <Profile />

              <div className={classes.tabsContainer}>
                <Tabs
                  orientation="vertical"
                  className={classes.tabs}
                  data-testid={'tabs'}
                  value={selectedTab}
                  onChange={handleChange}
                >
                  <RightAlignedTab
                    label={
                      <Badge badgeContent={newPending} color="error">
                        {t('dashboard_tabs_pending')}
                      </Badge>
                    }
                  />
                  <RightAlignedTab
                    label={
                      <Badge badgeContent={newDispute} color="error">
                        {t('dashboard_tabs_under_dispute')}
                      </Badge>
                    }
                  />
                  <RightAlignedTab label={t('dashboard_tabs_my_rentals')} />
                  <RightAlignedTab label={t('dashboard_tabs_my_bookings')} />
                  {hasAds() && <RightAlignedTab label={t('dashboard_tabs_active_ads')} />}
                  {hasAds() && <RightAlignedTab label={t('dashboard_tabs_inactive_ads')} />}
                </Tabs>

                <TabPanel value={selectedTab} index={0}>
                  <DashboardRentedItems items={pendingApprovalAds.sort(sortByBookingNumber)} />
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                  <DashboardRentedItems items={disputeAds.sort(sortByBookingNumber)} />
                </TabPanel>

                <TabPanel value={selectedTab} index={2}>
                  <DashboardRentedItems
                    items={[...rentedItemsUser, ...completedRentals, ...cancelledRentals].sort(sortByBookingNumber)}
                  />
                </TabPanel>

                <TabPanel value={selectedTab} index={3}>
                  <DashboardRentedItems
                    items={[...rentedItemsOwner, ...completedBookings, ...cancelledBookings].sort(sortByBookingNumber)}
                  />
                </TabPanel>

                {hasAds() && (
                  <TabPanel value={selectedTab} index={4}>
                    <DashboardItems items={activeAds} onDeactivateAd={deactivateAd} />
                  </TabPanel>
                )}

                {hasAds() && (
                  <TabPanel value={selectedTab} index={5}>
                    <DashboardItems items={inactiveAds} onReactivateAd={reactivateAd} onDeleteAd={deleteAdConfirm} />
                  </TabPanel>
                )}
              </div>
            </Container>
          </Loading>
        </div>
        <Dialog data-testid={'published-alert-dialog'} open={openDeleteDialog} onClose={handleClose}>
          <DialogTitle>{t('dashboard_confirm_delete_ad')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('dashboard_confirm_delete_ad_question')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid={'delete-ad-dialog-yes'} onClick={deleteAdById} color="primary">
              {t('action_yes')}
            </Button>
            <Button data-testid={'delete-ad-dialog-no'} onClick={handleClose} color="primary">
              {t('action_no')}
            </Button>
          </DialogActions>
        </Dialog>
      </ProfileCheck>
    </SecuredRoute>
  );
};

DashboardScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DashboardScreen);
