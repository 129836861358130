import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import SearchRadiusSelector from './SearchRadiusSelector';
import PropTypes from 'prop-types';
import PriceRange from './PriceRange';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
  },
  moreLink: {
    width: '100%',
    height: '100%',
    textAlign: 'right',
  },
  openedContainer: {
    paddingTop: 10,
  },
}));

const AdvancedSearch = ({ range, priceRange, onRadiusChange, onPriceRangeChanged, large, initiallyOpened }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(initiallyOpened);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Link
        className={classes.moreLink}
        data-testid={'advanced-link'}
        href="#"
        onClick={() => {
          setOpened(!opened);
        }}
      >
        {opened ? t('advanced_opened') : t('advanced_closed')}
      </Link>
      {opened && (
        <div className={classes.openedContainer}>
          <SearchRadiusSelector large={large} radius={range} onRadiusChange={onRadiusChange} />
          <PriceRange priceRange={priceRange} onPriceRangeChanged={onPriceRangeChanged} />
        </div>
      )}
    </div>
  );
};

AdvancedSearch.propTypes = {
  onRadiusChange: PropTypes.func.isRequired,
  onPriceRangeChanged: PropTypes.func.isRequired,
  range: PropTypes.number.isRequired,
  priceRange: PropTypes.object.isRequired,
  large: PropTypes.bool.isRequired,
  initiallyOpened: PropTypes.bool,
};

AdvancedSearch.defaultProps = {
  initiallyOpened: false,
};

export default AdvancedSearch;
