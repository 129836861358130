// styles
const regularButtonStyle = {
  display: 'flex',
  backgroundColor: 'transparent',
  color: 'white',
  borderWidth: 0,
  fontFamily: 'Oswald',
  fontSize: 14,
  height: 35,
  alignItems: 'center',
};
const mouseOverStyle = {
  backgroundColor: 'white',
  color: '#505050',
};

const selectedButtonStyle = (buttonOver, extraStyle) => {
  if (buttonOver) {
    return { ...regularButtonStyle, ...mouseOverStyle, ...extraStyle };
  }
  return { ...regularButtonStyle, ...extraStyle };
};

export { regularButtonStyle, mouseOverStyle, selectedButtonStyle };
