import { makeStyles } from '@material-ui/core';
import React from 'react';
import chainsaw from '../../assets/chainsaw.jpg';
import constructionWorker from '../../assets/construction-worker.jpg';
import studio from '../../assets/studio.jpg';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import RoundedImageWithHeader from '../../components/roundedImageWithHeader';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  headerText: {
    paddingLeft: 8,
    paddingTop: 120,
    paddingBottom: 24,
  },
}));

const Offering = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const clickTools = () => {
    onClick('tools');
  };

  const clickServices = () => {
    onClick('services');
  };

  const clickPlace = () => {
    onClick('places');
  };

  return (
    <div>
      <div className={classes.headerText}>
        <Typography color={'primary'} align={'left'} variant={'h4'}>
          {t('home_featuring')}
        </Typography>
      </div>
      <Grid container alignItems={'center'} justify={'center'}>
        <Grid item xs={12} xl={4} lg={4} md={4} sm={12}>
          <RoundedImageWithHeader
            testid={'offering-tools'}
            header={t('home_offering_tools')}
            image={chainsaw}
            onClick={clickTools}
          />
        </Grid>
        <Grid item xs={12} xl={4} lg={4} md={4} sm={12}>
          <RoundedImageWithHeader
            testid={'offering-services'}
            header={t('home_offering_services')}
            image={constructionWorker}
            onClick={clickServices}
          />
        </Grid>
        <Grid item xs={12} xl={4} lg={4} md={4} sm={12}>
          <RoundedImageWithHeader
            testid={'offering-places'}
            header={t('home_offering_places')}
            image={studio}
            onClick={clickPlace}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Offering.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Offering;
