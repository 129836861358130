import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

class FirebaseService {
  constructor(firestore, storage, auth, functions) {
    this.firestore = firestore;
    this.storage = storage;
    this.auth = auth;
    this.functions = functions;
    this.snapshots = [];
  }

  async logout() {
    this.snapshots.forEach((snapshot) => {
      snapshot();
    });
  }

  async writeToCollection(col, uid, payload) {
    const colRef = collection(this.firestore, col);
    let docRef;
    if (uid) {
      docRef = doc(colRef, uid);
    } else {
      docRef = doc(colRef);
    }
    await setDoc(docRef, payload, { merge: true }).catch(this.reportErrorAndRethrow);
    return docRef;
  }

  async createDocument(col, payload) {
    const docRef = await this.writeToCollection(col, undefined, payload);
    return docRef.id;
  }

  async queryDocumentsWithOperator(collection, criterias, sortOrder, limit = 500) {
    const q = this.buildQuery(collection, criterias, sortOrder, limit);

    const docs = await getDocs(q);
    if (docs.empty) {
      return [];
    }

    const documents = [];
    docs.forEach((doc) => {
      documents.push({ ...doc.data(), ...{ uid: doc.id } });
    });
    return documents;
  }

  async queryDocuments(collection, criterias, sortOrder, limit = 500) {
    const updatedCriterias = [];
    if (criterias) {
      Object.keys(criterias).forEach((key) => {
        updatedCriterias.push({ key, value: criterias[key], operator: '==' });
      });
    }
    return this.queryDocumentsWithOperator(collection, updatedCriterias, sortOrder, limit);
  }

  buildQuery(col, criterias, sortOrder, max) {
    let collectionRef = collection(this.firestore, col);
    let whereClauses = [];
    let orderByClauses = [];

    if (criterias) {
      criterias.forEach((criteria) => {
        whereClauses.push(where(criteria.key, criteria.operator, criteria.value));
        if (criteria.operator === '!=' && sortOrder) {
          orderByClauses.push(orderBy(criteria.key, sortOrder.defaultOrder));
        }
      });
    }

    if (sortOrder) {
      orderByClauses.push(orderBy(sortOrder.field, sortOrder.defaultOrder));
    }
    return query(collectionRef, ...whereClauses, ...orderByClauses, limit(max));
  }

  /* istanbul ignore next */
  async onSnapshotByCondition(col, criterias, sortOrder, onSuccess, max = 500) {
    const q = this.buildQuery(col, criterias, sortOrder, max);

    const s = onSnapshot(q, {
      next: (querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) => {
          documents.push({ ...doc.data(), ...{ uid: doc.id } });
        });
        onSuccess(documents);
      },
      error: (error) => {
        this.reportErrorAndRethrow(error);
      },
      complete: () => {},
    });
    this.snapshots.push(s);
    return s;
  }

  /* istanbul ignore next */
  async onSnapshotById(col, id, onSuccess) {
    const colRef = collection(this.firestore, col);
    const docRef = doc(colRef, id);

    const s = onSnapshot(docRef, {
      next: (snapshot) => {
        onSuccess(snapshot);
      },
      error: (error) => {
        console.error(`An error occurred in onSnapshotById ${error}`);
        this.reportErrorAndRethrow(error);
      },
      complete: () => {},
    });
    this.snapshots.push(s);
    return s;
  }

  /* istanbul ignore next */
  async signOut() {
    /* istanbul ignore next */
    await signOut(this.auth);
  }

  async getDocumentById(col, uid) {
    if (uid) {
      const colRef = collection(this.firestore, col);
      const docRef = doc(colRef, uid);
      return await getDoc(docRef).catch(this.reportErrorAndRethrow);
    }

    return {
      exists: () => {
        return false;
      },
    };
  }

  async uploadToStorage(image, imageUrlReadyCallback) {
    if (image) {
      // Create the file metadata
      const metadata = {
        contentType: image.type,
      };

      const storage = getStorage();
      const storageRef = ref(storage, uuidv4());
      await uploadBytes(storageRef, image, metadata);
      const downloadUrl = await getDownloadURL(storageRef);
      console.log(`Successfully uploaded to ${downloadUrl}`);
      imageUrlReadyCallback(downloadUrl);
    }
  }

  async removeFromStorage(imageRef) {
    const storage = getStorage();
    const storageRef = ref(storage, imageRef);

    try {
      await deleteObject(storageRef);
      return true;
    } catch (error) {
      return false;
    }
  }

  async deleteById(col, uid) {
    if (uid) {
      const colRef = collection(this.firestore, col);
      const docRef = doc(colRef, uid);
      return await deleteDoc(docRef).catch(this.reportErrorAndRethrow);
    }
  }

  /* istanbul ignore next */
  reportErrorAndRethrow(error) {
    /* istanbul ignore next */
    console.error(error);
  }

  async callUnsecuredFunction(functionName, payload) {
    var url = `${process.env.REACT_APP_BACKEND_URL}/api/functions/${functionName}`;
    const response = await axios.post(url, payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      timeout: 30000,
    });
    return response.data;
  }

  /* istanbul ignore next */
  async callFunction(functionName, payload) {
    var url = `${process.env.REACT_APP_BACKEND_URL}/api/functions/${functionName}`;
    const token = await this.getIdToken();
    const AuthStr = 'Bearer '.concat(token);
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: AuthStr,
        'Access-Control-Allow-Origin': '*',
      },
      timeout: 30000,
    });
    return response.data;
  }

  /* istanbul ignore next */
  async getIdToken() {
    return await this.auth.currentUser.getIdToken(true);
  }

  getAuth() {
    return this.auth;
  }
}

export default FirebaseService;
