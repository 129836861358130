/* istanbul ignore file */
import PropTypes from 'prop-types';

const threadMessage = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  threadId: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
});

const threadMessages = PropTypes.arrayOf(threadMessage);

export { threadMessage, threadMessages };
