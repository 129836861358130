import React from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchRadiusSelector from '../../components/SearchRadiusSelector';
import { useTranslation } from 'react-i18next';
import NotificationsBlock from '../../components/notificationsBlock';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  maxRadiusContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingBottom: 10,
  },
  notifications: {
    paddingTop: 20,
  },
  globalNotifications: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  specificNotifications: {
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
}));

const Preferences = ({ preferences, onPreferenceChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.user.user);

  const onRadiusChange = (radius) => {
    onPreferenceChange({
      radius,
      notifications: {
        enabled: preferences.notifications.enabled,
        sms: preferences.notifications.sms,
        email: preferences.notifications.email,
      },
    });
  };

  const handleNotificationsChange = (event) => {
    onPreferenceChange({
      radius: preferences.radius,
      notifications: {
        enabled: event.target.checked,
        sms: preferences.notifications.sms,
        email: preferences.notifications.email,
      },
    });
  };

  const onSmsNotifications = (notif, value) => {
    onPreferenceChange({
      radius: preferences.radius,
      notifications: {
        enabled: preferences.notifications.enabled,
        sms: { ...preferences.notifications.sms, [notif]: value },
        email: preferences.notifications.email,
      },
    });
  };

  const onEmailNotifications = (notif, value) => {
    onPreferenceChange({
      radius: preferences.radius,
      notifications: {
        enabled: preferences.notifications.enabled,
        sms: preferences.notifications.sms,
        email: { ...preferences.notifications.email, [notif]: value },
      },
    });
  };

  const userHasPhoneNumber = () => {
    return currentUser !== undefined && currentUser.phoneNumber !== undefined;
  };

  return (
    <div className={classes.maxRadiusContainer}>
      <SearchRadiusSelector radius={preferences.radius} onRadiusChange={onRadiusChange} />
      <div className={classes.notifications}>
        <FormControlLabel
          disabled={userHasPhoneNumber() === false}
          control={
            <Checkbox
              inputProps={{ 'data-testid': 'global-notifications-check' }}
              checked={preferences.notifications.enabled}
              onChange={handleNotificationsChange}
            />
          }
          label={t('preferences_notifications')}
        />
        <div className={classes.specificNotifications}>
          <NotificationsBlock
            enabled={preferences.notifications.enabled}
            type={t('preferences_notifications_phone')}
            notifications={preferences.notifications.sms}
            setNotifications={onSmsNotifications}
            testPrefix={'sms-'}
          />
          <NotificationsBlock
            enabled={preferences.notifications.enabled}
            type={t('preferences_notifications_email')}
            notifications={preferences.notifications.email}
            setNotifications={onEmailNotifications}
            testPrefix={'email-'}
          />
        </div>
      </div>
    </div>
  );
};

Preferences.propTypes = {
  onPreferenceChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
};

export default Preferences;
