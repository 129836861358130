import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../../utils/location';
import { languageToLocale } from '../../utils/locale';
import { RENT } from '../../routes';
import { useHistory, withRouter } from 'react-router-dom';
import ReviewCard from '../../components/reviewCard';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    paddingBottom: 30,
  },
  innerPanel: {
    paddingRight: 112,
  },
  newItemsText: {
    flex: 1,
    paddingBottom: 4,
    paddingLeft: 4,
  },
  spacer: {
    height: 8,
    width: '100%',
  },
  newItemBox: {
    padding: 4,
  },
}));

const RecentlyRented = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const user = useSelector((state) => state.authentication.user);

  const { loadLastRentals } = useRematchDispatch((dispatch) => ({
    loadLastRentals: dispatch.home.loadLastRentals,
  }));

  useEffect(() => {
    /* istanbul ignore next */
    loadLastRentals(user ? user.uid : undefined);
  }, [user, loadLastRentals]);

  const locations = useSelector((state) => state.locations.locations);
  const lastRentals = useSelector((state) => state.home.lastRentals);
  const locale = useSelector((state) => state.authentication.locale);

  const goToAd = (adId) => {
    history.push(RENT.replace(':adId', adId));
  };

  const theme = useTheme();

  let largeScreen = true;
  /* istanbul ignore next */
  if (theme) {
    largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  }
  const hasRental = () => {
    return lastRentals && lastRentals.length > 0;
  };

  const renderContainerContent = () => {
    return (
      <>
        {lastRentals &&
          lastRentals.map((stat, index) => {
            const { review, rating, avatar, location, image, adId } = stat;
            return (
              <Grid item key={`recentlyrented-${index}`}>
                <div className={classes.newItemBox}>
                  <ReviewCard
                    id={`recentlyrented-${index}`}
                    reviewComment={review}
                    location={locationFor(locations, location).name[languageToLocale(locale)]}
                    image={image}
                    avatar={avatar}
                    rating={rating}
                    onAdClick={() => goToAd(adId)}
                  />
                </div>
                <div className={classes.spacer} />
              </Grid>
            );
          })}
      </>
    );
  };

  const renderContainer = () => {
    /* istanbul ignore next */
    if (largeScreen) {
      return (
        <div>
          <Grid container>{renderContainerContent()}</Grid>
        </div>
      );
    }
    /* istanbul ignore next */
    return (
      <Grid container direction={'column'} alignItems="center">
        {renderContainerContent()}
      </Grid>
    );
  };

  return (
    <div className={classes.container}>
      {hasRental() && (
        <div className={classes.newItemsText}>
          <Typography color={'primary'} align={'left'} variant={'h6'}>
            {t('home_recently_rented')}
          </Typography>
        </div>
      )}
      {renderContainer()}
    </div>
  );
};

export default withRouter(RecentlyRented);
