import React, { useState } from 'react';
import { Checkbox, Container, FormControlLabel, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import HelpPage from '../../components/helpPage';
import PropTypes from 'prop-types';

const Onboarding = ({ initiallyOpened, onShowNextTime }) => {
  const { t } = useTranslation();

  const getDialogContentHeight = () => {
    const { innerHeight } = window;
    return innerHeight * 0.8;
  };

  const [showNextTime, setShowNextTime] = useState(true);

  const [open, setOpen] = useState(initiallyOpened);

  const handleShowNextTimeChange = (event) => {
    setShowNextTime(event.target.checked);
    onShowNextTime(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog data-testid={'onboarding-dialog'} open={open} scroll={'paper'} maxWidth={'xl'}>
      <DialogTitle disableTypography={true}>
        <Typography color={'primary'} align={'left'} variant={'h3'}>
          {t('onboarding_welcome')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ height: getDialogContentHeight() }}>
        <Container maxWidth={'lg'}>
          <HelpPage enableLinks={false} />
        </Container>
      </DialogContent>
      <div style={{ paddingTop: 10, paddingLeft: 10 }}>
        <FormControlLabel
          label={t('onboarding_shownexttime')}
          control={
            <Checkbox
              inputProps={{ 'data-testid': 'onboarding_shownexttime' }}
              checked={showNextTime}
              onChange={handleShowNextTimeChange}
            />
          }
        />
      </div>
      <DialogActions>
        <Button data-testid={'onboarding-dialog-close'} color="primary" onClick={handleClose}>
          {t('action_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Onboarding.propTypes = {
  initiallyOpened: PropTypes.bool.isRequired,
  onShowNextTime: PropTypes.func.isRequired,
};

export default Onboarding;
