import React, { useEffect } from 'react';
import useRematchDispatch from '../hooks/useRematchDispatch';

const LocationLoader = () => {
  const { loadLocations } = useRematchDispatch((dispatch) => ({
    loadLocations: dispatch.locations.loadLocations,
  }));

  useEffect(() => {
    async function doLoadLocations() {
      await loadLocations();
    }

    doLoadLocations();
  }, [loadLocations]);

  return <></>;
};

export default LocationLoader;
