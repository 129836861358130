import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import SecuredRoute from '../../routes/SecuredPage';
import { DASHBOARD, DISPUTE } from '../../routes';
import Header from '../../components/header';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import AdNotFoundContainer from '../../components/adNotFoundContainer';
import ContactInfo from '../../components/contactInfo';
import { useTranslation } from 'react-i18next';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import ResizableImageBox from '../../components/resizableImageBox';
import { useTheme } from '@material-ui/styles';
import { AdDescription } from '../../components/adDescription';
import Loading from '../../components/loading';
import { UserAndRentalDates } from '../../components/userAndDatesInfoBlock';
import Button from '@material-ui/core/Button';
import { DisputeSnackbar } from '../../components/disputeSnackbar';
import moment from 'moment';
import Messages from '../Inbox/messages';
import { useAdServiceContext, useMessageServiceContext } from '../../services/ServicesProvider';
import { Overcharge } from '../CompleteReturn/overcharge';
import { RENT_STATUS } from '../../models/rentStatus';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BillingSection from '../../components/billingSection';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingLeft: 25,
    paddingRight: 25,
    boxSizing: 'border-box',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imageAndCalendar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: 150,
  },
  discussionBox: {
    width: '100%',
    height: '100%',
    paddingTop: 30,
  },
  wrapperForMessages: {
    width: '100%',
    height: '100%',
    paddingTop: 20,
    paddingBottom: 30,
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    height: '100%',
  },
  overchargeBox: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
}));

const Dispute = ({ history, rentId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [paymentInfoLoading, setPayloadLoadingInfoLoading] = useState(true);
  const [explanation, setExplanation] = useState();
  const [charge, setCharge] = useState();

  const ad = useSelector((state) => state.ads.currentAd);
  const renterUser = useSelector((state) => state.rent.user);
  const owner = useSelector((state) => state.rent.owner);

  const threadMessages = useSelector((state) => state.inbox.threadMessages);
  const user = useSelector((state) => state.user.user);
  const [item, setItem] = useState(undefined);

  const rental = useSelector((state) => state.rent.currentRentalSnapshot);
  const { loadAd, clearAd, registerRental, loadOwner, loadUser, unregisterCurrentRental } = useRematchDispatch(
    (dispatch) => ({
      loadAd: dispatch.ads.loadAd,
      clearAd: dispatch.ads.clearAd,
      registerRental: dispatch.rent.registerRental,
      unregisterCurrentRental: dispatch.rent.unregisterCurrentRental,
      loadOwner: dispatch.rent.loadOwner,
      loadUser: dispatch.rent.loadUser,
    }),
  );

  const { registerLoadThreadMessages, unregisterLoadThreadMessages } = useRematchDispatch((dispatch) => ({
    registerLoadThreadMessages: dispatch.inbox.registerLoadThreadMessages,
    unregisterLoadThreadMessages: dispatch.inbox.unregisterLoadThreadMessages,
  }));

  const [disputeOpened, setDisputeOpened] = useState(true);
  const messageService = useMessageServiceContext();
  const adService = useAdServiceContext();

  const createMessage = (message) => {
    messageService.createNewMessageInThread({
      threadId: rental.disputeId,
      user: user.uid,
      message,
      isOwner: user.uid === rental.owner,
    });
  };

  useEffect(() => {
    registerRental({ id: rentId });

    return () => {
      unregisterCurrentRental();
    };
  }, [rentId, registerRental, unregisterCurrentRental]);

  useEffect(() => {
    /* istanbul ignore next */
    if (rental) {
      adService.markAsRead(rental);
      setCharge(rental.charge);
      setExplanation(rental.explanation);
      loadAd({ id: rental.ad });
      loadOwner({ id: rental.owner });
      loadUser({ id: rental.user });
    }
  }, [rental, loadAd, loadOwner, loadUser, adService]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  useEffect(() => {
    /* istanbul ignore next */
    if (ad !== undefined && owner !== undefined && renterUser !== undefined && rental !== undefined) {
      setItem({ ad, owner, user: renterUser, rent: rental });
      setLoading(false);
    }
  }, [ad, owner, renterUser, rental]);

  useEffect(() => {
    /* istanbul ignore next */
    if (rental && rental.status === RENT_STATUS.CHARGE_ACCEPTED) {
      setDisputeOpened(false);
    }
  }, [rental]);

  const acceptCharge = async () => {
    await adService.acceptCharge(rentId);
    history.push(DASHBOARD);
  };

  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  useEffect(() => {
    /* istanbul ignore next */
    if (rental && rental.disputeId) {
      registerLoadThreadMessages({ threadId: rental.disputeId });
    }
  }, [rental, registerLoadThreadMessages]);

  useEffect(() => {
    return () => {
      unregisterLoadThreadMessages();
    };
  }, [unregisterLoadThreadMessages]);

  const getCalendarStyle = () => {
    return {
      width: '100%',
      height: '100%',
      paddingTop: 30,
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 30,
      paddingRight: /* istanbul ignore next */ isMobile ? 0 : 30,
      backgroundColor: '#f7f7f5',
      boxSizing: 'border-box',
    };
  };

  const isOwner = rental !== undefined && rental.owner === user.uid;

  const isChargeUpdated = rental !== undefined && (rental.charge !== charge || rental.explanation !== explanation);

  const computeRemainingDays = () => {
    let startDate;

    /* istanbul ignore next */
    if (!!rental && rental.disputeStartDate !== undefined) {
      startDate = rental.disputeStartDate.toDate();
    } else {
      /* istanbul ignore next */
      startDate = new Date();
    }

    const disputeStartDate = moment(startDate).startOf('day');
    return Math.max(
      7 -
        moment()
          .startOf('day')
          .diff(moment(disputeStartDate), 'days'),
      0,
    );
  };

  const updateCharge = async () => {
    const { uid, disputeId, owner } = rental;
    await adService.updateDispute(uid, {
      charge,
      explanation,
      disputeId,
      owner,
    });
  };

  /* istanbul ignore next */
  const handleClose = () => {
    /* istanbul ignore next */
    history.push(DASHBOARD);
  };

  /* istanbul ignore next */
  const onBillingSectionLoadingDone = () => {
    /* istanbul ignore next */
    setPayloadLoadingInfoLoading(false);
  };

  const renderContent = () => {
    if (loading) {
      return <></>;
    }
    return (
      <div className={classes.subContainer}>
        <DisputeSnackbar daysRemaining={computeRemainingDays()} statusOpened={disputeOpened} />
        <AdDescription ad={ad} />
        <div data-testid={'pageloaded'} className={classes.imageAndCalendar}>
          <Grid container>
            <Grid item>
              <div className={classes.fullFlex}>
                <ResizableImageBox ad={ad} center={false} />
              </div>
            </Grid>
            <Grid item>
              <div style={getCalendarStyle()}>
                <ContactInfo user={isOwner ? renterUser : owner} />
                <UserAndRentalDates rental={rental} />
                <BillingSection item={item} onLoadingDone={onBillingSectionLoadingDone} />
              </div>
            </Grid>
          </Grid>
        </div>
        {isOwner && (
          <div className={classes.overchargeBox}>
            <Overcharge
              ad={ad}
              charge={charge}
              onChargeChanged={setCharge}
              explanation={explanation}
              onExplanationChanged={setExplanation}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                disabled={!isChargeUpdated}
                data-testid={'updateCharge'}
                variant="contained"
                color="primary"
                onClick={updateCharge}
              >
                {t('dispute_update')}
              </Button>
            </div>
          </div>
        )}
        <div className={classes.discussionBox}>
          <Typography color={'primary'} align={'left'} variant={'h4'}>
            {t('dispute_discussion')}
          </Typography>
          <div className={classes.wrapperForMessages}>
            <Messages
              items={threadMessages}
              onSendMessage={createMessage}
              ownerId={rental.owner}
              renterId={rental.user}
            />
          </div>
        </div>
        {!isOwner && (
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              data-testid={'acceptCharge'}
              variant="contained"
              color="primary"
              onClick={acceptCharge}
            >
              {t('dispute_accept_button')}
            </Button>
          </div>
        )}
        <Dialog data-testid={'closed-alert-dialog'} open={disputeOpened === false} onClose={handleClose}>
          <DialogTitle>{t('dispute_resolved')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('dispute_successfully_settled')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid={'dispute-alert-dialog-close'} onClick={handleClose} color="primary">
              {t('action_close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <SecuredRoute path={DISPUTE}>
      <ProfileCheck>
        <Header />
        <div className={classes.page}>
          <div className={classes.content}>
            <div className={classes.mainContainer}>
              <AdNotFoundContainer ad={ad}>
                <Loading isLoading={loading && paymentInfoLoading}>{renderContent()}</Loading>
              </AdNotFoundContainer>
            </div>
          </div>
        </div>
      </ProfileCheck>
    </SecuredRoute>
  );
};

Dispute.propTypes = {
  rentId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Dispute);
