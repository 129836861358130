/* istanbul ignore file */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './assets/translations';

i18next.use(initReactI18next).init({
  resources: translations,
  fallbackLng: 'en',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
