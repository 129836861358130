import React from 'react';
import toolbox from '../../assets/tools-transparent.png';
import { Container, makeStyles } from '@material-ui/core';
import Header from '../../components/header';
import PropTypes from 'prop-types';
import { DynamicLogo } from '../../components/dynamicLogo';
import { DASHBOARD, INBOX, POST_AD, SEARCH_RESULTS } from '../../routes';
import { withRouter } from 'react-router-dom';
import HelpPage from '../../components/helpPage';

const useStyles = makeStyles(() => ({
  imageLogo: {
    minWidth: 3500,
    opacity: 0.7,
  },

  imageContainer: {
    width: '100%',
    overflow: 'hidden',
    maxHeight: 1200,
  },

  mainContainer: {
    position: 'absolute',
    top: 100,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
  },
}));

const HelpScreen = ({ history }) => {
  const classes = useStyles();

  document.body.style.backgroundColor = '#f2f2f2';

  const goToPublish = () => {
    history.push(POST_AD);
  };

  const goToDashboard = () => {
    history.push(DASHBOARD);
  };

  const goToSearch = () => {
    history.push(SEARCH_RESULTS);
  };

  const goToInbox = () => {
    history.push(INBOX);
  };

  return (
    <>
      <div>
        <header className="App-header">
          <Header />
          <div className={classes.imageContainer}>
            <img src={toolbox} className={classes.imageLogo} alt={''} />
          </div>
        </header>
      </div>
      <div className={classes.mainContainer}>
        <DynamicLogo />
        <Container maxWidth={'lg'}>
          <HelpPage
            enableLinks={true}
            goToDashboard={goToDashboard}
            goToInbox={goToInbox}
            goToPublish={goToPublish}
            goToSearch={goToSearch}
          />
        </Container>
      </div>
    </>
  );
};

HelpScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(HelpScreen);
