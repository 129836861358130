import { createModel } from '@rematch/core';
import COLLECTIONS from '../collections';
import { firebaseService } from '../services/firebaseServiceInstance';
import ensureUid from '../services/firebaseTools';

export const createProfileModel = () =>
  createModel({
    state: {
      user: undefined,
      userReviews: [],
      userReviewsSnapshotRef: undefined,
    },

    reducers: {
      updateUser(state, user) {
        return { ...state, user };
      },
      updateReviews(state, userReviews) {
        return { ...state, userReviews };
      },
      updateUserReviewsSnapshotRef(state, userReviewsSnapshotRef) {
        return { ...state, userReviewsSnapshotRef };
      },
      reset(state) {
        return { ...state, user: undefined, userReviews: [], userReviewsSnapshotRef: undefined };
      },
    },

    effects: (dispatch) => ({
      async loadUser({ uid }) {
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, uid);
        if (userDocument.exists()) {
          const user = ensureUid(userDocument);

          dispatch.profile.updateUser(user);
        }
      },

      async loadUserReviews({ uid, maxResults }) {
        const userReviewsSnapshotRef = await firebaseService.onSnapshotByCondition(
          COLLECTIONS.userReviews,
          [{ key: 'user', value: uid, operator: '==' }],
          { field: 'createdDate', defaultOrder: 'desc' },
          (userReviews) => {
            dispatch.profile.updateReviews(userReviews);
          },
          maxResults,
        );
        dispatch.profile.updateUserReviewsSnapshotRef(userReviewsSnapshotRef);
      },

      async unregisterLoadUserReviews(payload, rootState) {
        if (rootState.profile.userReviewsSnapshotRef) {
          rootState.profile.userReviewsSnapshotRef();
        }
        dispatch.profile.updateUserReviewsSnapshotRef(undefined);
      },
    }),
  });
