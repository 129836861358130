import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';
import { InformativeLabel } from './informativeLabel';
import RentShape from '../shapes/rentShape';
import UserShape from '../shapes/userShape';

const useStyles = makeStyles(() => ({
  renterItemInformation: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
  },
  commentBox: {
    display: 'block',
    width: '100%',
    height: 125,
    fontFamily: 'Oswald',
    color: '#1c1e6e',
    fontSize: 12,
    textAlign: 'left',
    marginRight: 10,
  },
  infoBlock: {
    paddingTop: 20,
  },
}));

const UserAddressBlock = ({ isOwner, rent, user, onUserClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const getFormattedPhoneNumber = () => {
    const phoneNumber = user.phoneNumber;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (phoneRegex.test(phoneNumber)) {
      return phoneNumber.replace(phoneRegex, '($1) $2-$3');
    }
    return phoneNumber;
  };

  const showAddress = () => {
    return (
      isOwner === false ||
      rent.status === 'PENDING_PAYMENT' ||
      rent.status === 'APPROVED' ||
      rent.status === 'IN_PROGRESS' ||
      rent.status === 'COMPLETED'
    );
  };

  const renderDate = (label, date, time) => {
    const { unit } = rent;
    if (unit === undefined || unit === 'days') {
      return <InformativeLabel label={label} value={moment(date.toDate()).format('YYYY-MM-DD')} />;
    }
    return <InformativeLabel label={label} value={`${moment(date.toDate()).format('YYYY-MM-DD')} ${time}:00`} />;
  };

  return (
    <div className={classes.renterItemInformation}>
      {renderDate(t('dashboard_rent_start_date'), rent.startDate, rent.startTime)}
      {renderDate(t('dashboard_rent_end_date'), rent.endDate, rent.endTime)}
      <InformativeLabel
        onClick={onUserClick}
        label={isOwner ? t('dashboard_rent_owner_name') : t('dashboard_rent_renter_name')}
        value={/* istanbul ignore next */ user ? user.displayName : ''}
      />
      {showAddress() === true && (
        <div className={classes.infoBlock}>
          <Typography color={'primary'} align={'left'} variant={'body2'}>
            {isOwner ? t('dashboard_rent_owner_address') : t('dashboard_rent_renter_address')}
          </Typography>
          <Typography color={'textPrimary'} align={'left'} variant={'body2'}>
            {user.street}
          </Typography>
          <Typography color={'textPrimary'} align={'left'} variant={'body2'}>
            {locationFor(locations, user.location).name[languageToLocale(locale)]}
          </Typography>
          <Typography color={'textPrimary'} align={'left'} variant={'body2'}>
            {user.zipcode}
          </Typography>
        </div>
      )}
      <div className={classes.infoBlock}>
        <Typography color={'primary'} align={'left'} variant={'body2'}>
          {t('dashboard_rent_coordinates')}
        </Typography>
        <Typography color={'textPrimary'} align={'left'} variant={'body2'}>
          {getFormattedPhoneNumber()}
        </Typography>
        <Typography color={'textPrimary'} align={'left'} variant={'body2'}>
          {user.email}
        </Typography>
      </div>
    </div>
  );
};

UserAddressBlock.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  rent: RentShape.isRequired,
  user: UserShape.isRequired,
  onUserClick: PropTypes.func,
};

UserAddressBlock.defaultProps = {
  onUserClick: undefined,
};

export default UserAddressBlock;
