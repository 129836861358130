import React from 'react';
import { HeaderToggleButton } from './headerToggleButton';
import { useTranslation } from 'react-i18next';

const LogoutButton = ({ onClick }) => {
  const { t } = useTranslation();

  return <HeaderToggleButton extraStyle={{ width: 95 }} onClick={onClick} title={t('component_logout')} />;
};

export default LogoutButton;
