import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  snackbar: {
    backgroundColor: '#8b0000',
  },
}));

const DisputeSnackbar = ({ daysRemaining, statusOpened }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(statusOpened);

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(statusOpened);
  }, [statusOpened]);

  const computeRemainingDaysMessage = () => {
    if (daysRemaining > 0) {
      return t('item_under_dispute', { days: daysRemaining });
    } else {
      return t('item_under_dispute_today');
    }
  };

  return (
    <Snackbar
      classes={{
        root: classes.root,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
    >
      <SnackbarContent
        className={classes.snackbar}
        message={<span>{computeRemainingDaysMessage()}</span>}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon data-testid={'closeSnackbar'} fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Snackbar>
  );
};

DisputeSnackbar.propTypes = {
  daysRemaining: PropTypes.number.isRequired,
  statusOpened: PropTypes.bool,
};

DisputeSnackbar.defaultProps = {
  statusOpened: true,
};

export { DisputeSnackbar };
