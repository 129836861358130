import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import Rating from './rating';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  review: {
    paddingTop: 20,
    width: '100%',
  },
}));

const ReviewPanel = ({ rating, review, onRatingChanged, onReviewChanged }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const ratingIsSet = () => rating !== 0;

  const handleOnReviewChanged = (event) => {
    onReviewChanged(event.target.value);
  };

  return (
    <div className={classes.review}>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('return_review')}
      </Typography>
      <Rating onSetRating={onRatingChanged} />
      <TextField
        disabled={!ratingIsSet()}
        fullWidth={true}
        multiline={true}
        rows={8}
        inputProps={{ 'data-testid': 'review' }}
        variant="outlined"
        value={review}
        onChange={handleOnReviewChanged}
      />
    </div>
  );
};

ReviewPanel.propTypes = {
  rating: PropTypes.number.isRequired,
  review: PropTypes.string,
  onRatingChanged: PropTypes.func.isRequired,
  onReviewChanged: PropTypes.func.isRequired,
};

export { ReviewPanel };
