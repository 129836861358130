import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { languageToLocale } from '../utils/locale';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    height: '100%',
  },
}));

const AutoCompleteInputTextField = ({ title, onChange, options, initialValue, locale, id }) => {
  const classes = useStyles();
  const onPropertyChange = (id) => {
    onChange(id);
  };

  const getLabel = (option) => {
    /* istanbul ignore next */
    if (option.name) {
      return option.name[languageToLocale(locale)];
    }
    /* istanbul ignore next */
    return '';
  };

  return (
    <div className={classes.mainContainer}>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {title}
      </Typography>
      <Autocomplete
        disabled={options.length === 0}
        style={{ width: '100%' }}
        options={options}
        classes={{
          option: classes.option,
        }}
        disableClearable={true}
        autoHighlight
        value={initialValue ? initialValue : {}}
        getOptionSelected={(option) => {
          if (option && initialValue) {
            if (option.uid) {
              return option.uid === initialValue.uid;
            } else {
              return option.code === initialValue.code;
            }
          }
        }}
        getOptionLabel={(option) => getLabel(option)}
        onChange={(event, newValue) => {
          /* istanbul ignore next */
          if (newValue) {
            onPropertyChange(newValue);
          }
        }}
        renderOption={(option) => (
          <React.Fragment>
            <span>{option.name[languageToLocale(locale)]}</span>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              ...{ id: id ? id : 'autoCompleteInputField' },
            }}
          />
        )}
      />
    </div>
  );
};

AutoCompleteInputTextField.propTypes = {
  title: PropTypes.string,
  initialValue: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default AutoCompleteInputTextField;
