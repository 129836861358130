import React from 'react';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  maxRadiusLabel: {
    display: 'flex',
    width: '100%',
  },
}));

const SearchRadiusSelector = ({ radius, onRadiusChange, large }) => {
  const classes = useStyles();
  const SEARCH_RADIUS = [5, 10, 25, 50, 100, 250, 500];

  const { t } = useTranslation();

  return (
    <>
      <div className={classes.maxRadiusLabel}>
        <Typography color={'primary'} align={'left'} variant={large ? 'h6' : 'body1'}>
          {t('max_search_radius')}
        </Typography>
      </div>
      <Select
        align={'left'}
        className={classes.selector}
        id="radius"
        value={radius}
        fullWidth
        onChange={(event) => onRadiusChange(event.target.value)}
      >
        {SEARCH_RADIUS.map((radius) => {
          return (
            <MenuItem key={`radius_${radius}`} value={radius} style={{ backgroundColor: 'white' }}>
              {`${radius} km`}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

SearchRadiusSelector.propTypes = {
  onRadiusChange: PropTypes.func.isRequired,
  radius: PropTypes.number.isRequired,
  large: PropTypes.bool,
};

SearchRadiusSelector.defaultProps = {
  large: true,
};

export default SearchRadiusSelector;
