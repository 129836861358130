import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    paddingBottom: 10,
  },
}));

const InputNumericField = ({ title, value, onChange, testid, large }) => {
  const classes = useStyles();

  const onPropertyChange = (event) => {
    const re = /^[0-9\b]+$/;

    if (event.target.value === '') {
      onChange('');
    } else if (re.test(event.target.value)) {
      onChange(event.target.value);
    }
    event.preventDefault();
  };

  return (
    <div className={classes.mainContainer}>
      <Typography color={'primary'} align={'left'} variant={large ? 'h6' : 'body1'}>
        {title}
      </Typography>
      <TextField
        fullWidth
        inputProps={{ 'data-testid': testid }}
        value={value}
        variant="outlined"
        onChange={onPropertyChange}
      />
    </div>
  );
};

InputNumericField.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  testid: PropTypes.string,
  large: PropTypes.bool,
};

InputNumericField.defaultProps = {
  value: undefined,
  large: true,
};

export default InputNumericField;
