import React, { useState } from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import adShape from '../../shapes/adShape';
import PropTypes from 'prop-types';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(() => ({
  elementSpacer: {
    width: '50%',
    paddingTop: 20,
  },
  errorMessage: {
    height: 50,
    paddingTop: 5,
    fontFamily: 'Oswald bold',
  },
  description: {
    paddingTop: 20,
    width: '100%',
  },
}));

const Overcharge = ({ ad, charge, onChargeChanged, explanation, onExplanationChanged }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const chargeIsSet = () => charge !== 0;

  const [overchargeError, setOverchargeError] = useState(false);

  const handleOnChargeChanged = (event) => {
    if (isNaN(event.target.value) || event.target.value === '') {
      setOverchargeError(t('paywall_charge_invalid', { max: ad.securityDeposit }));
    } else {
      const newCharge = parseInt(event.target.value);
      if (newCharge > ad.securityDeposit) {
        setOverchargeError(t('paywall_charge_invalid', { max: ad.securityDeposit }));
      } else {
        setOverchargeError(undefined);
      }
      onChargeChanged(newCharge);
    }
  };

  const handleExplanationChanged = (event) => {
    onExplanationChanged(event.target.value);
  };

  return (
    <>
      <div className={classes.elementSpacer}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography color={'primary'} align={'left'} variant={'h6'}>
            {t('complete_overcharge')}
          </Typography>
          <Tooltip title={t('complete_overcharge_explanation')}>
            <HelpIcon />
          </Tooltip>
        </div>
        <TextField
          inputProps={{ 'data-testid': 'charge', min: '0', max: ad && ad.securityDeposit, step: '1' }}
          value={charge}
          variant="outlined"
          onChange={(event) => handleOnChargeChanged(event)}
          type={'number'}
        />
        <div className={classes.errorMessage}>
          {overchargeError && (
            <Typography color={'error'} align={'left'} variant={'subtitle1'}>
              {overchargeError}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.description}>
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('complete_explanation')}
        </Typography>
        <TextField
          disabled={!chargeIsSet()}
          fullWidth={true}
          multiline={true}
          rows={8}
          inputProps={{ 'data-testid': 'explanation' }}
          variant="outlined"
          value={explanation}
          onChange={(event) => handleExplanationChanged(event)}
        />
      </div>
    </>
  );
};

Overcharge.propTypes = {
  ad: adShape.isRequired,
  charge: PropTypes.number.isRequired,
  onChargeChanged: PropTypes.func.isRequired,
  explanation: PropTypes.string.isRequired,
  onExplanationChanged: PropTypes.func.isRequired,
};

export { Overcharge };
