import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { threadMessages } from '../../shapes/threadMessageShape';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import notavailable from '../../assets/noimageavailable.png';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  preview: {
    maxHeight: '80vh',
    overflow: 'auto',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
  },
  avatar: {
    width: 25,
    height: 25,
  },
  searchbox: {
    paddingTop: 10,
    width: '100%',
    display: 'flex',
  },
  roundButton: {
    borderRadius: '50%',
  },
});

const row = {
  display: 'flex',
  width: '100%',
};

const baseMessageContainer = {
  display: 'flex',
  maxWidth: '30%',
  borderRadius: '10px',
  backgroundColor: '#ededed',
  paddingLeft: 5,
  paddingRight: 5,
};

const Messages = ({ items, onSendMessage, ownerId, renterId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { loadOwner, loadUser } = useRematchDispatch((dispatch) => ({
    loadOwner: dispatch.inbox.loadOwner,
    loadUser: dispatch.inbox.loadUser,
  }));

  const owner = useSelector((state) => state.inbox.owner);
  const user = useSelector((state) => state.inbox.user);
  const currentUser = useSelector((state) => state.user.user);

  const isMyself = (message) => {
    return message !== undefined && user !== undefined && message.user === currentUser.uid;
  };

  const computeChatPosition = (message) => {
    if (isMyself(message)) {
      return { ...row, justifyContent: 'flex-start' };
    }
    return { ...row, justifyContent: 'flex-end' };
  };

  const computeRowStyle = (message) => {
    if (isMyself(message)) {
      return { ...baseMessageContainer, justifyContent: 'flex-start' };
    }
    return { ...baseMessageContainer, justifyContent: 'flex-end' };
  };

  const [ownerPhoto, setOwnerPhoto] = useState(notavailable);
  const [userPhoto, setUserPhoto] = useState(notavailable);
  const [message, setMessage] = useState('');

  const isLoading = () => owner === undefined || user === undefined;

  const sendMessage = () => {
    onSendMessage(message);
    setMessage('');
  };

  useEffect(() => {
    if (ownerId) {
      loadOwner({ id: ownerId });
    }
  }, [ownerId, loadOwner]);

  useEffect(() => {
    if (renterId) {
      loadUser({ id: renterId });
    }
  }, [renterId, loadUser]);

  useEffect(() => {
    if (owner) {
      setOwnerPhoto(owner.photoURL);
    } else {
      setOwnerPhoto(notavailable);
    }
  }, [owner]);

  useEffect(() => {
    if (user) {
      setUserPhoto(user.photoURL);
    } else {
      setUserPhoto(notavailable);
    }
  }, [user]);

  const getAvatar = (message) => {
    /* istanbul ignore next */
    if (user) {
      if (isMyself(message) && ownerId === currentUser.uid) {
        return ownerPhoto;
      }

      /* istanbul ignore next */
      if (message.user === ownerId) {
        return ownerPhoto;
      }
      return userPhoto;
    }
    /* istanbul ignore next */
    return notavailable;
  };

  return (
    <div className={classes.preview}>
      {isLoading() && t('loading')}
      {isLoading() === false && (
        <>
          {items.map((message) => {
            return (
              <div key={message.uid} style={computeChatPosition(message)}>
                {isMyself(message) && (
                  <div className={classes.avatarContainer}>
                    <Avatar className={classes.avatar} src={getAvatar(message)} />
                  </div>
                )}
                <div style={computeRowStyle(message)}>
                  <Typography color={'primary'} align={'left'} variant={'h6'}>
                    {message.message}
                  </Typography>
                </div>
                {!isMyself(message) && (
                  <div className={classes.avatarContainer}>
                    <Avatar className={classes.avatar} src={getAvatar(message)} />
                  </div>
                )}
              </div>
            );
          })}
          <div className={classes.searchbox}>
            <TextField
              inputProps={{ 'data-testid': 'message' }}
              fullWidth
              id="message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              onKeyDown={
                /* istanbul ignore next */ (event) => {
                  /* istanbul ignore next */
                  if (event.key === 'Enter') {
                    sendMessage();
                  }
                }
              }
            />
            <Button
              data-testid={'sendMessage'}
              disabled={!message}
              onClick={sendMessage}
              className={classes.roundButton}
            >
              <SendIcon />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

Messages.propTypes = {
  items: threadMessages.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  ownerId: PropTypes.string,
  renterId: PropTypes.string,
};

export default Messages;
