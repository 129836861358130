import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ReviewCardWithoutImage from './reviewCardWithoutImage';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';

const useStyles = makeStyles(() => ({
  lastRentals: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  noReviews: {
    display: 'block',
    marginTop: 0,
    justifyContent: 'center',
    width: 200,
    height: 186,
  },
}));

const GridReviews = ({ reviews }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const renderComments = () => {
    if (reviews && reviews.length > 0) {
      return (
        <Grid container spacing={3}>
          {reviews.map((rental, index) => {
            const { review, rating, image, location, displayName } = rental;
            return (
              <Grid key={`rc-${index}`} item lg={3} md={4} sm={6} xs={12}>
                <ReviewCardWithoutImage
                  displayName={displayName}
                  reviewComment={review}
                  location={locationFor(locations, location).name[languageToLocale(locale)]}
                  avatar={image}
                  rating={rating}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }
    return (
      <div className={classes.noReviews}>
        <Typography align={'left'} color={'secondary'} variant={'body1'} noWrap={true}>
          {t('component_reviews_no_reviews')}
        </Typography>
      </div>
    );
  };

  return <div className={classes.lastRentals}>{renderComments()}</div>;
};

GridReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default GridReviews;
