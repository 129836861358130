/* istanbul ignore file */
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Button from '@material-ui/core/Button';
import { selectedButtonStyle } from './buttonStyles';
import { useTranslation } from 'react-i18next';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import getUiConfig from '../firebaseUiConfig';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const LoginButton = ({ onLogin }) => {
  const { t } = useTranslation();
  const [buttonOver, setButtonOver] = useState(false);

  const handleLogin = ({ user }) => {
    onLogin({ user });
  };

  return (
    <Popup
      trigger={
        <Button
          onMouseEnter={() => setButtonOver(true)}
          onMouseLeave={() => setButtonOver(false)}
          style={selectedButtonStyle(buttonOver)}
        >
          {t('component_login')}
        </Button>
      }
      position="bottom right"
    >
      <div>
        <StyledFirebaseAuth uiConfig={getUiConfig(handleLogin)} firebaseAuth={firebase.auth()} />
      </div>
    </Popup>
  );
};

export default LoginButton;
