import GoogleMapReact from 'google-map-react';
import React from 'react';
import PropTypes from 'prop-types';

const Map = ({ lat, lng }) => {
  const Marker = () => (
    <span
      style={{
        display: 'block',
        position: 'absolute',
        left: -50,
        top: -50,
        width: 100,
        height: 100,
        opacity: '0.3',
        backgroundColor: 'darkGray',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
    />
  );

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLEMAP_API_KEY }}
        defaultCenter={{ lat, lng }}
        defaultZoom={10}
        options={{ maxZoom: 14 }}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export { Map };
