import React, { useState } from 'react';
import { createMuiTheme, makeStyles, MuiThemeProvider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { RENT_STATUS } from '../models/rentStatus';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAdServiceContext } from '../services/ServicesProvider';
import { CANCEL_RENTAL, COMPLETE, DISPUTE, PAY_WALL, RETURN } from '../routes';
import { withRouter } from 'react-router-dom';
import LoadingButton from './loadingButton';

const useStyles = makeStyles(() => ({
  button: {
    width: 95,
  },
  buttonSeparator: {
    width: 10,
    height: '100%',
  },
}));

const buttonsTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#8f5d57',
    },
    secondary: {
      main: '#57715b',
    },
  },
  typography: {
    fontFamily: ['Oswald'].join(','),
  },
});

const DashboardRentedButtons = ({ history, isOwner, item, displayStatusOnly }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isInProgress = (rent) => {
    // TODO consider hours
    return moment(rent.startDate.toDate()).isSameOrBefore(moment());
  };

  const [cancelling, setCancelling] = useState(false);
  const [declining, setDeclining] = useState(false);

  const conditionallyDisplayText = (text) => {
    if (displayStatusOnly) {
      return (
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {text}
        </Typography>
      );
    }
    return <></>;
  };

  const adService = useAdServiceContext();

  const approve = (rental) => {
    adService.approve(rental.rent);
  };

  const decline = async (rental) => {
    setDeclining(true);
    await adService.decline(rental.rent);
    setDeclining(false);
  };

  const goToComplete = ({ rent }) => {
    history.push(COMPLETE.replace(':rentId', rent.uid));
  };

  const goToReturn = ({ rent }) => {
    history.push(RETURN.replace(':rentId', rent.uid));
  };

  const cancel = async (rental) => {
    setCancelling(true);
    await adService.cancel(rental.rent);
    setCancelling(false);
  };

  const pay = (rental) => {
    history.push(PAY_WALL.replace(':rentId', rental.rent.uid));
  };

  const goToDispute = ({ rent }) => {
    history.push(DISPUTE.replace(':rentId', rent.uid));
  };

  const goToCancelApproved = ({ rent }) => {
    history.push(CANCEL_RENTAL.replace(':rentId', rent.uid));
  };

  const renderButtons = () => {
    const { rent } = item;
    if (rent.status === RENT_STATUS.PENDING_OWNER_REVIEW || rent.status === RENT_STATUS.CHARGE_ACCEPTED) {
      if (isOwner) {
        return (
          <Button
            data-testid={`click-complete-${rent.uid}`}
            variant="contained"
            color="primary"
            onClick={() => goToComplete(item)}
            className={classes.button}
          >
            {t('component_dashboard_complete_rental')}
          </Button>
        );
      } else {
        return conditionallyDisplayText(t('component_dashboard_pending_owner_review'));
      }
    } else if (rent.status === RENT_STATUS.PENDING_APPROVAL) {
      if (isOwner) {
        return (
          <MuiThemeProvider theme={buttonsTheme}>
            <Button
              data-testid={`click-approve-${rent.uid}`}
              variant="contained"
              color="secondary"
              onClick={() => approve(item)}
              className={classes.button}
            >
              {t('component_dashboard_approve')}
            </Button>
            <div className={classes.buttonSeparator} />
            <Button
              data-testid={`click-reject-${rent.uid}`}
              variant="contained"
              color="primary"
              onClick={() => decline(item)}
              className={classes.button}
            >
              <LoadingButton loading={declining} content={t('component_dashboard_decline')} />
            </Button>
          </MuiThemeProvider>
        );
      }
      return (
        <div className={classes.pendingOwnerApproval}>
          {displayStatusOnly && (
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('component_dashboard_pending_approval')}
            </Typography>
          )}
          <MuiThemeProvider theme={buttonsTheme}>
            <Button
              data-testid={`click-cancel-${rent.uid}`}
              variant="contained"
              color="primary"
              onClick={() => cancel(item)}
              className={classes.button}
            >
              <LoadingButton loading={cancelling} content={t('action_cancel')} />
            </Button>
          </MuiThemeProvider>
        </div>
      );
    } else if (rent.status === RENT_STATUS.PENDING_PAYMENT) {
      if (isOwner) {
        return (
          <div className={classes.pendingOwnerApproval}>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('component_dashboard_awaiting_payment')}
            </Typography>
            <MuiThemeProvider theme={buttonsTheme}>
              <Button
                data-testid={`click-reject-${rent.uid}`}
                variant="contained"
                color="primary"
                onClick={() => decline(item)}
                className={classes.button}
              >
                {t('confirm_rent_cancel')}
              </Button>
            </MuiThemeProvider>
          </div>
        );
      }
      return (
        <MuiThemeProvider theme={buttonsTheme}>
          <Button
            data-testid={`click-pay-${rent.uid}`}
            variant="contained"
            color="secondary"
            onClick={() => pay(item)}
            className={classes.button}
          >
            {t('component_dashboard_pay')}
          </Button>
          <div className={classes.buttonSeparator} />
          <Button
            data-testid={`click-cancel-${rent.uid}`}
            variant="contained"
            color="primary"
            onClick={() => cancel(item)}
            className={classes.button}
          >
            {t('action_cancel')}
          </Button>
        </MuiThemeProvider>
      );
    } else if (rent.status === RENT_STATUS.DISPUTE_OPENED) {
      return (
        <Button
          data-testid={`click-see-dispute-${rent.uid}`}
          variant="contained"
          color="primary"
          onClick={() => goToDispute(item)}
          className={classes.button}
        >
          {t('component_dashboard_see_dispute')}
        </Button>
      );
    } else if (rent.status === RENT_STATUS.APPROVED) {
      return (
        <Button
          data-testid={`click-cancel-${rent.uid}`}
          variant="contained"
          color="primary"
          onClick={() => goToCancelApproved(item)}
          className={classes.button}
        >
          {t('action_cancel')}
        </Button>
      );
    } else if (rent.status === RENT_STATUS.IN_PROGRESS) {
      if (!isInProgress(rent)) {
        return (
          <Button
            data-testid={`click-cancel-booking-${rent.uid}`}
            variant="contained"
            color="primary"
            onClick={() => goToCancelApproved(item)}
            className={classes.button}
          >
            {t('action_cancel')}
          </Button>
        );
      } else {
        if (isOwner === false) {
          return (
            <Button
              data-testid={`click-return-${rent.uid}`}
              variant="contained"
              color="primary"
              onClick={() => goToReturn(item)}
              className={classes.button}
            >
              {t('component_dashboard_return_item')}
            </Button>
          );
        } else {
          return conditionallyDisplayText(t('component_dashboard_in_progress'));
        }
      }
    } else if (rent.status === RENT_STATUS.COMPLETED) {
      return conditionallyDisplayText(t('component_dashboard_completed'));
    } else if (rent.status === RENT_STATUS.CANCELLED) {
      return conditionallyDisplayText(t('component_dashboard_cancelled'));
    }
  };

  return <>{renderButtons()}</>;
};

DashboardRentedButtons.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  displayStatusOnly: PropTypes.bool.isRequired,
};

DashboardRentedButtons.defaultProps = {
  displayStatusOnly: true,
};

export default withRouter(DashboardRentedButtons);
