import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
  helpBox: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.75)',
  },

  helpContent: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: 30,
  },
}));

const HelpPage = ({ enableLinks, goToPublish, goToDashboard, goToSearch, goToInbox }) => {
  const classes = useStyles();

  const locale = useSelector((state) => state.authentication.locale);

  const getLinkStyle = () => {
    if (enableLinks) {
      return {
        color: 'red',
        cursor: 'pointer',
      };
    } else {
      return {
        color: 'red',
      };
    }
  };

  const renderFrContent = () => {
    return (
      <>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          Qu'est-ce que Loue-tout-ca ? Et comment ça fonctionne ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Lout-tout-ca est une plate-forme collaborative permettant à des particuliers de promouvoir la location de
          divers items de façon sécuritaire par un processus d'approbation, de résolution de disputes ainsi que des
          paiements sécurisés offert par un tier parti reconnu dans l'industrie: stripe.com. C'est par cet intermédiaire
          que l'argent des locations seront effectuées directement dans votre compte bancaire. Si vous n'êtes pas à
          l'aise à y entrer vos informations, loue-tout-ca vous offre un mode 'crédit'. Ces crédits peuvent être ainsi
          utilisés pour vous même faire des locations par la suite. Par contre, ces crédits ne sont pas garantis
          contrairement aux dépôts bancaires par stripe. Vous pourriez donc rapporter gros en louant les objets
          inutilisés qui traînent. On retrouve aussi plusieurs autres items offerts en location commerciales par divers
          fournisseurs afin que vous trouviez tout ce que vous cherchez à un seul endroit.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          Comment puis-je afficher un item ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          La{' '}
          <Link data-testid={'publish'} onClick={goToPublish} style={getLinkStyle()}>
            publication
          </Link>{' '}
          est offerte gratuitement à tous les utilisateurs enregistrés ayant un compte bancaire validé. Une fois cette
          étape faite, vous n'aurez qu'à choisir vos tarifs, fournir des photos de l'item et choisir un montant
          suffisant comme dépôt de sécurité. Ce montant sert à couvrir les frais de retard que vous pourriez devoir
          imposer, les frais de réparations de l'item en cas de bris, les frais en cas de vol de l'item ou tout autre
          frais afférant à la location. Ce montant sera automatiquement facturé au locataire lors de la réservation de
          l'item. Une fois l'annonce complétée est sera automatiquement publiée et visible par les autres utilisateurs
          de la plate-forme.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          Comment fonctionne la location ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          Si vous affichez un ou des items
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Lorsqu'un utilisateur désire louer un de vos item, vous recevrez une notification SMS ou email si vous les
          avez activées et l'item en question s'affichera aussi dans votre{' '}
          <Link data-testid={'dashboard-1'} onClick={goToDashboard} style={getLinkStyle()}>
            tableau de bord
          </Link>{' '}
          . Vous aurez toutes les informations sur le locataire ainsi que le montant qui vous reviendra une fois la
          réservation terminée. Si vous acceptez la réservation, le locataire devra ensuite procéder au paiement pour
          confirmer la location. Vous pouvez annuler la réservation à tout moment avant la date de début de celle-ci.
          Lorsque le locataire ramène l'item la location doit être complétée par le locataire et le vous. Le locataire
          fournira une appréciation de son expérience de location et vous donnera une note qui sera affichée dans les
          détails de votre annonce. Elle comptera aussi pour une note global. Cette étape est facultative mais
          recommandée. De votre côté, la complétion de la réservation est obligatoire et c'est après cette étape
          seulement que votre paiement vous sera acheminé dans un délai de 5 à 7 jours ouvrables.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          Si vous désirez louer un item
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Une fois que vous aurez trouvé{' '}
          <Link data-testid={'search'} onClick={goToSearch} style={getLinkStyle()}>
            l'item
          </Link>{' '}
          que vous chercher et choisi vos date de locations vous devrez en faire la demande auprès du propriétaire. Si
          celui-ci l'approuve, vous recevez une notification SMS ou email si vous les avez activées et l'item en
          question s'affichera aussi dans votre{' '}
          <Link data-testid={'dashboard-2'} onClick={goToDashboard} style={getLinkStyle()}>
            tableau de bord
          </Link>{' '}
          . La prochaine étape est le paiement de la location. Vous devez payer le montant de la location et le dépôt de
          sécurité que le propriétaire a établi, en plus des taxes et frais de services. Le montant du dépôt de sécurité
          vous sera remboursé en totalité si l'item revient à temps et intact. En cas de bris, le propriétaire aura
          l'option de conserver une partie ou la totalité de celui-ci mais vous aurez possiblité de négocier lors de ce
          processus, voir la section si dessous. Lorsque votre location se termine vous devez retourner l'item au
          propriétaire et compléter la réservation, processus facultatif par lequel vous pourrez donner votre
          appréciation de l'expérience de location. Le propriétaire fera la même chose et par la suite le rembouresement
          du dépôt de sécurité sera effecté dans un délai de 5 à 7 jours ouvrables. Vous pouvez annuler la réservation
          jusqu'à 7 jours avant la date de début de celle-ci sans frais. Si vous annulez à l'intérieur des 7 jours avant
          le début, les frais de services seront conservés.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          Qu'arrive-t-il si mon item est endommagé ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Lorsque vous complétez le retour de la location vous pourrez choisir de garder une partie ou la totalité de ce
          montant en fournissant une explication au locataire. Ceci ouvrira un processus de dispute qui durera un
          maximum de 7 jours et pendant lequel le locataire pourra négocier avec vous sur le montant et/ou les
          explications. Ces discussions seront aussi visible dans la{' '}
          <Link data-testid={'inbox'} onClick={goToInbox} style={getLinkStyle()}>
            messagerie
          </Link>{' '}
          . Si le locataire accepte les frais, vous devrez compléter la réservation en évaluant cette fois-ci
          l'expérience avec le locataire. Si vous et le locataire n'en venez pas à une entente au bout du 7 jours, le
          système passera automatiquement l'item à compléter avec les frais de surchage demandés.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          Quel sont les frais de Lout-tout-ca ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          Si vous affichez un ou des items
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Il n'y a aucun frais de service de platforme pour afficher et louer un item à un particulier. Donc si par
          exemple vous louer un item pour 50$/jour pendant 3 jours, votre paiment sera de 150$. Il n'y a aucun frais
          d'annulation pour les propriétaires peu importe le temps restant avant la location.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          Si vous désirez louer un item
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Les frais de service de la platforme sont de 10%, minimum 2$. Donc si par exemple vous désirez louer un item
          pour 20$/jour pendant 3 jours, les frais de services seront de 6$. Il n'y a aucun frais d'annulation s'il
          reste plus de 7 jours avant la date de début de location. Si ce délai est dépassé, les frais de services de 2%
          seront conservés pour dédommager le propriétaire.
        </Typography>
      </>
    );
  };

  const renderEnContent = () => {
    return (
      <>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          What is Loue-tout-ca ? And how is it working ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Lout-tout-ca is a collaborative platform enabling users to promote and rent securely different items. With an
          approval process, dispute resolution workflows and secure payments through an industry known provider:
          stripe.com. It is through them that you will receive payments directly to your bank account. If you are
          unconfortable with entering these informations, loue-tout-ca also offers a 'credit' mode. These credits can
          later be used to rent items for yourself. However, these credits are not guaranteed as they are by stripe. So
          you could own big by renting unused items from your house. We also have several items by various renting
          companies to help you find you are looking for in a single place.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          How can I publish an item to rent ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          <Link data-testid={'publish'} onClick={goToPublish} style={getLinkStyle()}>
            Posting an ad
          </Link>{' '}
          is totally free for all registered users having a linked bank account. Once this is done, all you have to do
          is to set your price, add some photos and set the deposit amount. This amount is used to cover costs for late
          returns, repairs, theft or any other fees you may encounter. It will be automatically charged to the renter
          during the booking process. Once all of this is done, the ad will be published and visible to all other
          platform users.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          How does renting works ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          If you are an owner
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          When someone wants to rent one of your items, you will receive an SMS or email notification if you have
          enabled them in your profile. The booking will also appear in your{' '}
          <Link data-testid={'dashboard-1'} onClick={goToDashboard} style={getLinkStyle()}>
            dashboard
          </Link>{' '}
          . All informations about the renter will be shown along with the payment that you will receive once the rental
          is complete. If you accept the booking, the renter will then have to proceed with the full payment to confirm
          it. You have the option to cancel the booking at anytime if the booking isn't started yet. When the renter
          brings back the item you will need to complete the booking and the same goes for the renter which will write a
          review about its experience. This review will show in the details of your ad and will also impact your global
          rating. This step is not mandatory for renters but highly recommended. From your side, the complete rental
          step is mandatory and you will only receive payment 5 to 7 business days after it is done.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          If you are renting
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          Once you have found the{' '}
          <Link data-testid={'search'} onClick={goToSearch} style={getLinkStyle()}>
            item
          </Link>{' '}
          you were looking for and chose your dates you need to send a booking request to the owner. If he approves, you
          will receive an SMS or email notification if you have enabled them in your profile. The booking will also
          appear in your{' '}
          <Link data-testid={'dashboard-2'} onClick={goToDashboard} style={getLinkStyle()}>
            dashboard
          </Link>
          . The next step is the payment. You must pay the rental amount, the security deposit that the owner has set,
          the taxes and the service fees. The security deposit will be refunded in total or partially. In the event that
          the owner opens a dispute and sets an overcharge, you will have the possibility to negociate, see section
          below. Once your rental is complete you must return the item to the owner and complete the booking to write a
          review about your experience. This is optional but recommended. The owner will do the same and once it is done
          the security deposit will be refunded within 5 to 7 business days. You can cancel your booking up to 7 days
          before it starts without any fees. If you cancel after this period, the services fees will not be reimbursed.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          What happens if the item is damaged ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          When you complete the booking you will be able to keep a part or the totality of the security deposit to cover
          extra costs. You must provide an explanation to the renter. This will open a dispute process that will stay
          open for a maximum of 7 days. During this period, the renter can negociate on the amount and/or accept the
          charge. These discussions will be also available in your{' '}
          <Link data-testid={'inbox'} onClick={goToInbox} style={getLinkStyle()}>
            inbox
          </Link>{' '}
          . If the renter agrees with the charge, you will need to resume the 'complete booking' workflow and evaluate
          your experience with the renter. If the owner and you cannot agree on the charge after 7 days, the system will
          automatically bill the charges you initially set.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h4'} paragraph={true}>
          What are the fees ?
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          If you are an owner
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          There are no fees for posting and renting items to people. So if you were to rent an item for 50$/day for 3
          days, your payment would be 150$. There are no cancellation fees for the owner no matter how much time is left
          before the booking starts.
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h5'} paragraph={true}>
          If you are renting
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'h6'} paragraph={true}>
          The service fees are 10%, minimum 2$. So if you were to book an item for 20$/day for 3 days, the service fees
          would be 6$. There are no cancellation fees if there is more than 7 days left before the booking starts. If
          not, the 10% service fees will be kept to compensate the owner.
        </Typography>
      </>
    );
  };

  const renderHelpContent = () => {
    if (locale === 'french') {
      return renderFrContent();
    }
    return renderEnContent();
  };

  return (
    <>
      <div className={classes.helpBox}>
        <div className={classes.helpContent}>{renderHelpContent()}</div>
      </div>
    </>
  );
};

HelpPage.propTypes = {
  enableLinks: PropTypes.bool,
  goToPublish: PropTypes.func,
  goToDashboard: PropTypes.func,
  goToSearch: PropTypes.func,
  goToInbox: PropTypes.func,
};

export default HelpPage;
