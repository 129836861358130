import { createModel } from '@rematch/core';
import units from '../assets/units/units.json';

export const createUnitsModel = () =>
  createModel({
    state: {
      units,
    },

    reducers: {},

    effects: (dispatch) => ({}),
  });
