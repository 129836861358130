import React from 'react';
import { makeStyles } from '@material-ui/core';
import RentingImage from './rentingImage';
import AdShape from '../shapes/adShape';
import RentShape from '../shapes/rentShape';
import UserShape from '../shapes/userShape';
import { useSelector } from 'react-redux';
import notavailable from '../assets/noimageavailable.png';
import PropTypes from 'prop-types';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';
import UserAddressBlock from './userAddressBlock';
import { PROFILE_VIEW, BOOKING_VIEW } from '../routes';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
  itemDescription: {
    display: 'flex',
    flexDirection: 'column',
  },
  renterItemInformation: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
  },
  commentBox: {
    display: 'block',
    width: '100%',
    height: 125,
    fontFamily: 'Oswald',
    color: '#1c1e6e',
    fontSize: 12,
    textAlign: 'left',
    marginRight: 10,
  },
  infoBlock: {
    paddingTop: 30,
  },
}));

const ItemDescriptionWithUserAddressBlock = ({ history, isOwner, rent, user, owner, ad, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const goToUserPage = () => {
    let userId;
    if (isOwner) {
      userId = rent.owner;
    } else {
      userId = rent.user;
    }
    history.push(PROFILE_VIEW.replace(':userId', userId));
  };

  const goToRentDetails = () => {
    history.push(BOOKING_VIEW.replace(':rentId', rent.uid));
  };

  return (
    <div className={classes.itemDescription}>
      <RentingImage
        title={ad.title}
        location={locationFor(locations, ad.location).name[languageToLocale(locale)]}
        image={ad.images && ad.images[0] ? ad.images[0] : notavailable}
        price={rent.price}
        showUnit={false}
        unit={ad.unit}
        mainImageWidth={ad.mainImageWidth}
        mainImageHeight={ad.mainImageHeight}
        noBorder
        testid={`edit-ad-${ad.uid}`}
        onAdClick={onClick}
      />
      <Link data-testid={'bookingNumber-link'} onClick={goToRentDetails} style={{ cursor: 'pointer' }}>
        <Typography color={'textSecondary'} align={'left'} variant={'body2'}>
          {t('dashboard_bookingNumber', { bookingNumber: rent.bookingNumber })}
        </Typography>
      </Link>
      {(user || owner) && (
        <UserAddressBlock
          isOwner={isOwner}
          rent={rent}
          onUserClick={goToUserPage}
          user={/* istanbul ignore next */ isOwner ? owner : user}
        />
      )}
    </div>
  );
};

ItemDescriptionWithUserAddressBlock.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  ad: AdShape.isRequired,
  rent: RentShape.isRequired,
  owner: UserShape,
  user: UserShape,
  onClick: PropTypes.func,
  history: PropTypes.object.isRequired,
};

export default withRouter(ItemDescriptionWithUserAddressBlock);
