/* istanbul ignore file */
import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import getUiConfig from '../../firebaseUiConfig';
import PropTypes from 'prop-types';
import { DASHBOARD, LOGIN, RENT, WELCOME } from '../../routes';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import toolbox from '../../assets/tools-transparent.png';
import { makeStyles } from '@material-ui/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const useStyles = makeStyles(() => ({
  imageLogo: {
    width: '100%',
    height: '100%',
  },
  floatingPopupWindow: {
    position: 'absolute',
    left: 0,
    top: '15%',
    width: '100%',
  },
}));

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const LoginScreen = ({ location, history }) => {
  const classes = useStyles();

  const { onLogin } = useRematchDispatch((dispatch) => ({
    onLogin: dispatch.authentication.onLogin,
  }));

  const { from } = (location && location.state) || { from: { pathname: WELCOME } };

  const handleLogin = async ({ user }) => {
    await onLogin({ user });
    if (from) {
      history.push({
        pathname: from,
        state: location.state,
      });
    } else {
      history.push(DASHBOARD);
    }
  };

  return (
    <div>
      <img src={toolbox} className={classes.imageLogo} alt={''} />
      <div className={classes.floatingPopupWindow}>
        <StyledFirebaseAuth uiConfig={getUiConfig(handleLogin)} firebaseAuth={firebase.auth()} />
      </div>
    </div>
  );
};

LoginScreen.propTypes = {
  location: PropTypes.object.isRequired,
};

export { LoginScreen };
