import Bugsnag from '@bugsnag/js';

const languageToLocale = (language) => {
  if (language === 'english') {
    return 'en';
  } else if (language === 'french') {
    return 'fr';
  }
  Bugsnag.notify(new Error(`Unexpected language ${language}`));
  console.error('Unexpected language', language);
  return 'en';
};

export { languageToLocale };
