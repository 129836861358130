import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const LoadingComponent = ({ isLoading, children }) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading && (
        <div>
          <Typography color={'primary'} align={'left'} variant={'h6'}>
            {t('loading')}
          </Typography>
        </div>
      )}
      {!isLoading && children}
    </>
  );
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default LoadingComponent;
