import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import { makeStyles } from '@material-ui/core';
import SearchBox from '../../components/searchBox';
import NavigationButtons from '../../components/navigationButton';
import ResultsHeader from './resultsHeader';
import PropTypes from 'prop-types';
import { useSearchServiceContext } from '../../services/ServicesProvider';
import { useSelector } from 'react-redux';
import SortBySelector from '../../components/sortBy';
import { useTranslation } from 'react-i18next';
import SearchResultsList from '../../components/searchResultsList';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ResultsPerPageSelector from '../../components/resultsPerPageSelector';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import {
  buildSearchParams,
  getDefaultCategory,
  getDefaultFrom,
  getDefaultIncludeCommercial,
  getDefaultLocation,
  getDefaultQuery,
  getDefaultResultsPerPage,
  getDefaultSearchRadius,
  getDefaultSortOrder,
  getDefaultTo,
} from '../../utils/searchTools';
import LocationAwarePage from '../../components/locationAwarePage';
import Loading from '../../components/loading';
import { SEARCH_RESULTS } from '../../routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 25,
    paddingRight: 25,
  },
  topRightContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  resultsLength: {
    width: '100%',
  },
  sortByContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
}));

export const DEFAULT_RESULTS_PER_PAGE = 25;

const SearchResults = ({ location }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const categories = useSelector((state) => state.categories.categories);

  const searchService = useSearchServiceContext();
  const locations = useSelector((state) => state.locations.locations);

  const [results, setResults] = useState([]);
  const [resultsInPage, setResultsInPage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState(getDefaultSortOrder(location));
  const user = useSelector((state) => state.user.user);
  const [searchPanelStyle, setSearchPanelStyle] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(getDefaultResultsPerPage(location));

  const [locationDetected, setLocationDetected] = useState(false);
  const [formData, setFormData] = useState();

  const history = useHistory();

  useEffect(() => {
    if (results) {
      const temp = [...results];
      setResultsInPage(temp.splice(currentIndex * resultsPerPage, resultsPerPage));
    }
  }, [results, currentIndex, resultsPerPage]);

  const isOutdated = useCallback(() => {
    return getDefaultSortOrder(location) !== sortOrder || getDefaultResultsPerPage(location) !== resultsPerPage;
  }, [location, sortOrder, resultsInPage]);

  useEffect(() => {
    if (isOutdated()) {
      const newSortOrder = getDefaultSortOrder(location);
      setSortOrder(newSortOrder);
      setResultsPerPage(getDefaultResultsPerPage(location));
    }
  }, [isOutdated]);

  const onSearch = async (terms) => {
    setSearching(true);
    const { what, category, location, searchRadius, priceRange, includeCommercial, sortOrder } = terms;
    setResults(
      await searchService.search(
        what,
        category,
        location,
        sortOrder,
        searchRadius,
        priceRange,
        /* istanbul ignore next */ user ? user.uid : undefined,
        includeCommercial,
      ),
    );
    setSearching(false);
    setCurrentIndex(0);
    setFormData(terms);
  };

  const hasMoreResults = () => {
    return results && results.length > (currentIndex + 1) * resultsPerPage;
  };

  const onSetSortOrder = (newSortOrder) => {
    setSortOrder(newSortOrder);
    history.push({
      pathname: SEARCH_RESULTS,
      search: buildSearchParams({ ...formData, sortOrder: newSortOrder }),
    });
  };

  const onSetResultsPerPage = (newResultsPerPage) => {
    setResultsPerPage(newResultsPerPage);
    history.push({
      pathname: SEARCH_RESULTS,
      search: buildSearchParams({ ...formData, resultsPerPage: newResultsPerPage }),
    });
  };

  const isnOnFirstPage = () => currentIndex === 0;

  /* istanbul ignore next */
  const goToPreviousPage = () => {
    /* istanbul ignore next */
    setCurrentIndex(currentIndex - 1);
  };

  /* istanbul ignore next */
  const goToNextPage = () => {
    /* istanbul ignore next */
    setCurrentIndex(currentIndex + 1);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    /* istanbul ignore next */
    if (windowSize.width < 460) {
      /* istanbul ignore next */
      setSearchPanelStyle({
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      });
    } else {
      setSearchPanelStyle({
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
      });
    }
  }, [windowSize]);

  const renderSearchResults = () => {
    if (searching) {
      return (
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('loading')}
        </Typography>
      );
    }
    return (
      <>
        <SearchResultsList resultsInPage={resultsInPage} />
        <NavigationButtons
          showPrevious={!isnOnFirstPage()}
          showNext={hasMoreResults()}
          onPreviousPress={goToPreviousPage}
          onNextPress={goToNextPage}
        />
      </>
    );
  };

  const onLocationReady = () => {
    setLocationDetected(true);
  };

  const renderContent = () => {
    return (
      <ProfileCheck>
        <div className={classes.page}>
          <div className={classes.header}>
            <Header />
          </div>

          <Container maxWidth={'xl'}>
            <div className={classes.topRightContainer}>
              <div className={classes.resultsLength}>
                {searching === false && (
                  <ResultsHeader count={results ? results.length : 0} resultsPerPage={resultsPerPage} />
                )}
              </div>
              <div className={classes.sortByContainer}>
                <SortBySelector sortBy={sortOrder} onSelectSortOrder={onSetSortOrder} />
                <ResultsPerPageSelector resultsPerPage={resultsPerPage} onSelectResultsPerPage={onSetResultsPerPage} />
              </div>
            </div>

            <div style={searchPanelStyle}>
              <SearchBox
                title={t('searchresults_search_for')}
                onSearch={(terms) => onSearch(terms)}
                inputWhat={getDefaultQuery(location)}
                inputCategory={getDefaultCategory(categories, location)}
                inputLocation={getDefaultLocation(locations, location)}
                inputIncludeCommercial={getDefaultIncludeCommercial(location)}
                inputFrom={getDefaultFrom(location)}
                inputTo={getDefaultTo(location)}
                inputSearchRadius={getDefaultSearchRadius(location)}
                inputSortOrder={sortOrder}
                showAdvanced={true}
              />
              <div className={classes.results}>{renderSearchResults()}</div>
            </div>
          </Container>
        </div>
      </ProfileCheck>
    );
  };

  return (
    <LocationAwarePage onReady={onLocationReady}>
      <Loading isLoading={locationDetected === false}>{renderContent()}</Loading>
    </LocationAwarePage>
  );
};

SearchResults.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export { SearchResults };
