/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { ResizableBox } from './resizableBox';
import ForRentImage from './forRentImage';
import { hasImages } from '../utils/imagesHelper';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

const ResizableImageBox = ({ ad, center, supportSmallMode }) => {
  const [imageBoxSize, setImageBoxSize] = useState(0);
  const [imageReady, setImageReady] = useState(false);
  const theme = useTheme();
  let isSmall;
  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isSmall = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true,
    });
  }

  const onImageReady = (imageAvailable) => {
    /* istanbul ignore next */
    setImageReady(imageAvailable);
  };

  if (supportSmallMode) {
    return (
      <>
        {/* istanbul ignore next */ isSmall ? (
          <ResizableBox onResize={setImageBoxSize} hasChildren={hasImages(ad)} imageIsAvailable={imageReady}>
            <ForRentImage imageWidth={imageBoxSize} ad={ad} showArrows={false} onReady={onImageReady} />
          </ResizableBox>
        ) : (
          <ForRentImage imageWidth={imageBoxSize} ad={ad} showArrows={false} onReady={onImageReady} />
        )}
      </>
    );
  }

  return (
    <ResizableBox onResize={setImageBoxSize} center={center} hasChildren={hasImages(ad)} imageIsAvailable={imageReady}>
      <ForRentImage imageWidth={imageBoxSize} ad={ad} onReady={onImageReady} />
    </ResizableBox>
  );
};

ResizableImageBox.propTypes = {
  ad: PropTypes.object.isRequired,
  center: PropTypes.bool,
  supportSmallMode: PropTypes.bool,
};

ResizableImageBox.defaultProps = {
  center: true,
  supportSmallMode: false,
};

export default ResizableImageBox;
