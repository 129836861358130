/* istanbul ignore file */
import PropTypes from 'prop-types';
import { values } from '../models/rentStatus';

export default PropTypes.shape({
  ad: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  owner: PropTypes.string.isRequired,
  status: PropTypes.oneOf(values()),
  user: PropTypes.string.isRequired,
});
