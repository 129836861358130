import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoadingButton from '../loadingButton';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    paddingTop: 20,
    width: 150,
  },
  button: {
    width: '100%',
    height: '100%',
  },
}));

const LinkAccountButton = ({ onClick, payoutMode }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [linkingAccount, setLinkingAccount] = useState(false);

  const handleLinkAccount = () => {
    setLinkingAccount(true);
    onClick();
  };

  const renderLinkAccountButton = () => {
    if (payoutMode === undefined || payoutMode === 'link') {
      return (
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            data-testid={'linkAccount'}
            variant="contained"
            color="primary"
            onClick={handleLinkAccount}
          >
            <LoadingButton loading={linkingAccount} content={t('link_account')} />
          </Button>
        </div>
      );
    }
    return <></>;
  };

  return renderLinkAccountButton();
};

LinkAccountButton.propTypes = {
  payoutMode: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default LinkAccountButton;
