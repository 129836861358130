import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  boxInside: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  logo: {
    width: 275,
    aspectRatio: 1,
    borderRadius: '150px',
    overflow: 'hidden',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#395f4b',
  },
  image: {
    width: 340,
    height: 275,
    cursor: 'pointer',
  },
}));

const RoundedImageWithHeader = ({ image, header, onClick, testid }) => {
  const classes = useStyles();
  return (
    <div data-testid={testid} className={classes.box} onClick={onClick}>
      <div className={classes.boxInside}>
        <div className={classes.text}>
          <Typography color={'primary'} align={'left'} variant={'h5'}>
            {header}
          </Typography>
          <div className={classes.logo}>
            <img src={image} className={classes.image} alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};

RoundedImageWithHeader.propTypes = {
  header: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

RoundedImageWithHeader.defaultProps = {
  testid: 'image-header',
};

export default RoundedImageWithHeader;
