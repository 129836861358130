import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useRematchDispatch from '../hooks/useRematchDispatch';
import LoginButton from './loginButton';
import { useSelector } from 'react-redux';
import LogoutButton from './logoutButton';
import { DASHBOARD, INBOX, POST_AD, SEARCH_RESULTS, WELCOME, HELP } from '../routes';
import { HeaderToggleButton } from './headerToggleButton';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import { useTheme } from '@material-ui/styles';
import { DashboardHeaderToggleButton } from './dashboardToggleButton';
import { InboxHeaderToggleButton } from './inboxToggleButton';
import { HeaderMenuButton } from './headerMenuButton';

const useStyles = makeStyles(() => ({
  topHeaderBar: {
    width: '100%',
    backgroundColor: '#505050',
    display: 'flex',
  },

  headerRightPart: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  navigationElements: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-start',
    paddingLeft: 15,
  },

  avatar: {
    height: 34,
    width: 34,
    borderRadius: '50%',
  },
}));

const Header = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const locale = useSelector((state) => state.authentication.locale);

  const { onLogin, logout, toggleLocale } = useRematchDispatch((dispatch) => ({
    onLogin: dispatch.authentication.onLogin,
    toggleLocale: dispatch.authentication.toggleLocale,
    logout: dispatch.user.logout,
  }));

  const performLogout = async () => {
    await logout();
    history.push(WELCOME);
  };

  const renderAuthenticationButton = () => {
    if (user) {
      return <LogoutButton onClick={performLogout} />;
    }
    return <LoginButton history={history} onLogin={onLogin} />;
  };

  const goToHome = () => {
    history.push(WELCOME);
  };

  const goToDashboard = () => {
    history.push(DASHBOARD);
  };

  const goToPostAd = () => {
    history.push(POST_AD);
  };

  const goToSearch = () => {
    history.push(SEARCH_RESULTS);
  };

  const goToInbox = () => {
    history.push(INBOX);
  };

  const goToHelp = () => {
    history.push(HELP);
  };

  const getLocaleButtonTitle = () => {
    if (locale === 'english') {
      return 'Fr';
    }
    return 'En';
  };

  const toggleLanguage = () => {
    if (locale === 'french') {
      toggleLocale({ from: 'header', value: 'english' });
    } else {
      toggleLocale({ from: 'header', value: 'french' });
    }
  };

  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  const getHeaderStyle = () => {
    return {
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 20,
      display: 'flex',
    };
  };

  const renderMenuContent = () => {
    /* istanbul ignore next */
    if (isMobile) {
      /* istanbul ignore next */
      return (
        <HeaderMenuButton
          onBrowseClick={goToSearch}
          onHomeClick={goToHome}
          onPostAdClick={goToPostAd}
          onDashboardClick={goToDashboard}
          onInboxClick={goToInbox}
        />
      );
    }
    return (
      <>
        <HeaderToggleButton onClick={goToHome} title={t('header_home')} />
        <DashboardHeaderToggleButton onClick={goToDashboard} title={t('header_dashboard')} />
        <HeaderToggleButton onClick={goToSearch} title={t('header_browse')} />
        <HeaderToggleButton onClick={goToPostAd} title={t('header_post_ad')} />
        <InboxHeaderToggleButton onClick={goToInbox} title={t('header_inbox')} />
      </>
    );
  };

  return (
    <div className={classes.topHeaderBar}>
      <div style={getHeaderStyle()}>{user && <Avatar className={classes.avatar} src={user.photoURL} />}</div>
      <div className={classes.navigationElements}>{renderMenuContent()}</div>
      <div className={classes.headerRightPart}>
        <HeaderToggleButton onClick={goToHelp} title={t('header_help')} />
        <HeaderToggleButton onClick={toggleLanguage} title={getLocaleButtonTitle()} />
        {renderAuthenticationButton()}
      </div>
    </div>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);
