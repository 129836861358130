import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Container } from '@material-ui/core';
import RentingGridItem from './rentingGridItem';
import Offering from './offering';
import { SEARCH_RESULTS } from '../../routes';
import { useHistory, withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginBottom: 40,
    backgroundColor: '#f2f2f2',
  },
  headerText: {
    paddingLeft: 8,
    paddingBottom: 24,
  },
  splitter: {
    marginTop: 8,
    backgroundColor: '#505050',
    height: 32,
    width: '100%',
    borderBottomWidth: '4px',
    borderTopWidth: '4px',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: '#395f4b',
    borderStyle: 'solid',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const RentingOffer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const goToOffering = (category) => {
    history.push({
      pathname: SEARCH_RESULTS,
      search: `?category=${encodeURI(category)}`,
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.splitter} />
      <Container maxWidth={'xl'}>
        <div className={classes.headerText}>
          <Typography color={'primary'} align={'left'} variant={'h4'}>
            {t('home_rent_with')}
          </Typography>
        </div>
        <div className={classes.grid}>
          <Grid container direction={'row'} justify="center" alignItems="center">
            <RentingGridItem text={t('home_verified_users')} description={t('home_verified_users_detail')}>
              <VerifiedUserIcon color={'secondary'} style={{ fontSize: 100 }} />
            </RentingGridItem>
            <RentingGridItem text={t('home_secured_transactions')} description={t('home_secured_transactions_detail')}>
              <MonetizationOnIcon color={'secondary'} style={{ fontSize: 100 }} />
            </RentingGridItem>
            <RentingGridItem text={t('home_reviews')} description={t('home_reviews_details')}>
              <ThumbUpIcon color={'secondary'} style={{ fontSize: 100 }} />
            </RentingGridItem>
          </Grid>
        </div>
        <Offering onClick={goToOffering} />
      </Container>
    </div>
  );
};

export default withRouter(RentingOffer);
