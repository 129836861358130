/* istanbul ignore file */
import PropTypes from 'prop-types';
import MessageType from '../models/messageTypes';

export default PropTypes.shape({
  uid: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  date: PropTypes.object.isRequired,
  lastMessage: PropTypes.string.isRequired,
  type: PropTypes.oneOf([MessageType.DISPUTE, MessageType.REQUEST]).isRequired,
});
