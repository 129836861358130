import { createModel } from '@rematch/core';
import ab from '../assets/locations/locationdata-AB.json';
import bc from '../assets/locations/locationdata-BC.json';
import mb from '../assets/locations/locationdata-MB.json';
import nl from '../assets/locations/locationdata-NL.json';
import ns from '../assets/locations/locationdata-NS.json';
import nt from '../assets/locations/locationdata-NT.json';
import nu from '../assets/locations/locationdata-NU.json';
import on from '../assets/locations/locationdata-ON.json';
import pe from '../assets/locations/locationdata-PE.json';
import qc from '../assets/locations/locationdata-QC.json';
import sk from '../assets/locations/locationdata-SK.json';
import yt from '../assets/locations/locationdata-YT.json';

export const createLocationsModel = () =>
  createModel({
    state: {
      locations: undefined,
      provinces: [ab, bc, mb, nl, ns, nt, nu, on, pe, qc, sk, yt],
    },

    reducers: {
      updateLocations(state, locations) {
        return { ...state, locations };
      },
    },

    effects: (dispatch) => ({
      async loadLocations() {
        const locations = [];
        locations.push(...ab.cities);
        locations.push(...bc.cities);
        locations.push(...mb.cities);
        locations.push(...nl.cities);
        locations.push(...ns.cities);
        locations.push(...nt.cities);
        locations.push(...nu.cities);
        locations.push(...on.cities);
        locations.push(...pe.cities);
        locations.push(...qc.cities);
        locations.push(...sk.cities);
        locations.push(...yt.cities);

        dispatch.locations.updateLocations(locations);
      },
    }),
  });
