import { createModel } from '@rematch/core';
import COLLECTIONS from '../collections';
import { firebaseService } from '../services/firebaseServiceInstance';

export const createAuthenticationModel = () =>
  createModel({
    state: {
      user: undefined,
      locale: 'french',
    },

    reducers: {
      updateUser(state, user) {
        return { ...state, user };
      },
      updateLocale(state, locale) {
        return { ...state, locale };
      },
      reset(state) {
        return { ...state, user: undefined };
      },
    },

    effects: (dispatch) => ({
      async onLogin(payload) {
        const user = toUser(payload.user.user);
        const userDocument = await firebaseService.getDocumentById(COLLECTIONS.users, payload.user.user.uid);
        if (!userDocument.exists()) {
          await firebaseService.writeToCollection(COLLECTIONS.users, payload.user.user.uid, user);
        }
        dispatch.authentication.updateUser(user);
        const promises = [];
        promises.push(dispatch.user.loadUser({ uid: user.uid }));
        await Promise.all(promises);
      },

      async toggleLocale({ from, value }) {
        dispatch.authentication.updateLocale(value);

        if (from === 'header') {
          dispatch.user.changeLocale(value);
        }
      },
    }),
  });

const toUser = (user) => {
  const { displayName, photoURL, email, phoneNumber, uid } = user;

  // TODO user from locale
  return {
    displayName,
    photoURL,
    email,
    phoneNumber,
    uid,
    searchRadius: 25,
    language: 'french',
    notifications: {
      enabled: true,
      sms: { newRentals: true, pendingPayment: true, completed: true, newMessages: true },
      email: { newRentals: true, pendingPayment: true, completed: true, newMessages: true },
    },
  };
};
