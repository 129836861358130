import COLLECTIONS from '../collections';
import MessageType from '../models/messageTypes';

class MessageService {
  constructor(firebaseService) {
    this.firebaseService = firebaseService;
  }

  async createDispute(payload) {
    const { user, from, fromName, avatar, explanation, adId } = payload;
    const threadDocumentId = await this.firebaseService.createDocument(COLLECTIONS.threads, {
      user,
      from,
      fromName,
      avatar,
      date: new Date(),
      lastMessage: explanation,
      type: MessageType.DISPUTE,
      adId,
      unreadByOwner: false,
      unreadByUser: true,
    });

    this.createNewMessageInThread({ threadId: threadDocumentId, user, message: explanation, isOwner: true });
    return threadDocumentId;
  }

  async askQuestion(payload) {
    const { user, from, fromName, avatar, question, adId } = payload;
    const threadDocumentId = await this.firebaseService.createDocument(COLLECTIONS.threads, {
      user,
      from,
      fromName,
      avatar,
      date: new Date(),
      lastMessage: question,
      type: MessageType.REQUEST,
      adId,
      unreadByOwner: true,
      unreadByUser: false,
    });

    this.createNewMessageInThread({ threadId: threadDocumentId, user: from, message: question, isOwner: false });
    return threadDocumentId;
  }

  async createNewMessageInThread(payload) {
    const { threadId, user, message, isOwner } = payload;
    this.firebaseService.createDocument(COLLECTIONS.messages, {
      threadId,
      user,
      message,
      isOwner: isOwner ? isOwner : false,
      date: new Date(),
    });

    if (isOwner) {
      this.firebaseService.writeToCollection(COLLECTIONS.threads, threadId, {
        lastMessage: message,
        unreadByUser: true,
      });
    } else {
      this.firebaseService.writeToCollection(COLLECTIONS.threads, threadId, {
        lastMessage: message,
        unreadByOwner: true,
      });
    }
  }

  async markAsReadByOwner(payload) {
    const { threadId } = payload;
    this.firebaseService.writeToCollection(COLLECTIONS.threads, threadId, {
      unreadByOwner: false,
    });
  }

  async markAsReadByUser(payload) {
    const { threadId } = payload;
    this.firebaseService.writeToCollection(COLLECTIONS.threads, threadId, {
      unreadByUser: false,
    });
  }
}

export default MessageService;
