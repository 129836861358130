import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AutoCompleteInputTextField from './AutoCompleteInputTextField';
import { citiesFor } from '../utils/location';

const LocationSelector = ({ location, onSelectLocation }) => {
  const locations = useSelector((state) => state.locations.locations);
  const provinces = useSelector((state) => state.locations.provinces);
  const locale = useSelector((state) => state.authentication.locale);
  const currentUser = useSelector((state) => state.user.user);

  const getOptions = () => {
    if (currentUser && currentUser.state) {
      return citiesFor(provinces, currentUser.state);
    }
    return locations;
  };

  return (
    <AutoCompleteInputTextField
      fullWidth
      id={'location'}
      initialValue={location}
      options={getOptions()}
      onChange={onSelectLocation}
      locale={locale}
    />
  );
};

LocationSelector.propTypes = {
  location: PropTypes.object.isRequired,
  onSelectLocation: PropTypes.func.isRequired,
};

export default LocationSelector;
