import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const NoWrapTypography = ({ value, padding, color, size }) => {
  return (
    <div
      style={{
        color: '#3b3b39',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        paddingLeft: padding,
        paddingRight: padding,
      }}
    >
      <Tooltip title={value}>
        <Typography align={'left'} color={color} variant={size} noWrap={true}>
          {value}
        </Typography>
      </Tooltip>
    </div>
  );
};

NoWrapTypography.propTypes = {
  value: PropTypes.string.isRequired,
  padding: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.string,
};

NoWrapTypography.defaultProps = {
  padding: 8,
  color: 'secondary',
  size: 'caption',
};

export { NoWrapTypography };
