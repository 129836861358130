import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { EDIT_PROFILE } from './index';
import { isProfileComplete } from '../utils/profile';

const ProfileCheck = ({ path, children }) => {
  const user = useSelector((state) => state.user.user);
  if (user) {
    const { displayName, email, phone, street, location, state, country, zipcode, language } = user;
    const profile = {
      fullName: displayName,
      email,
      phone,
      street,
      location,
      state,
      country,
      zipcode,
      language,
    };

    return isProfileComplete(profile) ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: EDIT_PROFILE,
          state: { from: path },
        }}
      />
    );
  }

  return <>{children}</>;
};

export default ProfileCheck;
