import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import rentShape from '../shapes/rentShape';
import { useTranslation } from 'react-i18next';

const UserAndRentalDates = ({ rental }) => {
  const { t } = useTranslation();

  const renderDates = () => {
    if (rental) {
      const { unit, startDate, startTime, endDate, endTime } = rental;
      if (unit === undefined || unit === 'days') {
        return (
          <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
            {moment(startDate.toDate()).format('YYYY-MM-DD')} {t('to')}{' '}
            {endDate ? moment(endDate.toDate()).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
          </Typography>
        );
      }
      return (
        <>
          <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
            {moment(startDate.toDate()).format('YYYY-MM-DD')} {`${startTime}:00`} {t('to')}{' '}
          </Typography>
          <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
            {endDate ? moment(endDate.toDate()).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} {`${endTime}:00`}
          </Typography>
        </>
      );
    }
  };

  return (
    <>
      {' '}
      <div style={{ display: 'flex', width: '100%', paddingTop: 40, paddingBottom: 10 }}>
        <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
          {t('rented_dates')}
        </Typography>
      </div>
      {renderDates()}
    </>
  );
};

UserAndRentalDates.propTypes = {
  rental: rentShape.isRequired,
};

export { UserAndRentalDates };
