import React, { useState } from 'react';
import { Badge, Grid, makeStyles, Typography, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import messageShape from '../../shapes/messageShape';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RENT } from '../../routes';
import { NoWrapTypography } from '../../components/noWrapTypography';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 60,
    height: 60,
    cursor: 'pointer',
  },
  fromInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingLeft: 10,
  },
}));

const element = {
  paddingBottom: 10,
  paddingTop: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
};

const withBorder = {
  borderBottom: '1px solid lightGray',
};

const MessageItem = ({ message, isLast, onSelect, isSelected, history }) => {
  const classes = useStyles();

  const [mouseOver, setMouseOver] = useState(false);
  const user = useSelector((state) => state.user.user);

  const isUnread = () => {
    const isOwner = message.user === user.uid;
    if (isOwner) {
      return message.unreadByOwner;
    }
    return message.unreadByUser;
  };

  const computeStyleForElement = () => {
    let style;
    if (isLast) {
      style = element;
    } else {
      style = { ...element, ...withBorder };
    }

    /* istanbul ignore next */
    if (isSelected) {
      /* istanbul ignore next */
      if (mouseOver) {
        /* istanbul ignore next */
        return { ...style, backgroundColor: '#C3C3C3' };
      }
      /* istanbul ignore next */
      return { ...style, backgroundColor: '#ededed' };
    } else {
      /* istanbul ignore next */
      if (mouseOver) {
        /* istanbul ignore next */
        return { ...style, backgroundColor: '#ededed' };
      }
      return { ...style, backgroundColor: 'white' };
    }
  };

  const goToAd = () => {
    history.push(RENT.replace(':adId', message.adId));
  };

  const StyledBadge = withStyles((theme) => ({
    badge: {
      top: 5,
      backgroundColor: '#8b0000',
      color: theme.palette.background.paper,
    },
  }))(Badge);

  return (
    <div
      style={computeStyleForElement()}
      data-testid={`thread-${message.uid}`}
      onMouseEnter={/* istanbul ignore next */ () => setMouseOver(true)}
      onMouseLeave={/* istanbul ignore next */ () => setMouseOver(false)}
      onClick={onSelect}
    >
      <Grid container>
        <Grid item>
          <StyledBadge
            badgeContent={isUnread() ? 1 : 0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Avatar
              data-testid={`useravatar-${message.uid}`}
              className={classes.avatar}
              src={message.avatar}
              onClick={() => goToAd()}
            />
          </StyledBadge>
        </Grid>
        <Grid item xs={9}>
          <div className={classes.fromInfoContainer}>
            <NoWrapTypography
              padding={0}
              value={message.fromName}
              color={'secondary'}
              align={'left'}
              variant={'caption'}
            />
            <Typography color={'secondary'} align={'left'} variant={'caption'}>
              {message.lastMessage}
            </Typography>
            <Typography color={'secondary'} align={'left'} variant={'caption'}>
              {moment(message.date.toDate()).format('YYYY-MM-DD')}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

MessageItem.propTypes = {
  message: messageShape.isRequired,
  isLast: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withRouter(MessageItem);
