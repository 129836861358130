import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import SecuredRoute from '../../routes/SecuredPage';
import { EDIT_PROFILE } from '../../routes';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as QueryString from 'query-string';
import { useProfileServiceContext } from '../../services/ServicesProvider';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { firebaseService } from '../../services/firebaseServiceInstance';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const ProfileCreated = ({ history, location, initialSignedInState }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const profileService = useProfileServiceContext();
  const [userSignedIn, setUserSignedIn] = useState(initialSignedInState);
  const { loadUser } = useRematchDispatch((dispatch) => ({ loadUser: dispatch.user.loadUser }));

  /* istanbul ignore next */
  firebaseService.getAuth().onAuthStateChanged((user) => {
    /* istanbul ignore next */
    if (user) {
      /* istanbul ignore next */
      setUserSignedIn(true);
    } else {
      /* istanbul ignore next */
      setUserSignedIn(false);
    }
  });

  useEffect(() => {
    const params = QueryString.parse(location.search);

    async function doConfirmAccount() {
      await profileService.confirmAccount(params.id, params.account);
      loadUser({ uid: params.id });
      history.push(EDIT_PROFILE);
    }

    /* istanbul ignore next */
    if (userSignedIn) {
      doConfirmAccount();
    }
  }, [userSignedIn, location.search, history, loadUser, profileService]);

  return (
    <SecuredRoute path={EDIT_PROFILE}>
      <div className={classes.header}>
        <Header />
      </div>

      <div className={classes.topText}>
        <Typography color={'primary'} align={'left'} variant={'h5'}>
          {t('loading')}
        </Typography>
      </div>
    </SecuredRoute>
  );
};

ProfileCreated.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  initialSignedInState: PropTypes.bool,
};

ProfileCreated.defaultProps = {
  initialSignedInState: false,
};

export default withRouter(ProfileCreated);
