import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: 'e6ba3b73c8473d049993c54966b25458',
  plugins: [new BugsnagPluginReact()],
  appVersion: '1.0',
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);
