import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderToggleButton } from './headerToggleButton';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';

const DashboardHeaderToggleButton = ({ onClick, title, extraStyle }) => {
  const pendingApprovalAds = useSelector((state) => state.ads.pendingApprovalAds);
  const disputeAds = useSelector((state) => state.ads.disputeAds);

  const [numberOfUnread, setNumberOfUnread] = useState(0);

  useEffect(() => {
    let unreads = 0;

    if (pendingApprovalAds) {
      const unreadPending = filter(pendingApprovalAds, (o) => o.rent.unread === true);
      unreads = unreads + unreadPending.length;
    }

    if (disputeAds) {
      const unreadDisputes = filter(disputeAds, (o) => o.rent.unread === true);
      unreads = unreads + unreadDisputes.length;
    }
    setNumberOfUnread(unreads);
  }, [pendingApprovalAds, disputeAds]);

  return (
    <HeaderToggleButton
      onClick={onClick}
      title={title}
      badgeCount={numberOfUnread}
      extraStyle={{ ...extraStyle, paddingRight: /* istanbul ignore next */ numberOfUnread !== 0 ? 25 : 5 }}
    />
  );
};

DashboardHeaderToggleButton.propTypes = {
  title: PropTypes.string.isRequired,
  extraStyle: PropTypes.object,
};

export { DashboardHeaderToggleButton };
