import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { renderStars } from '../utils/ratings';
import { useSelector } from 'react-redux';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';
import PropTypes from 'prop-types';
import { PROFILE_VIEW } from '../routes';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  avatarAndRating: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  avatar: {
    width: 100,
    height: 100,
    cursor: 'pointer',
  },
  starsAndContact: {
    paddingLeft: 30,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ContactInfo = ({ user, history }) => {
  const classes = useStyles();

  const locations = useSelector((state) => state.locations.locations);
  const locale = useSelector((state) => state.authentication.locale);

  const goToUserProfile = () => {
    history.push(PROFILE_VIEW.replace(':userId', user.uid));
  };

  return (
    <div className={classes.container}>
      {user && (
        <>
          <div className={classes.avatarAndRating}>
            <div className={classes.avatar}>
              <Avatar
                data-testid={'avatar-button'}
                className={classes.avatar}
                src={user.photoURL}
                onClick={goToUserProfile}
              />
            </div>
            <div className={classes.starsAndContact}>{user && renderStars(user.rating)}</div>
          </div>
          <div style={{ width: '100%' }}>
            <Typography align={'left'} color={'secondary'} variant={'h5'} noWrap={true}>
              {user.displayName}
            </Typography>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <Typography align={'left'} color={'secondary'} variant={'h5'} noWrap={true}>
              {user.location !== undefined ? locationFor(locations, user.location).name[languageToLocale(locale)] : ''}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

ContactInfo.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ContactInfo);
