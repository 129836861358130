import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import CardSection from './cardSection';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useAdServiceContext } from '../../services/ServicesProvider';
import PropTypes from 'prop-types';
import LoadingButton from '../../components/loadingButton';

const useStyles = makeStyles(() => ({
  imageLogo: {
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    left: 0,
    top: 34,
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },
  textContent: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundedBox: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 10,
  },
  cardElement: {
    width: '100%',
    height: '100%',
  },
  buttonAndError: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorMessage: {
    height: 50,
    paddingTop: 5,
    fontFamily: 'Oswald bold',
  },
}));

const CardForm = ({ rentId, userEmail, paymentSuccess, initialCardValid }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const adService = useAdServiceContext();

  const [error, setError] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const [ownerName, setOwnerName] = useState('');
  const [cardValid, setCardValid] = useState(initialCardValid);

  const handleSubmit = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    /* istanbul ignore next */
    if (!stripe || !elements) {
      return;
    }

    if (ownerName === '') {
      setError(t('paywall_owner_name_not_defined'));
      return;
    }

    setProcessingPayment(true);
    try {
      const session = await adService.pay(rentId, userEmail);
      const result = await stripe.confirmCardPayment(session, {
        payment_method: {
          card: elements.getElement('cardNumber'),
          billing_details: {
            name: ownerName,
          },
        },
      });
      if (result.error) {
        setError(result.error.message);
        setProcessingPayment(false);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded' || result.paymentIntent.status === 'requires_capture') {
          await adService.confirmPayment(rentId);
          setProcessingPayment(false);
          paymentSuccess();
        } else {
          console.debug('Payment status is', result.paymentIntent.status);
          setProcessingPayment(false);
          setError('unable to process payment');
        }
      }
    } catch (error) {
      setProcessingPayment(false);
      setError(t('paywall_generic_error'));
    }
  };

  const onStateChange = (valid) => {
    setCardValid(valid);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection
        onOwnerNameChanged={setOwnerName}
        onStateChange={onStateChange}
        initialCardValid={initialCardValid}
      />
      <div className={classes.buttonAndError}>
        <Button
          data-testid={'payButton'}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!cardValid || !stripe || processingPayment}
        >
          <LoadingButton loading={processingPayment} content={t('paywall_pay')} />
        </Button>
        <div className={classes.errorMessage}>
          {error && (
            <Typography color={'error'} align={'left'} variant={'subtitle1'}>
              {error}
            </Typography>
          )}
        </div>
      </div>
    </form>
  );
};

CardForm.propTypes = {
  rentId: PropTypes.string.isRequired,
  paymentSuccess: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  initialCardValid: PropTypes.bool,
};

CardForm.defaultProps = {
  initialCardValid: false,
};

export default CardForm;
