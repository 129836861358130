import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import RentingImage from '../../components/rentingImage';
import AdShape from '../../shapes/adShape';
import { useSelector } from 'react-redux';
import notavailable from '../../assets/noimageavailable.png';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../../utils/location';
import { languageToLocale } from '../../utils/locale';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  itemDescription: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemInformation: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 5,
  },
  commentBox: {
    display: 'block',
    width: '100%',
    height: 125,
    fontFamily: 'Oswald',
    color: '#1c1e6e',
    fontSize: 12,
    textAlign: 'left',
    marginRight: 10,
  },
  customBox: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 7,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
}));

const ItemDescription = ({ ad, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const adStats = useSelector((state) => state.ads.adStats);
  const locale = useSelector((state) => state.authentication.locale);

  const [adStat, setAdStat] = useState();

  useEffect(() => {
    /* istanbul ignore next */
    if (ad && adStats) {
      const adStat = adStats.find((adStat) => adStat.adId === ad.uid);
      if (adStat) {
        setAdStat(adStat);
      }
    }
  }, [ad, adStats]);

  return (
    <div className={classes.itemDescription}>
      <RentingImage
        title={ad.title}
        location={locationFor(locations, ad.location).name[languageToLocale(locale)]}
        image={ad.images && ad.images[0] ? ad.images[0] : notavailable}
        price={ad.price}
        unit={ad.unit}
        mainImageWidth={ad.mainImageWidth}
        mainImageHeight={ad.mainImageHeight}
        noBorder
        testid={`edit-ad-${ad.uid}`}
        onAdClick={onClick}
      />
      <div className={classes.commentBox}>
        <Box component="div" classes={{ root: classes.customBox }}>
          {ad.description}
        </Box>
      </div>
      <div className={classes.itemInformation}>
        <Typography color={'secondary'} align={'left'}>
          {t('dashboard_stats')}
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'caption'}>
          {`${t('dashboard_date_published')}: ${moment(ad.createdDate.toDate()).format('YYYY-MM-DD')}`}
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'caption'}>
          {`${t('dashboard_number_of_rentals')}: ${adStat ? adStat.totalRentals.toString() : '0'}`}
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'caption'}>
          {`${t('dashboard_money_so_far')}: ${adStat ? adStat.totalMoney.toString() : '0'} $`}
        </Typography>
        <Typography color={'primary'} align={'left'} variant={'caption'}>
          {`${t('dashboard_number_of_reviews')}: ${adStat ? adStat.reviews.toString() : '0'}`}
        </Typography>
      </div>
    </div>
  );
};

ItemDescription.propTypes = {
  ad: AdShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ItemDescription;
