import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ReviewCardWithoutImage from './reviewCardWithoutImage';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import useRematchDispatch from '../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../utils/location';
import { languageToLocale } from '../utils/locale';
import LoadingComponent from './loadingComponent';

const useStyles = makeStyles(() => ({
  lastRentals: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  noReviews: {
    display: 'block',
    marginTop: 0,
    justifyContent: 'center',
    width: 200,
    height: 186,
  },
}));

const GridLastReviews = ({ adId, maxResults }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const lastRentals = useSelector((state) => state.rent.lastRentals);
  const locale = useSelector((state) => state.authentication.locale);

  const [loading, setLoading] = useState(true);

  const { loadLastRentals } = useRematchDispatch((dispatch) => ({
    loadLastRentals: dispatch.rent.loadLastRentals,
  }));

  useEffect(() => {
    async function doLoadLasRentals() {
      await loadLastRentals({ adId, maxResults });
      setLoading(false);
    }
    doLoadLasRentals();
  }, [adId, maxResults, loadLastRentals]);

  const renderComments = () => {
    if (lastRentals && lastRentals[adId]) {
      return (
        <LoadingComponent isLoading={loading}>
          <Grid container spacing={3}>
            {lastRentals[adId].map((rental, index) => {
              const { review, rating, image, location, displayName } = rental;
              return (
                <Grid key={`rc-${index}`} item lg={3} md={4} sm={6} xs={12}>
                  <ReviewCardWithoutImage
                    displayName={displayName}
                    reviewComment={review}
                    location={locationFor(locations, location).name[languageToLocale(locale)]}
                    avatar={image}
                    rating={rating}
                  />
                </Grid>
              );
            })}
          </Grid>
        </LoadingComponent>
      );
    }
    return (
      <div className={classes.noReviews}>
        <Typography align={'left'} color={'secondary'} variant={'body1'} noWrap={true}>
          {t('component_reviews_no_reviews')}
        </Typography>
      </div>
    );
  };

  return <div className={classes.lastRentals}>{renderComments()}</div>;
};

GridLastReviews.propTypes = {
  adId: PropTypes.string.isRequired,
  maxResults: PropTypes.number,
};

GridLastReviews.defaultProps = {
  maxResults: undefined,
};

export default GridLastReviews;
