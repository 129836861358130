import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Header from '../../components/header';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CANCEL_RENTAL, DASHBOARD } from '../../routes';
import SecuredRoute from '../../routes/SecuredPage';
import Container from '@material-ui/core/Container';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { useAdServiceContext, useMessageServiceContext } from '../../services/ServicesProvider';
import Loading from '../../components/loading';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import OwnerContactInfo from '../../components/ownerContactInfo';
import notavailable from '../../assets/noimageavailable.png';
import { ErrorSnackbar } from '../../components/errorSnackbar';
import BillingSection from '../../components/billingSection';
import LoadingButton from '../../components/loadingButton';
import ResizableImageBox from 'components/resizableImageBox';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rentItem: {
    width: '100%',
    height: '100%',
  },
  paymentInfo: {
    width: '100%',
    height: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    boxSizing: 'border-box',
    backgroundColor: '#f7f7f5',
  },
  billInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
    height: '100%',
  },
  subContainer: {
    display: 'block',
    width: '100%',
    height: 600,
  },
  cardInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  buttons: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  warningCancellation: {
    display: 'flex',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

const CancelRental = ({ history, rentId }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [paymentInfoLoading, setPayloadLoadingInfoLoading] = useState(true);
  const [item, setItem] = useState(undefined);

  const adService = useAdServiceContext();

  const { loadRental, clearAd, loadAd, loadOwner, loadUser } = useRematchDispatch((dispatch) => ({
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
    loadRental: dispatch.rent.loadRental,
    loadUser: dispatch.rent.loadUser,
    loadOwner: dispatch.rent.loadOwner,
  }));
  const rental = useSelector((state) => state.rent.currentRental);
  const ad = useSelector((state) => state.ads.currentAd);
  const owner = useSelector((state) => state.rent.owner);
  const renterUser = useSelector((state) => state.rent.user);
  const currentUser = useSelector((state) => state.user.user);

  const [open, setOpen] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadRental({ id: rentId });
  }, [rentId, loadRental]);

  useEffect(() => {
    function doLoadItem() {
      loadAd({ id: rental.ad });
      loadUser({ id: rental.user });
      loadOwner({ id: rental.owner });
    }

    /* istanbul ignore next */
    if (rental) {
      doLoadItem();
    }
  }, [rental, loadAd, loadUser, loadOwner, adService]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  useEffect(() => {
    /* istanbul ignore next */
    if (ad !== undefined && owner !== undefined && renterUser !== undefined && rental !== undefined) {
      setItem({ ad, owner, user: renterUser, rent: rental });
      setLoading(false);
    }
  }, [ad, owner, renterUser, rental]);

  const messageService = useMessageServiceContext();

  const handleClose = () => {
    setOpen(false);
    history.push(DASHBOARD);
  };

  const confirmCancellation = async () => {
    setProcessingPayment(true);
    try {
      await adService.refund({ rentId: rental.uid, callerId: currentUser.uid });
      setProcessingPayment(false);
      setOpen(true);
    } catch (e) {
      setError(true);
    }
  };

  const cancel = () => {
    history.push(DASHBOARD);
  };

  const sendQuestion = (question) => {
    messageService.askQuestion({
      user: ad.user,
      from: currentUser.uid,
      fromName: currentUser.displayName,
      avatar: /* istanbul ignore next */ ad && ad.images[0] ? ad.images[0] : notavailable,
      question,
      adId: ad.uid,
    });
  };

  const renderButtons = () => {
    return (
      <>
        <div className={classes.buttons}>
          <Button
            data-testid={'confirm'}
            type="submit"
            variant="contained"
            color="secondary"
            onClick={confirmCancellation}
          >
            <LoadingButton loading={processingPayment} content={t('cancel_rent_confirm')} />
          </Button>
        </div>
        {processingPayment === false && (
          <div className={classes.buttons}>
            <Button data-testid={'cancel'} type="submit" variant="contained" color="primary" onClick={cancel}>
              {t('confirm_rent_cancel')}
            </Button>
          </div>
        )}
      </>
    );
  };

  const onBillingSectionLoadingDone = () => {
    setPayloadLoadingInfoLoading(false);
  };

  const renderLoadingContent = () => {
    if (!loading) {
      return (
        <>
          <Container maxWidth={'xl'}>
            <div style={{ display: 'flex', paddingTop: 20 }}>
              <OwnerContactInfo
                showUserName={true}
                showAskQuestion={true}
                owner={owner}
                onSendQuestion={sendQuestion}
              />
            </div>
            <ErrorSnackbar
              open={error}
              onCloseSnackbar={handleClose}
              rentId={rental.uid}
              autoHideDuration={null}
              errorMessage={t('cant_refund_item', { rentId })}
            />
            <div className={classes.warningCancellation}>
              <Typography color={'primary'} align={'left'} variant={'h5'}>
                {t('cancel_rent_please_confirm', { title: /* istanbul ignore next */ ad ? ad.title : '' })}
              </Typography>
            </div>
            <div className={classes.mainContainer}>
              <Grid container>
                <Grid item md={8}>
                  <div className={classes.rentItem}>
                    <ResizableImageBox ad={ad} supportSmallMode={true} />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.paymentInfo}>
                    <div className={classes.subContainer}>
                      <div className={classes.billInfo}>
                        <BillingSection
                          item={item}
                          onLoadingDone={onBillingSectionLoadingDone}
                          showCancellationFees={true}
                        />
                        {paymentInfoLoading === false && renderButtons()}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </>
      );
    }
    return <></>;
  };

  return (
    <SecuredRoute path={CANCEL_RENTAL}>
      <ProfileCheck>
        <div className={classes.header}>
          <Header />
        </div>

        <Loading isLoading={loading && paymentInfoLoading}>{renderLoadingContent()}</Loading>
        <Dialog data-testid={'cancel-rent-alert-dialog'} open={open} onClose={handleClose}>
          <DialogTitle>{t('cancel_booking_requested')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('booking_successfully_cancelled')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid={'booking-cancelled--alert-dialog-close'} onClick={handleClose} color="primary">
              {t('action_close')}
            </Button>
          </DialogActions>
        </Dialog>
      </ProfileCheck>
    </SecuredRoute>
  );
};

CancelRental.propTypes = {
  history: PropTypes.object.isRequired,
  rentId: PropTypes.string.isRequired,
};

export default withRouter(CancelRental);
