import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  fullPageWidth: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Loading = ({ isLoading, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={classes.fullPageWidth}>
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('loading')}
        </Typography>
      </div>
    );
  }
  return children;
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Loading;
