import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import toolbox from '../../assets/tools-transparent.png';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    background: `linear-gradient(rgba(255,255,255,.75), rgba(255,255,255,.5)), url(${toolbox})`,
  },
  content: {
    height: '100vh',
    width: 'auto',
  },
  textContent: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundedBox: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 10,
  },
}));

const MaintenanceScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.page}>
      <div className={classes.content}>
        <div className={classes.textContent}>
          <div className={classes.roundedBox}>
            <Typography color={'error'} align={'center'} variant={'h5'}>
              {t('site_under_maintenance')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

MaintenanceScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MaintenanceScreen);
