import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HeaderToggleButton } from './headerToggleButton';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';

const InboxHeaderToggleButton = ({ onClick, title, extraStyle }) => {
  const fromMessages = useSelector((state) => state.inbox.fromMessages);
  const toMessages = useSelector((state) => state.inbox.toMessages);

  const [numberOfUnread, setNumberOfUnread] = useState(0);

  useEffect(() => {
    let unreads = 0;

    if (fromMessages) {
      const unreadFrom = filter(fromMessages, (o) => o.unreadByUser === true);
      unreads = unreads + unreadFrom.length;
    }

    if (toMessages) {
      const unreadTo = filter(toMessages, (o) => o.unreadByOwner === true);
      unreads = unreads + unreadTo.length;
    }
    setNumberOfUnread(unreads);
  }, [fromMessages, toMessages]);

  return (
    <HeaderToggleButton
      onClick={onClick}
      title={title}
      badgeCount={numberOfUnread}
      extraStyle={{ ...extraStyle, paddingRight: /* istanbul ignore next */ numberOfUnread !== 0 ? 20 : 0 }}
    />
  );
};

InboxHeaderToggleButton.propTypes = {
  title: PropTypes.string.isRequired,
  extraStyle: PropTypes.object,
};

export { InboxHeaderToggleButton };
