/* istanbul ignore file */
import React from 'react';
import { initFirebase } from 'firebaseClient';
import theme from './theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MainRouter from './routes/MainRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './models/store';
import { Provider } from 'react-redux';
import { firebaseService, initFirebaseService } from './services/firebaseServiceInstance';
import { firebase, setFirebaseInstance } from './services/firebaseInstance';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ServicesProvider } from './services/ServicesProvider';
import AdService from './services/adService';
import SearchService from './services/searchService';
import ProfileService from './services/profileService';
import MessageService from './services/messageService';
import UserLoader from './components/userLoader';
import './i18n';
import LanguageSelector from './components/languageSelector';
import LocationLoader from './components/locationsLoader';
import { AppLoading } from './services/AppLoading';
import MaintenanceWatcher from './components/maintenanceWatcher';

setFirebaseInstance(initFirebase());
initFirebaseService(firebase);

const persistor = getPersistor();
const messageService = new MessageService(firebaseService);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ServicesProvider
          adService={new AdService(firebaseService, messageService)}
          searchService={new SearchService(firebaseService)}
          profileService={new ProfileService(firebaseService)}
          messageService={messageService}
        >
          <LocationLoader />
          <AppLoading>
            <MuiThemeProvider theme={theme}>
              <div className="App">
                <header className="App-header">
                  <LanguageSelector />
                  <UserLoader />
                  <Router>
                    <MainRouter />
                    <MaintenanceWatcher />
                  </Router>
                </header>
              </div>
            </MuiThemeProvider>
          </AppLoading>
        </ServicesProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
