import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: 160,
    marginLeft: 16,
    marginRight: 16,
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 4,
    marginRight: 4,
  },
}));

const RentingGridItem = ({ text, description, children }) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} xl={4} lg={4} md={4}>
      <div className={classes.gridItem}>
        {children}
        <div className={classes.textBox}>
          <Typography color={'textPrimary'} align={'left'} variant={'h6'}>
            {text}
          </Typography>
          <Typography color={'textSecondary'} align={'left'}>
            {description}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

RentingGridItem.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default RentingGridItem;
