import React, { useCallback, useEffect, useState } from 'react';
import { DASHBOARD, EDIT_PROFILE, POST_AD, SEARCH_RESULTS } from '../../routes';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CategorySelector from '../../components/categorySelector';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useAdServiceContext } from '../../services/ServicesProvider';
import { useSelector } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from 'react-router-dom';
import UploadImage from './uploadImage';
import parse from 'url-parse';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import AllReviews from './allReviews';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import InputNumericFieldWithTooltip from '../../components/InputNumericFieldWithToolTip';
import BasePage from '../../components/basePage';
import InputNumericField from '../../components/InputNumericField';
import UnitSelector from '../../components/unitSelector';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  elementSpacer: {
    width: '100%',
    paddingTop: 20,
  },
  description: {
    paddingTop: 20,
    width: '100%',
  },
  topText: {
    display: 'box',
    width: '100%',
    flex: 1,
    paddingLeft: 25,
    boxSizing: 'border-box',
  },
  imagesUploadContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  imagesUploadSection: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    paddingBottom: 20,
  },
  buttonContainer2: {
    width: '90',
    paddingRight: 20,
  },
  horizontalSpacer: {
    paddingRight: 10,
  },
  pricebox: {
    paddingTop: 20,
    display: 'flex',
    width: '100',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  warningBox: {
    paddingBottom: 10,
  },
}));

const PostAd = ({ adId, history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { loadAd, clearAd } = useRematchDispatch((dispatch) => ({
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
  }));
  const ad = useSelector((state) => state.ads.currentAd);

  const categories = useSelector((state) => state.categories.categories);

  const [category, setCategory] = useState();
  const [canPublish, setCanPublish] = useState(false);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);

  const [price, setPrice] = useState('');
  const [securityDeposit, setSecurityDeposit] = useState('');
  const adService = useAdServiceContext();
  const user = useSelector((state) => state.user.user);
  const [open, setOpen] = React.useState(false);
  const [imagesUrl, setImagesUrl] = useState(['', '', '', '', '', '', '', '', '', '']);
  const [unit, setUnit] = useState(undefined);

  const selectCategory = (value) => {
    setCategory(value);
  };

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const onPriceChange = (value) => {
    setPrice(value);
  };

  const onUnitChange = (value) => {
    setUnit(value);
  };

  const onSecurityDepositChange = (value) => {
    setSecurityDeposit(value);
  };

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const isEdit = useCallback(() => {
    return adId !== undefined;
  }, [adId]);

  const publishAd = () => {
    if (isEdit()) {
      adService.saveAd({
        uid: ad.uid,
        category,
        title,
        description,
        price,
        unit,
        securityDeposit,
        images: imagesUrl,
        location: user.location,
      });
      history.push(DASHBOARD);
    } else {
      adService.postAd({
        user: user.uid,
        category,
        title,
        description,
        price,
        unit,
        images: imagesUrl,
        location: user.location,
        securityDeposit,
      });
      showAlert();
    }
  };

  useEffect(() => {
    setCanPublish(
      title !== '' &&
        description !== '' &&
        description !== undefined &&
        price !== undefined &&
        price !== '' &&
        securityDeposit !== undefined &&
        securityDeposit !== '' &&
        isNaN(price) === false &&
        isNaN(securityDeposit) === false,
    );
  }, [title, price, securityDeposit, description]);

  useEffect(() => {
    if (isEdit()) {
      loadAd({ id: adId });
    } else {
      clearAd();
      setCategory(undefined);
      setCanPublish(false);
      setDescription('');
      setTitle('');
      setPrice('');
      setUnit('day');
      setSecurityDeposit('');
      setImagesUrl(['', '', '', '', '', '', '', '', '', '']);
    }
  }, [adId, loadAd, clearAd, isEdit]);

  useEffect(() => {
    if (ad !== undefined && adId !== undefined) {
      setCategory(ad.category);
      setTitle(ad.title);
      setDescription(ad.description);
      setPrice(ad.price);
      /* istanbul ignore next */
      setUnit(ad.unit ? ad.unit : 'day');
      setSecurityDeposit(ad.securityDeposit);
      setImagesUrl([
        ad.images[0],
        ad.images[1],
        ad.images[2],
        ad.images[3],
        ad.images[4],
        ad.images[5],
        ad.images[6],
        ad.images[7],
        ad.images[8],
        ad.images[9],
      ]);
      setLoading(false);
    }
  }, [ad, adId, categories]);

  useEffect(() => {
    /* istanbul ignore next */
    if (categories) {
      if (adId !== undefined) {
        setLoading(ad === undefined);
      } else {
        setLoading(false);
        setCategory(categories[0].code);
      }
    }
  }, [adId, ad, categories]);

  const handleClose = () => {
    setOpen(false);
    history.push(DASHBOARD);
  };

  const handleGoToProfile = () => {
    history.push({
      pathname: EDIT_PROFILE,
      state: { selectedTab: 2 },
    });
  };

  const showAlert = () => {
    setOpen(true);
  };

  /* istanbul ignore next */
  const setImageSelected = (index, downloadURL) => {
    /* istanbul ignore next */
    const newImagesUrl = [...imagesUrl];
    newImagesUrl[index] = downloadURL;
    setImagesUrl(newImagesUrl);
  };

  /* istanbul ignore next */
  const clearImageSelected = async (index) => {
    const pathName = parse(imagesUrl[index]).pathname;
    const imageRef = pathName.substring(pathName.lastIndexOf('/') + 1);
    await adService.deleteImage(imageRef);

    const newImagesUrl = [...imagesUrl];
    newImagesUrl[index] = '';
    setImagesUrl(newImagesUrl);
  };

  const goBack = () => {
    history.goBack();
  };

  const renderPublishAdSection = () => {
    if (user && user.payoutsEnabled) {
      return (
        <>
          <div className={classes.elementSpacer}>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('postad_field_category')}
            </Typography>
          </div>
          <CategorySelector category={category} onSelectCategory={selectCategory} />
          <div className={classes.elementSpacer}>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('postad_field_title')}
            </Typography>
            <TextField
              inputProps={{ 'data-testid': 'title' }}
              variant="outlined"
              fullWidth={true}
              value={title}
              onChange={onTitleChange}
            />
          </div>
          <div className={classes.description}>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('postad_field_description')}
            </Typography>
            <TextField
              fullWidth={true}
              multiline={true}
              rows={8}
              inputProps={{ 'data-testid': 'description', maxLength: 750 }}
              variant="outlined"
              value={description}
              onChange={onDescriptionChange}
            />
          </div>
          <div className={classes.pricebox}>
            <InputNumericField
              className={classes.price}
              onChange={onPriceChange}
              value={price}
              title={t('postad_field_price')}
              testid={'price'}
            />
            <UnitSelector unit={unit} onSelectUnit={onUnitChange} />
          </div>
          <div className={classes.elementSpacer}>
            <InputNumericFieldWithTooltip
              onChange={onSecurityDepositChange}
              value={securityDeposit}
              title={t('postad_field_security_deposit')}
              tooltipText={t('postad_tooltip_securitydeposit')}
              testid={'securityDeposit'}
            />
          </div>
          <div className={classes.imagesUploadSection}>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('postad_images_edit')}
            </Typography>
          </div>
          <Grid container>
            {[0, 1, 2, 3, 4].map((value) => {
              return (
                <Grid item key={`adimage-${value}`}>
                  <UploadImage
                    key={value}
                    image={imagesUrl[value]}
                    imageDefinedInitialState={!!imagesUrl[value]}
                    onImageSelected={/* istanbul ignore next */ (image) => setImageSelected(value, image)}
                    onImageRemoved={/* istanbul ignore next */ () => clearImageSelected(value)}
                  />
                </Grid>
              );
            })}
            {[5, 6, 7, 8, 9].map((value) => {
              return (
                <Grid item key={`adimage-${value}`}>
                  <UploadImage
                    key={value}
                    image={imagesUrl[value]}
                    imageDefinedInitialState={!!imagesUrl[value]}
                    onImageSelected={/* istanbul ignore next */ (image) => setImageSelected(value, image)}
                    onImageRemoved={/* istanbul ignore next */ () => clearImageSelected(value)}
                  />
                </Grid>
              );
            })}
          </Grid>
          <div className={classes.buttonContainer}>
            <div className={classes.buttonContainer2}>
              <Button
                data-testid={'publishButton'}
                variant="contained"
                color="primary"
                disabled={!canPublish}
                onClick={publishAd}
                className={classes.horizontalSpacer}
              >
                {isEdit() ? t('action_save') : t('action_publish')}
              </Button>
            </div>
            <Button data-testid={'cancelButton'} variant="contained" color="primary" onClick={goBack}>
              {t('action_cancel')}
            </Button>
          </div>
          {isEdit() && <AllReviews adId={adId} />}
        </>
      );
    }

    return (
      <div>
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('account_not_linked')}
        </Typography>
        <div className={classes.elementSpacer}>
          <Button data-testid={'goToProfileButton'} variant="contained" color="primary" onClick={handleGoToProfile}>
            {t('go_to_profile')}
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <></>;
    }
    return (
      <div className={classes.page}>
        <div className={classes.topText}>
          <Typography color={'primary'} align={'left'} variant={'h5'}>
            {isEdit() ? t('postad_edit_item') : t('postad_new_item')}
          </Typography>
        </div>

        <Container maxWidth={'lg'}>
          <div className={classes.mainContainer}>{renderPublishAdSection()}</div>
          <div>
            <Typography color={'primary'} align={'left'} variant={'h4'} className={classes.warningBox}>
              {t('warning')}
            </Typography>
            <Typography color={'primary'} align={'left'} variant={'h6'}>
              {t('warning_details')}
            </Typography>
          </div>
        </Container>
      </div>
    );
  };

  return (
    <BasePage pagePath={POST_AD} loading={loading}>
      {renderContent()}
      <Dialog data-testid={'published-alert-dialog'} open={open} onClose={handleClose}>
        <DialogTitle>{t('postad_published')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('postad_ad_successfully_published')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid={'published-alert-dialog-close'} onClick={handleClose} color="primary">
            {t('action_close')}
          </Button>
        </DialogActions>
      </Dialog>
    </BasePage>
  );
};

PostAd.propTypes = {
  history: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
};

PostAd.defaultProps = {
  editMode: false,
};

export default withRouter(PostAd);
