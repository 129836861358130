import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CategorySelector from './categorySelector';
import LocationSelector from './locationSelector';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AdvancedSearch from './advancedSearchBox';
import { DEFAULT_RADIUS } from '../screens/Profile';
import { useProfileServiceContext } from '../services/ServicesProvider';
import { debounce } from 'lodash';
import { SEARCH_RESULTS } from '../routes';
import { useHistory, withRouter } from 'react-router-dom';
import { buildSearchParams, getDefaultSortOrder } from '../utils/searchTools';
import { globalLocationDetected } from './locationDetector';
import SORTFIELDS from '../sortFields';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
  },
  title: {
    width: '100%',
  },
  what: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'left',
    height: 55,
    width: '100%',
  },
  in: {
    display: 'flex',
    justifyContent: 'left',
    height: 30,
    width: '100%',
    paddingLeft: 14,
    paddingTop: 20,
    paddingBottom: 14,
  },
  category: {
    display: 'flex',
    height: 30,
    width: '100%',
  },
  categoryContent: {
    display: 'flex',
    justifyContent: 'left',
    width: '100%',
    height: '100%',
    paddingLeft: 14,
    paddingRight: 14,
  },
  buttons: {
    paddingTop: 14,
    paddingBottom: 14,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-even',
  },
  button: {
    paddingRight: 14,
  },
  where: {
    display: 'flex',
    justifyContent: 'left',
    height: 55,
    width: '100%',
  },
  whereSelector: {
    display: 'flex',
    width: '50%',
    justifyContent: 'left',
  },
}));

const SearchBox = ({
  location,
  onSearch,
  title,
  large,
  inputWhat,
  inputCategory,
  inputLocation,
  inputIncludeCommercial,
  inputSearchRadius,
  inputFrom,
  inputTo,
  inputSortOrder,
  showAdvanced,
  triggerAutoSearch,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const user = useSelector((state) => state.user.user);

  const mountedRef = useRef();
  const disableNextAutoSearchRef = useRef(false);

  const [formData, setFormData] = useState({
    what: inputWhat,
    category: inputCategory,
    location: inputLocation ? inputLocation : globalLocationDetected,
    searchRadius: inputSearchRadius,
    includeCommercial: inputIncludeCommercial,
    priceRange: { from: inputFrom, to: inputTo, error: undefined },
    sortOrder: inputSortOrder,
  });
  const lastNavigation = useRef(buildSearchParams(formData));

  const [what, setWhat] = useState(inputWhat);

  const [canSearch, setCanSearch] = useState(false);

  const profileService = useProfileServiceContext();

  useEffect(() => {
    if (formData.sortOrder !== inputSortOrder) {
      formData.sortOrder = inputSortOrder;
    }
  }, [inputSortOrder]);

  const handleCategoryChanged = (category) => {
    setFormData({ ...formData, category });
  };

  const clearSearch = () => {
    setWhat('');
    setFormData({
      what: '',
      category: '-1',
      location: globalLocationDetected,
      searchRadius: DEFAULT_RADIUS,
      includeCommercial: true,
      priceRange: { from: '', to: '', error: undefined },
      sortOrder: SORTFIELDS.mostRecent,
    });
  };

  const onRadiusChange = (searchRadius) => {
    setFormData({ ...formData, searchRadius });
  };

  const onPriceRangeChanged = (priceRange) => {
    setFormData({ ...formData, priceRange });
  };

  const handleWhatChanged = (what) => {
    setWhat(what);
    debounced.cancel();
    debounced(what);
  };

  const setWhatChanged = (what) => {
    setFormData((formData) => {
      return {
        ...formData,
        what,
      };
    });
  };

  const debounced = useCallback(
    debounce((what) => setWhatChanged(what), 250),
    [formData],
  );

  const handleLocationChanged = (location) => {
    setFormData({ ...formData, location });
  };

  useEffect(() => {
    setCanSearch(formData.priceRange.error === undefined);
  }, [formData.priceRange]);

  useEffect(() => {
    if (mountedRef.current === true) {
      /* istanbul ignore next */
      if (triggerAutoSearch && disableNextAutoSearchRef.current === false) {
        runSearchNow();
      }
      disableNextAutoSearchRef.current = false;
    }
  }, [formData]);

  const isOutdated = useCallback(() => {
    return (
      inputWhat !== formData.what ||
      inputCategory !== formData.category ||
      inputLocation !== formData.location ||
      inputSearchRadius !== formData.searchRadius ||
      inputIncludeCommercial !== formData.includeCommercial ||
      inputFrom !== formData.priceRange.from ||
      inputTo !== formData.priceRange.to ||
      inputSortOrder.id !== getDefaultSortOrder(location).id
    );
  }, [
    inputWhat,
    inputCategory,
    inputLocation,
    inputSearchRadius,
    inputIncludeCommercial,
    inputFrom,
    inputTo,
    inputSortOrder,
    location,
  ]);

  useEffect(() => {
    if (isOutdated()) {
      disableNextAutoSearchRef.current = true;
      setWhat(inputWhat);
      const newFormDate = {
        what: inputWhat,
        category: inputCategory,
        location: inputLocation ? inputLocation : globalLocationDetected,
        searchRadius: inputSearchRadius,
        includeCommercial: inputIncludeCommercial,
        priceRange: { from: inputFrom, to: inputTo, error: undefined },
        sortOrder: getDefaultSortOrder(location),
      };
      setFormData(newFormDate);
      onSearch({ what: inputWhat, ...newFormDate });
    }
  }, [isOutdated]);

  // fires once & sets "mountedRef" ref to "true"
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = undefined;
    };
  }, []);

  const runSearchNow = () => {
    if (lastNavigation.current !== buildSearchParams(formData)) {
      history.push({
        pathname: SEARCH_RESULTS,
        search: buildSearchParams(formData),
      });
      lastNavigation.current = buildSearchParams(formData);
    }
  };

  useEffect(() => {
    if (isOutdated() === false) {
      onSearch({ what, ...formData });
    }
  }, [location.search, isOutdated]);

  const updateIncludeCommercial = (value) => {
    /* istanbul ignore next */
    if (user) {
      profileService.setIncludeCommercial(user.uid, value);
    }
    setFormData({ ...formData, includeCommercial: value });
  };

  const handleCommercialChange = (e) => {
    updateIncludeCommercial(e.target.checked);
  };

  const shouldOpenAdvanced = () => {
    return (
      formData.priceRange.from ||
      formData.priceRange.to ||
      (formData.searchRadius && formData.searchRadius !== DEFAULT_RADIUS)
    );
  };

  return (
    <div className={classes.box}>
      <div className={classes.title}>
        <Typography color={'primary'} align={'left'} variant={large ? 'h4' : 'h5'}>
          {title}
        </Typography>
      </div>
      <div className={classes.what}>
        <TextField
          fullWidth
          id="what"
          variant={'outlined'}
          value={what}
          onKeyDown={(event) => {
            /* istanbul ignore next */
            if (event.key === 'Enter' && canSearch) {
              runSearchNow();
            }
          }}
          onChange={(event) => handleWhatChanged(event.target.value)}
        />
      </div>
      <div className={classes.in}>
        <Typography color={'primary'} align={'left'}>
          {t('component_searchbox_in')}
        </Typography>
      </div>
      <div className={classes.category}>
        <div className={classes.categoryContent}>
          <CategorySelector
            category={formData.category}
            onSelectCategory={(value) => handleCategoryChanged(value)}
            showAll={true}
          />
        </div>
      </div>
      <div className={classes.in}>
        <Typography color={'primary'} align={'left'}>
          {t('component_searchbox_where')}
        </Typography>
      </div>
      <div className={classes.where}>
        <LocationSelector location={formData.location} onSelectLocation={(value) => handleLocationChanged(value)} />
      </div>
      {showAdvanced && (
        <AdvancedSearch
          large={false}
          range={formData.searchRadius}
          priceRange={formData.priceRange}
          onRadiusChange={onRadiusChange}
          onPriceRangeChanged={onPriceRangeChanged}
          initiallyOpened={shouldOpenAdvanced()}
        />
      )}

      <FormControlLabel
        control={
          <Checkbox id={'includeCommercial'} checked={formData.includeCommercial} onChange={handleCommercialChange} />
        }
        label={t('component_searchbox_include_commercial')}
      />
      <div className={classes.buttons}>
        <div className={classes.button}>
          <Button
            disabled={canSearch === false}
            id={'search'}
            align={'right'}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              runSearchNow();
            }}
          >
            {t('component_searchbox_search')}
          </Button>
        </div>
        <div className={classes.button}>
          <Button
            id={'clear'}
            align={'right'}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={clearSearch}
          >
            {t('component_searchbox_clear')}
          </Button>
        </div>
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  large: PropTypes.bool,
  inputWhat: PropTypes.string,
  inputCategory: PropTypes.string,
  inputLocation: PropTypes.object,
  inputSearchRadius: PropTypes.number,
  inputSortOrder: PropTypes.object,
  showAdvanced: PropTypes.bool,
  triggerAutoSearch: PropTypes.bool,
  inputIncludeCommercial: PropTypes.bool,
};

SearchBox.defaultProps = {
  large: true,
  inputWhat: '',
  inputCategory: '-1',
  showAdvanced: false,
  triggerAutoSearch: true,
  inputIncludeCommercial: true,
  inputSearchRadius: DEFAULT_RADIUS,
  inputSortOrder: SORTFIELDS.mostRecent,
};

export default withRouter(SearchBox);
