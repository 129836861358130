import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { languageToLocale } from '../utils/locale';

const useStyles = makeStyles(() => ({
  selector: {
    paddingLeft: 20,
    paddingTop: 12,
    width: '100%',
    fontSize: 20,
  },
}));

const UnitSelector = ({ unit, onSelectUnit }) => {
  const classes = useStyles();
  const units = useSelector((state) => state.units.units);
  const locale = useSelector((state) => state.authentication.locale);

  return (
    <Select
      align={'left'}
      className={classes.selector}
      id="unit"
      value={unit}
      onChange={(event) => onSelectUnit(event.target.value)}
      displayEmpty
      disableUnderline
      autoWidth
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {units &&
        units
          .filter((c) => c !== undefined)
          .map((unit) => {
            /* istanbul ignore next */
            const { code } = unit;
            return (
              <MenuItem key={code} value={code} style={{ backgroundColor: 'white' }}>
                {unit[languageToLocale(locale)]}
              </MenuItem>
            );
          })}
    </Select>
  );
};

UnitSelector.propTypes = {
  unit: PropTypes.string,
  onSelectUnit: PropTypes.func.isRequired,
};

UnitSelector.defaultProps = {
  unit: 'day',
};

export default UnitSelector;
