import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoadingButton from '../loadingButton';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    paddingTop: 20,
    width: 150,
  },
  button: {
    width: '100%',
    height: '100%',
  },
}));

const UnlinkAccountButton = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleUnlinkAccount = () => {
    setLoading(true);
    onClick();
  };

  const renderUnlinkAccountButton = () => {
    return (
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          data-testid={'unlinkAccount'}
          variant="contained"
          color="primary"
          onClick={handleUnlinkAccount}
        >
          <LoadingButton loading={loading} content={t('unlink_account')} />
        </Button>
      </div>
    );
  };

  return renderUnlinkAccountButton();
};

UnlinkAccountButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UnlinkAccountButton;
