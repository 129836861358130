import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BOOKING_VIEW, DASHBOARD } from '../../routes';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import ContactInfo from '../../components/contactInfo';
import { useAdServiceContext } from '../../services/ServicesProvider';
import { withRouter } from 'react-router-dom';
import ResizableImageBox from '../../components/resizableImageBox';
import { useTheme } from '@material-ui/styles';
import { AdDescription } from '../../components/adDescription';
import { UserAndRentalDates } from '../../components/userAndDatesInfoBlock';
import ReviewCardWithoutImage from '../../components/reviewCardWithoutImage';
import BasePage from '../../components/basePage';
import RentalStatus from './status';
import BillingSection from '../../components/billingSection';
import { RENT_STATUS } from '../../models/rentStatus';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 25,
    paddingRight: 25,
    boxSizing: 'border-box',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imageAndCalendar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullFlex: {
    width: '100%',
    height: '100%',
  },
  button: {
    width: 150,
  },
}));

const Booking = ({ rentId, history }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [paymentInfoLoading, setPayloadLoadingInfoLoading] = useState(true);
  const [item, setItem] = useState(undefined);

  const ad = useSelector((state) => state.ads.currentAd);
  const renterUser = useSelector((state) => state.rent.user);
  const owner = useSelector((state) => state.rent.owner);

  const rental = useSelector((state) => state.rent.currentRentalSnapshot);
  const { loadAd, clearAd, registerRental, loadUser, loadOwner, unregisterCurrentRental } = useRematchDispatch(
    (dispatch) => ({
      loadAd: dispatch.ads.loadAd,
      clearAd: dispatch.ads.clearAd,
      registerRental: dispatch.rent.registerRental,
      unregisterCurrentRental: dispatch.rent.unregisterCurrentRental,
      loadUser: dispatch.rent.loadUser,
      loadOwner: dispatch.rent.loadOwner,
    }),
  );
  const adService = useAdServiceContext();

  useEffect(() => {
    registerRental({ id: rentId });

    return () => {
      unregisterCurrentRental();
    };
  }, [rentId, registerRental, unregisterCurrentRental]);

  useEffect(() => {
    function doLoadItem() {
      adService.markAsRead(rental);
      loadAd({ id: rental.ad });
      loadUser({ id: rental.user });
      loadOwner({ id: rental.owner });
    }

    if (rental) {
      if (rental.status === RENT_STATUS.CANCELLED_NO_FEES) {
        history.push(DASHBOARD);
      } else {
        doLoadItem();
      }
    }
  }, [rental, loadAd, loadUser, loadOwner, adService, history]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  useEffect(() => {
    if (ad !== undefined && owner !== undefined && renterUser !== undefined && rental !== undefined) {
      setItem({ ad, owner, user: renterUser, rent: rental });
      setLoading(false);
    }
  }, [ad, owner, renterUser, rental]);

  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  const getCalendarStyle = () => {
    return {
      width: '100%',
      height: '100%',
      paddingTop: 30,
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 30,
      paddingRight: /* istanbul ignore next */ isMobile ? 0 : 30,
      backgroundColor: '#f7f7f5',
      boxSizing: 'border-box',
    };
  };

  const onBillingSectionLoadingDone = () => {
    setPayloadLoadingInfoLoading(false);
  };

  const renderContent = () => {
    if (loading) {
      return <></>;
    }

    return (
      <>
        <AdDescription ad={ad} />
        <div data-testid={'pageloaded'} className={classes.imageAndCalendar}>
          <Grid container>
            <Grid item>
              <div className={classes.fullFlex}>
                <ResizableImageBox ad={ad} center={false} />
              </div>
            </Grid>
            <Grid item>
              <div style={getCalendarStyle()}>
                <ContactInfo user={renterUser} />
                <RentalStatus item={item} />
                <UserAndRentalDates rental={rental} />
                <BillingSection item={item} onLoadingDone={onBillingSectionLoadingDone} />

                <div style={{ display: 'flex', width: '100%', paddingTop: 30 }}>
                  <ReviewCardWithoutImage
                    displayName={'test'}
                    reviewComment={rental.review}
                    rating={rental.rating}
                    fullWidth={true}
                    showAvatar={false}
                    showLocation={false}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  return (
    <BasePage loading={loading && paymentInfoLoading} pagePath={BOOKING_VIEW}>
      {renderContent()}
    </BasePage>
  );
};

Booking.propTypes = {
  rentId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Booking);
