/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

const ResizableBox = ({ children, onResize, center, hasChildren, imageIsAvailable }) => {
  let [state, setState] = React.useState({ viewPort: null });
  let homeDiv = React.useRef(null);
  const [imageAvailable, setImageAvailable] = useState(false);

  useEffect(() => {
    const container = document.getElementById('mainContainer');
    const reportWindowSize = _.throttle(() => {
      if (imageAvailable) {
        if (container) {
          setState({ viewPort: { width: container.offsetWidth, height: container.offsetHeight } });
        } else {
          setState({ viewPort: { width: window.innerWidth * 0.8, height: window.innerHeight } });
        }
      } else {
        setState({ viewPort: { width: 0, height: 0 } });
      }
    }, 100);
    if (hasChildren) {
      reportWindowSize();
      window.addEventListener('resize', reportWindowSize);
    }
    return () => {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, [hasChildren, imageAvailable]);

  useEffect(() => {
    if (hasChildren && state.viewPort) {
      let n = state.viewPort.width * 0.75;
      homeDiv.current.style.height = `${n}px`;
      homeDiv.current.style.width = `${n}px`;
      /* istanbul ignore next */
      if (center) {
        /* istanbul ignore next */
        const leftPosition = (state.viewPort.width - n) / 2;
        /* istanbul ignore next */
        homeDiv.current.style.left = `${leftPosition}px`;
      }
      /* istanbul ignore next */
      if (onResize) {
        onResize(n);
      }
    }
  });

  useEffect(() => {
    setImageAvailable(hasChildren && imageIsAvailable);
  }, [hasChildren, imageIsAvailable]);

  if (hasChildren) {
    return (
      <div
        ref={homeDiv}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          top: '0',
          left: '0',
        }}
      >
        {children}
      </div>
    );
  }
  return <></>;
};

ResizableBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onResize: PropTypes.func,
  center: PropTypes.bool,
  hasChildren: PropTypes.bool.isRequired,
  imageIsAvailable: PropTypes.bool,
};

ResizableBox.defaultProps = {
  center: true,
};

export { ResizableBox };
