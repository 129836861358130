import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MessageItem from './messageItem';
import { useTranslation } from 'react-i18next';

const Threads = ({ items, onItemSelected }) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(undefined);

  const selectItem = (message) => {
    if (message.uid === selectedItem) {
      setSelectedItem(undefined);
      onItemSelected(undefined);
    } else {
      setSelectedItem(message.uid);
      onItemSelected(message);
    }
  };

  return (
    <>
      {(items === undefined || items.length === 0) && (
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('none')}
        </Typography>
      )}
      {items && items.length !== 0 && (
        <div>
          {items.map((message, index) => (
            <MessageItem
              key={message.uid}
              message={message}
              isLast={index === items.length - 1}
              isSelected={selectedItem === message.uid}
              onSelect={() => selectItem(message)}
            />
          ))}
        </div>
      )}
    </>
  );
};

Threads.propTypes = {
  items: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};

export default withRouter(Threads);
