import React, { useEffect, useState } from 'react';
import toolbox from '../../assets/tools-transparent.png';
import { Container, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NewItems from './newItems';
import RecentlyRented from './recentlyRented';
import Header from '../../components/header';
import SearchBox from '../../components/searchBox';
import { useProfileServiceContext } from '../../services/ServicesProvider';
import PropTypes from 'prop-types';
import ContactUs from '../../components/contactUs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/loading';
import Grid from '@material-ui/core/Grid';
import Onboarding from './onboarding';
import { useHistory, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import { globalLocationDetected } from '../../components/locationDetector';
import LocationAwarePage from '../../components/locationAwarePage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import RentingOffer from './renting';

const useStyles = makeStyles(() => ({
  infoTextBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#505050',
    paddingLeft: 20,
    color: 'white',
  },
  infoTextBoxContent: {
    display: 'block',
    paddingLeft: 14,
    paddingTop: 20,
    paddingBottom: 20,
  },
  searchbox: {
    backgroundColor: 'white',
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    height: '100%',
  },
  background: {
    background: `linear-gradient(rgba(255,255,255,.75), rgba(255,255,255,.5)), url(${toolbox})`,
  },
  page: {
    backgroundColor: '#f2f2f2',
  },
  newAndRecentContainer: {
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 8,
  },
  gridContainer: {
    display: 'flex',
  },
}));

const MainScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const profileService = useProfileServiceContext();

  const user = useSelector((state) => state.user.user);
  const authUser = useSelector((state) => state.authentication.user);

  const locations = useSelector((state) => state.locations.locations);

  const [loading, setLoading] = useState(true);
  const [locationDetected, setLocationDetected] = useState(false);

  const onLocationReady = () => {
    setLocationDetected(true);
  };

  const sendQuestion = ({ email, question }) => {
    profileService.contactUs({ from: email, message: question });
    profileService.contactUs({ from: email, message: question });
  };

  const theme = useTheme();
  let largeScreen = true;
  /* istanbul ignore next */
  if (theme) {
    /* istanbul ignore next */
    largeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  }

  useEffect(() => {
    if (authUser) {
      /* istanbul ignore next */
      if (user !== undefined && locations !== undefined && locationDetected) {
        setLoading(false);
      }
    } else {
      /* istanbul ignore next */
      if (locations !== undefined && locationDetected) {
        setLoading(false);
      }
    }
  }, [authUser, user, locations, locationDetected]);

  const shouldShowOnboarding = () => {
    if (user) {
      return user.showOnboarding === true || user.showOnboarding === undefined;
    }
    const cookies = new Cookies();
    const cookie = cookies.get('louetoutca.showonboarding');
    return cookie === true || cookie === undefined;
  };

  const setShowOnboarding = (showOnboarding) => {
    /* istanbul ignore next */
    if (user) {
      profileService.setShowOnboarding(user.uid, showOnboarding);
    }
    const cookies = new Cookies();
    cookies.set('louetoutca.showonboarding', showOnboarding, { path: '/' });
  };

  const renderGridContainerContent = () => {
    return (
      <>
        <div className={classes.searchbox}>
          <SearchBox
            title={t('home_searchbox_title')}
            onSearch={() => {}}
            inputLocation={globalLocationDetected}
            triggerAutoSearch={false}
          />
        </div>
        <div className={classes.newAndRecentContainer}>
          <NewItems />
          <RecentlyRented />
        </div>
      </>
    );
  };

  const renderGridContainer = () => {
    /* istanbul ignore next */
    if (largeScreen) {
      return (
        <>
          <div className={classes.gridContainer}>{renderGridContainerContent()}</div>
        </>
      );
    }
    /* istanbul ignore next */
    return (
      <>
        <Grid container direction="column" alignItems="center">
          <Grid item>{renderGridContainerContent()}</Grid>
        </Grid>
      </>
    );
  };

  const renderContent = () => {
    if (loading === true) {
      return <></>;
    }
    return (
      <ProfileCheck>
        <div className={classes.page}>
          <header className="App-header">
            <Header />
            <div className={classes.background}>
              <Container maxWidth={'xl'}>{renderGridContainer()}</Container>
              <RentingOffer />
            </div>
            <div className={classes.infoTextBox}>
              <div className={classes.infoTextBoxContent}>
                <ContactUs onContact={sendQuestion} userEmail={/* istanbul ignore next */ user ? user.email : ''} />
                <Typography color={'inherit'} align={'left'} variant={'subtitle2'}>
                  (c) 2020-2023 louetoutca
                </Typography>
              </div>
            </div>
          </header>
        </div>
        <Onboarding initiallyOpened={shouldShowOnboarding()} onShowNextTime={setShowOnboarding} />
      </ProfileCheck>
    );
  };

  return (
    <LocationAwarePage onReady={onLocationReady}>
      <Loading isLoading={loading}>{renderContent()}</Loading>
    </LocationAwarePage>
  );
};

MainScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MainScreen);
