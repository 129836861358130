/* istanbul ignore file */
import { init } from '@rematch/core';
import * as models from './index';
import createRematchPersist from '@rematch/persist';

const persistPlugin = createRematchPersist({
  whitelist: ['authentication'],
  throttle: 1,
  version: 1,
});

const store = init({
  plugins: [persistPlugin],
  models,
});

export { store };
