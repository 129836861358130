import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LinkAccountButton from './linkAccountButton';
import UseCreditsButton from './useCreditsButton';
import { CREDITS, LINK } from '../../payoutModes';

const useStyles = makeStyles(() => ({
  payoutMode: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  noLink: {
    height: 57,
  },
}));

const AccountNotConfigured = ({ onLinkAccount }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const [payoutMode, setPayoutMode] = useState(user.payoutMode);

  const handleLinkAccount = () => {
    setPayoutMode(LINK);
    onLinkAccount({ payoutMode: LINK });
  };

  const handleUseCredit = () => {
    setPayoutMode(CREDITS);
    onLinkAccount({ payoutMode: CREDITS });
  };

  return (
    <div>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('account_not_linked')}
      </Typography>

      <div className={classes.payoutMode}>
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('payout_modes')}
        </Typography>
      </div>

      <LinkAccountButton onClick={handleLinkAccount} payoutMode={payoutMode} />
      <UseCreditsButton onClick={handleUseCredit} payoutMode={payoutMode} />
    </div>
  );
};

AccountNotConfigured.propTypes = {
  onLinkAccount: PropTypes.func.isRequired,
};

export default AccountNotConfigured;
