import Icon from '@material-ui/core/Icon';
import React from 'react';

const renderStars = (rating) => {
  let stars = [];

  for (let i = 0; i < Math.round(Math.max(1, Math.min(5, rating))); i++) {
    stars.push(
      <Icon key={`star-${i}`} color="secondary">
        star
      </Icon>,
    );
  }

  if (rating - Math.round(rating) > 0) {
    stars.push(
      <Icon key={`halfstar`} color="secondary">
        star_half
      </Icon>,
    );
  }

  if (stars.length === 0) {
    stars.push(<Icon key={`halfstar`} color="secondary" />);
  }

  return <div style={{ display: 'flex', width: '100%' }}>{stars}</div>;
};

export { renderStars };
