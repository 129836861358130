import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const AdServiceContext = React.createContext({});
const SearchServiceContext = React.createContext({});
const ProfileServiceContext = React.createContext({});
const MessageServiceContext = React.createContext({});

const ServicesProvider = ({ adService, searchService, profileService, messageService, children }) => {
  return (
    <AdServiceContext.Provider value={adService}>
      <ProfileServiceContext.Provider value={profileService}>
        <MessageServiceContext.Provider value={messageService}>
          <SearchServiceContext.Provider value={searchService}>{children}</SearchServiceContext.Provider>
        </MessageServiceContext.Provider>
      </ProfileServiceContext.Provider>
    </AdServiceContext.Provider>
  );
};

ServicesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  adService: PropTypes.object.isRequired,
  searchService: PropTypes.object.isRequired,
  profileService: PropTypes.object.isRequired,
  messageService: PropTypes.object.isRequired,
};

const useAdServiceContext = () => useContext(AdServiceContext);
const useSearchServiceContext = () => useContext(SearchServiceContext);
const useProfileServiceContext = () => useContext(ProfileServiceContext);
const useMessageServiceContext = () => useContext(MessageServiceContext);

export {
  ServicesProvider,
  useAdServiceContext,
  useSearchServiceContext,
  useProfileServiceContext,
  useMessageServiceContext,
};
