import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { selectedButtonStyle } from './buttonStyles';
import { Badge, withStyles } from '@material-ui/core';

const HeaderToggleButton = ({ onClick, title, extraStyle, badgeCount }) => {
  const [buttonOver, setButtonOver] = useState(false);

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -7,
      top: 5,
      border: `1px solid ${theme.palette.background.paper}`,
    },
  }))(Badge);

  return (
    <button
      onMouseEnter={/* istanbul ignore next */ () => setButtonOver(true)}
      onMouseLeave={/* istanbul ignore next */ () => setButtonOver(false)}
      style={selectedButtonStyle(buttonOver, extraStyle)}
      onClick={onClick}
    >
      <StyledBadge
        color="error"
        badgeContent={badgeCount}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {title}
      </StyledBadge>
    </button>
  );
};

HeaderToggleButton.propTypes = {
  title: PropTypes.string.isRequired,
  extraStyle: PropTypes.object,
  badgeCount: PropTypes.number,
};

export { HeaderToggleButton };
