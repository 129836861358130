/* istanbul ignore file */
import firebase from 'firebase/compat';
import 'firebase/compat/auth';

const getUiConfig = (onLogin) => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        onLogin({ user: authResult });
        return false;
      },
    },
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
  };
};

export default getUiConfig;
