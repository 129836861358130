/* istanbul ignore file */
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3b3b39',
    },
    secondary: {
      main: '#395f4b',
    },
    error: {
      main: '#8b0000',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },
  typography: {
    fontFamily: ['Oswald'].join(','),
  },
  overrides: {
    MuiBox: {
      root: {
        padding: '0px',
      },
    },
  },
});

export default theme;
