import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAdServiceContext } from '../services/ServicesProvider';
import { RENT_STATUS } from '../models/rentStatus';
import OwnerBillingInformation from './ownerBillingInformation';
import RentBillingInformation from './rentBillingInformation';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BillInformationLine from './billInformationLine';

const BillingSection = ({ item, onLoadingDone, showCancellationFees }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [ownerPayout, setOwnerPayout] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [cancellationPaymentInfo, setCancellationPaymentInfo] = useState();

  const currentUser = useSelector((state) => state.user.user);
  const adService = useAdServiceContext();

  const isOwner = useCallback(() => {
    return item !== undefined && currentUser !== undefined && item.rent.owner === currentUser.uid;
  }, [item, currentUser]);

  useEffect(() => {
    /* istanbul ignore next */
    if (item !== undefined) {
      if (isOwner() && ownerPayout !== undefined) {
        setLoading(false);
        onLoadingDone();
      }

      if (!isOwner() && paymentInfo !== undefined) {
        setLoading(false);
        onLoadingDone();
      }
    }
  }, [item, ownerPayout, paymentInfo, isOwner, onLoadingDone]);

  useEffect(() => {
    async function doComputePayout(rentId) {
      if (isOwner()) {
        const ownerPayout = await adService.getOwnerPayout(rentId);
        setOwnerPayout(ownerPayout);
      } else {
        const paymentInfo = await adService.getPaymentInformation(rentId);
        const { uid, cancelledBy } = item.rent;
        const reimbursementInfo = await adService.getReimbursementInformation({ rentId: uid, callerId: cancelledBy });
        setCancellationPaymentInfo(reimbursementInfo);
        setPaymentInfo(paymentInfo);
      }
    }

    /* istanbul ignore next */
    if (item.rent) {
      doComputePayout(item.rent.uid);
    }
  }, [adService, isOwner, item]);

  const showReimburseSecurityDeposit = () => {
    const status = item.rent.status;
    return (
      status === RENT_STATUS.COMPLETED ||
      status === RENT_STATUS.DISPUTE_OPENED ||
      status === RENT_STATUS.CHARGE_ACCEPTED
    );
  };

  const renderBillingSection = () => {
    if (isOwner()) {
      if (item.rent.status === RENT_STATUS.DISPUTE_OPENED) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: 30 }}>
            <BillInformationLine key={`charge`} price={item.rent.charge} title={t('dispute_charge')} />
          </div>
        );
      }

      return (
        <OwnerBillingInformation
          ownerPayout={ownerPayout}
          isCancelled={showCancellationFees || item.rent.status === RENT_STATUS.CANCELLED}
          cancelledRefundAmount={item.rent.ownerPayout}
        />
      );
    }

    return (
      <RentBillingInformation
        paymentInfo={paymentInfo}
        showReimburseSecurityDeposit={showReimburseSecurityDeposit()}
        showCancellationFeesAndTotal={showCancellationFees || item.rent.status === RENT_STATUS.CANCELLED}
        cancellationPaymentInfo={cancellationPaymentInfo}
        charge={item.rent.charge}
      />
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 356,
            height: 200,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
          <Typography align={'left'} color={'primary'} variant={'body1'} noWrap={true}>
            {t('processing')}
          </Typography>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: 30 }}>
        {renderBillingSection()}
      </div>
    );
  };

  return <>{renderContent()}</>;
};

BillingSection.propTypes = {
  onLoadingDone: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  showCancellationFees: PropTypes.bool,
};

export default BillingSection;
