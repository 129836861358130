import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SecuredRoute from '../../routes/SecuredPage';
import { PROFILE_VIEW, RENT } from '../../routes';
import Header from '../../components/header';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import OwnerContactInfo from '../../components/ownerContactInfo';
import { withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../../utils/location';
import { languageToLocale } from '../../utils/locale';
import Loading from '../../components/loading';
import ProfileCheck from '../../routes/profileIncompleteRedirect';
import { renderMap } from '../../utils/map';
import ListReviews from '../../components/listReviews';
import GridReviews from '../../components/gridReviews';
import { useSearchServiceContext } from '../../services/ServicesProvider';
import SearchResultsList from '../../components/searchResultsList';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minWidth: 388,
  },
  mainContainer: {
    display: 'flex',
    width: '80%',
    height: '80%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  adTitle: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  imageAndCalendar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  calendar: {
    width: '100%',
    height: '100%',
    padding: 10,
    boxSizing: 'border-box',
    backgroundColor: '#f7f7f5',
  },
  bottomContainer: {
    width: '100%',
    height: '100%',
    paddingTop: 20,
  },
  reviews: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  expandReviews: {
    paddingBottom: 10,
  },
  errorMessage: {
    height: 30,
    fontFamily: 'Oswald bold',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  otherAds: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingTop: 10,
    paddingBottom: 20,
  },
}));

const ProfileView = ({ userId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locations = useSelector((state) => state.locations.locations);
  const user = useSelector((state) => state.profile.user);
  const userReviews = useSelector((state) => state.profile.userReviews);
  const searchService = useSearchServiceContext();
  const [userAds, setUserAds] = useState([]);

  const { loadUser, loadUserReviews, unregisterLoadUserReviews } = useRematchDispatch((dispatch) => ({
    loadUser: dispatch.profile.loadUser,
    loadUserReviews: dispatch.profile.loadUserReviews,
    unregisterLoadUserReviews: dispatch.profile.unregisterLoadUserReviews,
  }));
  const locale = useSelector((state) => state.authentication.locale);

  const [loading, setLoading] = useState(true);
  const [listMode, setListMode] = useState(false);

  useEffect(() => {
    /* istanbul ignore next */
    if (userId) {
      loadUser({ uid: userId });
      loadUserReviews({ uid: userId, maxResults: 25 });
    }

    return () => {
      unregisterLoadUserReviews();
    };
  }, [userId, loadUser, loadUserReviews, unregisterLoadUserReviews]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    /* istanbul ignore next */
    if (user) {
      searchService.getUserAds(user.uid).then((results) => {
        setUserAds(results);
        setLoading(false);
      });
    }
  }, [user, searchService]);

  const toggleMode = () => {
    setListMode(!listMode);
  };

  const renderContainer = () => {
    if (loading) {
      return <></>;
    }
    return (
      <SecuredRoute path={PROFILE_VIEW}>
        <ProfileCheck>
          <div className={classes.imageAndCalendar}>
            <div className={classes.calendar}>
              <OwnerContactInfo showUserName={true} showAskQuestion={false} owner={user} />

              <div style={{ display: 'flex', width: '100%' }}>
                <Typography align={'left'} color={'secondary'} variant={'h5'} noWrap={true}>
                  {locationFor(locations, user.location).name[languageToLocale(locale)]}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.otherAds}>
              <div style={{ paddingBottom: 10 }}>
                <Typography align={'left'} color={'secondary'} variant={'h5'} noWrap={true}>
                  {t('profile_other_ads')}
                </Typography>
              </div>
              <SearchResultsList resultsInPage={userAds} />
            </div>
            <div className={classes.expandReviews}>
              <Typography align={'left'} color={'secondary'} variant={'body1'} noWrap={true}>
                <Link data-testid={'expand-reviews'} onClick={toggleMode}>
                  {listMode && t('rent_less_reviews')}
                  {!listMode && t('rent_all_reviews')}
                </Link>
              </Typography>
            </div>
            {listMode && <ListReviews reviews={userReviews} />}
            {!listMode && <GridReviews reviews={userReviews} />}
          </div>
          <div style={{ display: 'flex', paddingTop: 20, paddingBottom: 20 }}>
            {renderMap(user, locations, undefined)}
          </div>
        </ProfileCheck>
      </SecuredRoute>
    );
  };

  return (
    <SecuredRoute path={RENT}>
      <div className={classes.page}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.content}>
          <div className={classes.mainContainer}>
            <Loading isLoading={loading}>{renderContainer()}</Loading>
          </div>
        </div>
      </div>
    </SecuredRoute>
  );
};

ProfileView.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default withRouter(ProfileView);
