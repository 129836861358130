import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles(() => ({
  main: {
    cursor: 'pointer',
    color: 'white',
    paddingTop: 4,
  },
  contactBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  oneBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    cursor: 'pointer',
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
  },
}));

const ContactUs = ({ userEmail, onContact }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [question, setQuestion] = useState('');
  const [email, setEmail] = useState(userEmail ? userEmail : '');

  const openDialog = () => {
    setOpenQuestionDialog(true);
  };

  const handleClose = () => {
    setOpenQuestionDialog(false);
  };

  const handleSend = () => {
    onContact({ email, question });
    setOpenQuestionDialog(false);
  };

  const goToFacebook = () => {
    window.location.assign('https://facebook.com/louetoutca');
  };

  const goToInstagram = () => {
    window.location.assign('https://www.instagram.com/louetoutca/');
  };

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    } else {
      setEmail('');
    }
  }, [userEmail, setEmail]);

  return (
    <>
      <Typography color={'white'} align={'left'} variant={'h6'}>
        {t('component_contact_us')}
      </Typography>
      <div className={classes.contactBox}>
        <div className={classes.oneBox}>
          <EmailIcon className={classes.icon} onClick={openDialog} />
          <Link className={classes.main} data-testid={'contact-link'} href="#" onClick={openDialog}>
            {t('preferences_notifications_email')}
          </Link>
        </div>
        <div className={classes.oneBox}>
          <FacebookIcon className={classes.icon} onClick={goToFacebook} />
          <Link className={classes.main} data-testid={'contact-facebook'} href="#" onClick={goToFacebook}>
            Facebook
          </Link>
        </div>
        <div className={classes.oneBox}>
          <InstagramIcon className={classes.icon} onClick={goToFacebook} />
          <Link className={classes.main} data-testid={'contact-instagram'} href="#" onClick={goToInstagram}>
            Instagram
          </Link>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        data-testid={'contact-us'}
        open={openQuestionDialog}
        onClose={handleClose}
      >
        <DialogTitle>{t('component_contact_us')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('component_contact_us_your_email')}</DialogContentText>
          <TextField
            inputProps={{ 'data-testid': 'email' }}
            autoFocus
            margin="dense"
            id="email"
            fullWidth
            multiline={false}
            value={email}
            rows={1}
            onChange={(event) => setEmail(event.target.value)}
          />
          <DialogContentText>{t('component_contact_us_your_question')}</DialogContentText>
          <TextField
            inputProps={{ 'data-testid': 'question' }}
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            multiline={true}
            rows={8}
            onChange={(event) => setQuestion(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={question === '' || email === ''}
            data-testid={'contact-send'}
            onClick={handleSend}
            color="primary"
          >
            {t('action_send')}
          </Button>
          <Button data-testid={'contact-cancel'} onClick={handleClose} color="primary">
            {t('action_cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ContactUs.propTypes = {
  onContact: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
};

export default ContactUs;
