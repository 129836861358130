import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SORTING from '../sortFields';
import sortByFieldShape from '../shapes/sortByFieldShape';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  selector: {
    display: 'flex',
    width: '100%',
    justifyContent: 'left',
  },
}));

const sortingOrderList = [SORTING.mostRecent, SORTING.oldest, SORTING.lowestPrice, SORTING.mostExpensive];

const SortBySelector = ({ onSelectSortOrder, sortBy }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography color={'primary'} align={'left'} variant={'caption'}>
        {t('component_sort_by')}
      </Typography>
      <Select
        align={'left'}
        className={classes.selector}
        id="sortBy"
        value={sortBy}
        onChange={(event) => onSelectSortOrder(event.target.value)}
      >
        {sortingOrderList.map((sortingOrder) => {
          const { id, name } = sortingOrder;
          return (
            <MenuItem key={id} value={sortingOrder} style={{ backgroundColor: 'white' }}>
              {t(`component_sort_by_${name}`)}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

SortBySelector.propTypes = {
  sortBy: sortByFieldShape.isRequired,
  onSelectSortOrder: PropTypes.func.isRequired,
};

export default SortBySelector;
