import { createModel } from '@rematch/core';
import COLLECTIONS from '../collections';
import { firebaseService } from '../services/firebaseServiceInstance';

const DEFAULT_STATE = {
  lastRentals: [],
  newItems: [],
};

export const createHomeModel = () =>
  createModel({
    state: { ...DEFAULT_STATE },

    reducers: {
      updateLastRentals(state, lastRentals) {
        return { ...state, lastRentals };
      },
      updateNewItems(state, newItems) {
        return { ...state, newItems };
      },
      clearState() {
        return { ...DEFAULT_STATE };
      },
    },

    effects: (dispatch) => ({
      async loadLastRentals(userId) {
        let lastRentals = [];
        const loadedItems = await firebaseService.queryDocumentsWithOperator(
          COLLECTIONS.globalStats,
          undefined,
          { field: 'date', defaultOrder: 'desc' },
          50,
        );

        loadedItems.forEach((i) => {
          /* istanbul ignore next */
          if (i.user !== userId && lastRentals.length < 6) {
            lastRentals.push(i);
          }
        });

        dispatch.home.updateLastRentals(lastRentals);
      },

      async loadNewItems(userId) {
        // TODO reuse
        let newItems = [];
        const loadedItems = await firebaseService.queryDocumentsWithOperator(
          COLLECTIONS.ads,
          undefined,
          { field: 'createdDate', defaultOrder: 'desc' },
          50,
        );

        loadedItems.forEach((i) => {
          /* istanbul ignore next */
          if (i.user !== userId && newItems.length < 6) {
            newItems.push(i);
          }
        });

        dispatch.home.updateNewItems(newItems);
      },

      async reset() {
        dispatch.home.clearState();
      },
    }),
  });
