export const RENT_STATUS = {
  UNCONFIRMED: 'UNCONFIRMED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  APPROVED: 'APPROVED',
  IN_PROGRESS: 'IN_PROGRESS',
  DECLINED: 'DECLINED',
  COMPLETED: 'COMPLETED',
  DISPUTE_OPENED: 'DISPUTE_OPENED',
  CANCELLED: 'CANCELLED',
  PENDING_OWNER_REVIEW: 'PENDING_OWNER_REVIEW',
  CHARGE_ACCEPTED: 'CHARGE_ACCEPTED',
  CANCELLED_NO_FEES: 'CANCELLED_NO_FEES',
};

export const values = () => [
  RENT_STATUS.UNCONFIRMED,
  RENT_STATUS.APPROVED,
  RENT_STATUS.IN_PROGRESS,
  RENT_STATUS.PENDING_APPROVAL,
  RENT_STATUS.DECLINED,
  RENT_STATUS.PENDING_PAYMENT,
  RENT_STATUS.COMPLETED,
  RENT_STATUS.DISPUTE_OPENED,
  RENT_STATUS.CANCELLED,
  RENT_STATUS.PENDING_OWNER_REVIEW,
  RENT_STATUS.CHARGE_ACCEPTED,
  RENT_STATUS.CANCELLED_NO_FEES,
];
