import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { renderStars } from '../../utils/ratings';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ListLastReviews from '../../components/listLastReviews';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 20,
    width: '100%',
    height: '100%',
  },
  leftPanel: {
    width: '100%',
    height: '100%',
  },
  reviews: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const AllReviews = ({ adId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { loadLastRentals } = useRematchDispatch((dispatch) => ({
    loadLastRentals: dispatch.rent.loadLastRentals,
  }));

  const lastRentals = useSelector((state) => state.rent.lastRentals);

  useEffect(() => {
    loadLastRentals({ adId, max: 25 });
  }, [loadLastRentals, adId]);

  const computeRating = () => {
    let average = 0;
    if (!lastRentals || !lastRentals[adId] || lastRentals[adId].length === 0) {
      return average;
    }
    const rentals = lastRentals[adId];
    rentals.map((r) => {
      average = average + r.rating;
      return undefined;
    });
    return average / rentals.length;
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftPanel}>
        <Typography color={'primary'} align={'left'} variant={'h6'}>
          {t('reviews_title')}
        </Typography>
        {renderStars(computeRating())}
        <Typography color={'secondary'} align={'left'} variant={'caption'}>
          {`${lastRentals && lastRentals[adId] ? lastRentals[adId].length : 0} ${t('reviews_title')}`}
        </Typography>
      </div>
      <div className={classes.reviews}>
        <ListLastReviews adId={adId} maxResults={25} />
      </div>
    </div>
  );
};

AllReviews.propTypes = {
  adId: PropTypes.string.isRequired,
};

export default AllReviews;
