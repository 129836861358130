const hasImages = (ad) => {
  return findLastImageIndex(ad) > 0;
};

const findLastImageIndex = (ad) => {
  /* istanbul ignore next */
  if (ad !== undefined && ad.images !== undefined) {
    const index = ad.images.findIndex((element) => element === '');
    if (index === -1) {
      return ad.images.length;
    }
    return index;
  }
  /* istanbul ignore next */
  return -1;
};

export { findLastImageIndex, hasImages };
