import COLLECTIONS from '../collections';

class ProfileService {
  constructor(firebaseService) {
    this.firebaseService = firebaseService;
  }

  async saveProfile(id, { generalProfile, preferences }) {
    const { fullName, email, phone, street, location, state, country, zipcode, language, coords } = generalProfile;
    const { radius, notifications } = preferences;

    let payload = {
      displayName: fullName,
      email,
      phoneNumber: phone,
      street,
      location,
      state,
      country,
      zipcode,
      language,
      searchRadius: radius,
      coords,
      notifications,
    };

    Object.keys(payload).forEach((key) => {
      if (payload[key] === undefined) {
        delete payload[key];
      }
    });

    await this.firebaseService.writeToCollection(COLLECTIONS.users, id, payload);
  }

  // TODO move elsewhere
  async contactUs({ from, message }) {
    await this.firebaseService.callUnsecuredFunction('contactus', { from, message });
  }

  uploadProfileImage(id, image) {
    this.firebaseService.uploadToStorage(image, (photoURL) => {
      this.firebaseService.writeToCollection(COLLECTIONS.users, id, {
        photoURL,
      });
    });
  }

  async linkAccount(id) {
    const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://www.loue-tout.ca';
    const response = await this.firebaseService.callFunction('createaccount', { id, url });
    return response.url;
  }

  async useCreditForAccount(id) {
    await this.firebaseService.callFunction('usecredits', { id });
  }

  async useBankAccount(id) {
    await this.firebaseService.callFunction('usebankaccount', { id });
  }

  async unlinkAccountId(id) {
    await this.firebaseService.callFunction('unlinkbankaccount', { id });
  }

  async confirmAccount(userId, accountId) {
    await this.firebaseService.callFunction('validateaccount', { userId, accountId });
  }

  async setShowOnboarding(userId, showOnboarding) {
    this.firebaseService.writeToCollection(COLLECTIONS.users, userId, {
      showOnboarding,
    });
  }

  async setIncludeCommercial(userId, includeCommercial) {
    this.firebaseService.writeToCollection(COLLECTIONS.users, userId, {
      includeCommercial,
    });
  }

  async geoLocate() {
    return this.firebaseService.callUnsecuredFunction('geolocate');
  }
}

export default ProfileService;
