import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { renderStars } from '../utils/ratings';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import notavailable from '../assets/noimageavailable.png';
import { withRouter } from 'react-router-dom';
import { PROFILE_VIEW } from '../routes';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  askOwnerWithAvatar: {
    display: 'flex',
    width: '100%',
    height: 100,
    flexDirection: 'row',
  },
  avatar: {
    width: 100,
    height: 100,
    cursor: 'pointer',
  },
  starsAndContact: {
    paddingLeft: 30,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const OwnerContactInfo = ({ opened, owner, onSendQuestion, onNeedLogin, showUserName, showAskQuestion, history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.user.user);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(opened);
  const [question, setQuestion] = useState('');

  const openDialog = () => {
    if (currentUser) {
      setOpenQuestionDialog(true);
    } else {
      onNeedLogin();
    }
  };

  const sendQuestion = () => {
    onSendQuestion(question);
    setQuestion('');
    setOpenQuestionDialog(false);
  };

  const handleClose = () => {
    setOpenQuestionDialog(false);
  };

  const goToOwnerProfile = () => {
    history.push(PROFILE_VIEW.replace(':userId', owner.uid));
  };

  return (
    <div data-testid={'owner-info'} className={classes.askOwnerWithAvatar}>
      <Avatar
        data-testid={'owner-avatar'}
        className={classes.avatar}
        src={owner && owner.photoURL ? owner.photoURL : notavailable}
        onClick={goToOwnerProfile}
      />
      <div className={classes.starsAndContact}>
        {showUserName && (
          <Typography align={'left'} color={'secondary'} variant={'h5'} noWrap={true}>
            {owner.displayName}
          </Typography>
        )}
        {renderStars(5)}
        {showAskQuestion && (
          <Typography align={'left'} color={'primary'} variant={'h4'} noWrap={true}>
            <Link data-testid={'ask-seller'} href="#" onClick={openDialog}>
              {t('rent_ask_question')}
            </Link>
          </Typography>
        )}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        data-testid={'ask-question-dialog'}
        open={openQuestionDialog}
        onClose={handleClose}
      >
        <DialogTitle>{t('rent_ask_seller_question')}</DialogTitle>
        <DialogContent>
          <TextField
            inputProps={{ 'data-testid': 'question' }}
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            multiline={true}
            rows={3}
            onChange={(event) => setQuestion(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={question === ''} data-testid={'ask-dialog-send'} onClick={sendQuestion} color="primary">
            {t('action_send')}
          </Button>
          <Button data-testid={'ask-dialog-cancel'} onClick={handleClose} color="primary">
            {t('action_cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

OwnerContactInfo.propTypes = {
  opened: PropTypes.bool,
  onSendQuestion: PropTypes.func.isRequired,
  onNeedLogin: PropTypes.func,
  owner: PropTypes.object.isRequired,
  showUserName: PropTypes.bool,
  showAskQuestion: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

OwnerContactInfo.defaultProps = {
  opened: false,
  showUserName: false,
  showAskQuestion: true,
};

export default withRouter(OwnerContactInfo);
