import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputNumericField from './InputNumericField';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 30,
  },
}));

const PriceRange = ({ priceRange, onPriceRangeChanged }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const checkForError = (from, to) => {
    if (to !== '' && parseInt(to, 10) <= parseInt(from, 10)) {
      return t('price_error_must_higher_than_from', { minPrice: from });
    }
    return undefined;
  };

  const fromChanged = (from) => {
    const error = checkForError(from, priceRange.to);
    onPriceRangeChanged({ from, to: priceRange.to, error });
  };

  const toChanged = (to) => {
    const error = checkForError(priceRange.from, to);
    onPriceRangeChanged({ from: priceRange.from, to, error });
  };

  return (
    <>
      <div className={classes.container}>
        <InputNumericField
          value={priceRange.from}
          title={t('price_range_from')}
          onChange={fromChanged}
          large={false}
          testid={'price_from'}
        />
        <InputNumericField
          value={priceRange.to}
          title={t('price_range_to')}
          onChange={toChanged}
          large={false}
          testid={'price_to'}
        />
        {priceRange.error && (
          <Typography color={'error'} align={'left'} variant={'caption'}>
            {priceRange.error}
          </Typography>
        )}
      </div>
    </>
  );
};

PriceRange.propTypes = {
  priceRange: PropTypes.object,
  onPriceRangeChanged: PropTypes.func.isRequired,
};

PriceRange.defaultProps = {
  priceRange: {
    from: '',
    to: '',
    error: undefined,
  },
};

export default PriceRange;
