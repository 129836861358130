/* istanbul ignore file */
const fitTextForDisplay = (text, maxWidth, font) => {
  const words = text.split(' ');
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  let output = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    context.font = font;
    let metrics = context.measureText(word);
    if (metrics.width > maxWidth) {
      let fullWordProcessed = false;
      let remainingWord = word;
      while (fullWordProcessed === false) {
        let newWord = remainingWord;
        while (metrics.width > maxWidth) {
          newWord = newWord.slice(0, newWord.length - 1);
          metrics = context.measureText(newWord);
        }
        output = `${output} ${newWord}`;
        remainingWord = remainingWord.slice(newWord.length);

        metrics = context.measureText(remainingWord);
        fullWordProcessed = metrics.width <= maxWidth;
      }
      output = `${output} ${remainingWord}`;
    } else {
      output = `${output} ${word}`;
    }
  }
  return output;
};

export { fitTextForDisplay };
