import React, { useState } from 'react';
import { selectedButtonStyle } from './buttonStyles';
import MenuIcon from '@material-ui/icons/Menu';
import { Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HeaderMenuButton = ({ onHomeClick, onDashboardClick, onBrowseClick, onPostAdClick, onInboxClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /* istanbul ignore next */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [buttonOver, setButtonOver] = useState(false);

  return (
    <>
      <button
        data-testid={'burger-menu'}
        aria-controls="navigation-menu"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseEnter={/* istanbul ignore next */ () => setButtonOver(true)}
        onMouseLeave={/* istanbul ignore next */ () => setButtonOver(false)}
        style={selectedButtonStyle(buttonOver)}
      >
        <MenuIcon data-testid={'closeSnackbar'} fontSize="small" />
      </button>
      <Menu id="navigation-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem data-testid={'home'} onClick={onHomeClick}>
          {t('header_home')}
        </MenuItem>
        <MenuItem data-testid={'dashboard'} onClick={onDashboardClick}>
          {t('header_dashboard')}
        </MenuItem>
        <MenuItem data-testid={'browse'} onClick={onBrowseClick}>
          {t('header_browse')}
        </MenuItem>
        <MenuItem data-testid={'post'} onClick={onPostAdClick}>
          {t('header_post_ad')}
        </MenuItem>
        <MenuItem data-testid={'inbox'} onClick={onInboxClick}>
          {t('header_inbox')}
        </MenuItem>
      </Menu>
    </>
  );
};

HeaderMenuButton.propTypes = {
  onHomeClick: PropTypes.func.isRequired,
  onDashboardClick: PropTypes.func.isRequired,
  onBrowseClick: PropTypes.func.isRequired,
  onPostAdClick: PropTypes.func.isRequired,
  onInboxClick: PropTypes.func.isRequired,
};

export { HeaderMenuButton };
