import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const Rating = ({ onSetRating }) => {
  const classes = useStyles();

  const [stars, setStars] = useState(0);

  const setStarRating = (rating) => {
    setStars(rating);
    onSetRating(rating);
  };

  const renderStartForIndex = (index) => {
    if (stars >= index) {
      return (
        <Star key={index} data-testid={`star-${index}`} color={'secondary'} onClick={() => setStarRating(index)} />
      );
    }
    return (
      <StarBorderIcon
        key={index}
        data-testid={`star-${index}`}
        color={'secondary'}
        onClick={() => setStarRating(index)}
      />
    );
  };

  return <div className={classes.container}>{[1, 2, 3, 4, 5].map((index) => renderStartForIndex(index))}</div>;
};

Rating.propTypes = {
  onSetRating: PropTypes.func.isRequired,
};

export default Rating;
