import { closestCityWithLongLat } from './geoLocalisation';

const locationFor = (locations, locationId) => {
  if (locationId) {
    const l = locations.filter((l) => l.uid === locationId);
    if (l.length === 1) {
      return l[0];
    }

    const lat = locationId.substr(0, locationId.indexOf('__'));
    const long = locationId.substr(locationId.indexOf('__') + 2);
    return closestCityWithLongLat(locations, lat, long);
  }
};

const stateFrom = (provinces, locationId) => {
  let code = undefined;
  if (locationId) {
    provinces.forEach((p) => {
      const l = p.cities.filter((c) => c.uid === locationId);
      if (l.length === 1) {
        code = p.code;
        return p.code;
      }
    });
  }
  return code;
};

const provinceFor = (provinces, code) => {
  const l = provinces.filter((l) => l.code === code);
  if (l.length === 1) {
    return l[0];
  }
};

const citiesFor = (provinces, provinceUid) => {
  const province = provinces.filter((l) => l.code === provinceUid);
  if (provinceUid) {
    return province[0].cities;
  }
  return [];
};

export { locationFor, provinceFor, citiesFor, stateFrom };
