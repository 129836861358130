/* istanbul ignore file */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  DASHBOARD,
  LOGIN,
  POST_AD,
  SEARCH_RESULTS,
  WELCOME,
  RENT,
  EDIT_AD,
  INBOX,
  INBOX_SPECIFIC,
  PAY_WALL,
  EDIT_PROFILE,
  PROFILE_CREATED,
  CONFIRM_RENT,
  COMPLETE,
  RETURN,
  PROFILE_VIEW,
  DISPUTE,
  CANCEL_RENTAL,
  HELP,
  BOOKING_VIEW,
  MAINTENANCE,
} from './index';
import MainScreen from '../screens/Home';
import DashboardScreen from '../screens/Dashboard';
import { LoginScreen } from '../screens/Login';
import HelpScreen from '../screens/Help';
import { SearchResults } from '../screens/SearchResults';
import PostAd from '../screens/PostAd';
import RentItem from '../screens/RenItem';
import ErrorPage from '../screens/Error';
import Inbox from '../screens/Inbox';
import PayWall from '../screens/Paywall';
import Profile from '../screens/Profile/index';
import ProfileCreated from '../screens/ProfileCreated';
import ConfirmRent from '../screens/ConfirmRent';
import CompleteRental from '../screens/CompleteReturn';
import ReturnItem from '../screens/ReturnItem';
import ProfileView from '../screens/ProfileView';
import Dispute from '../screens/Dispute';
import CancelRental from '../screens/CancelRent';
import Booking from '../screens/Booking';
import MaintenanceScreen from '../screens/Maintenance';

const MainRouter = () => (
  <Switch>
    <Route path={LOGIN} exact component={LoginScreen} />
    <Route path={WELCOME} exact component={MainScreen} />
    <Route path={DASHBOARD} exact component={DashboardScreen} />
    <Route path={SEARCH_RESULTS} exact component={SearchResults} />
    <Route path={POST_AD} exact component={PostAd} />
    <Route path={EDIT_AD} exact render={({ match }) => <PostAd adId={match.params.adId} />} />
    <Route path={RENT} exact render={({ match }) => <RentItem adId={match.params.adId} />} />
    <Route path={COMPLETE} exact render={({ match }) => <CompleteRental rentId={match.params.rentId} />} />
    <Route path={RETURN} exact render={({ match }) => <ReturnItem rentId={match.params.rentId} />} />
    <Route path={INBOX} exact component={Inbox} />
    <Route path={INBOX_SPECIFIC} exact render={({ match }) => <Inbox threadId={match.params.threadId} />} />
    <Route path={PAY_WALL} exact render={({ match }) => <PayWall rentId={match.params.rentId} />} />
    <Route path={PROFILE_CREATED} exact component={ProfileCreated} />
    <Route path={EDIT_PROFILE} exact component={Profile} />
    <Route path={CONFIRM_RENT} exact render={({ match }) => <ConfirmRent rentId={match.params.rentId} />} />
    <Route path={PROFILE_VIEW} exact render={({ match }) => <ProfileView userId={match.params.userId} />} />
    <Route path={DISPUTE} exact render={({ match }) => <Dispute rentId={match.params.rentId} />} />
    <Route path={CANCEL_RENTAL} exact render={({ match }) => <CancelRental rentId={match.params.rentId} />} />
    <Route path={BOOKING_VIEW} exact render={({ match }) => <Booking rentId={match.params.rentId} />} />
    <Route path={HELP} component={HelpScreen} />
    <Route path={MAINTENANCE} component={MaintenanceScreen} />
    <Route render={() => <ErrorPage />} />
  </Switch>
);

export default MainRouter;
