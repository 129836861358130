import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { fitTextForDisplay } from '../utils/textTruncate';
import { renderStars } from '../utils/ratings';
import { NoWrapTypography } from './noWrapTypography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  box: {
    width: 200,
    minHeight: 180,
  },
  boxTopPart: {
    display: 'flex',
    flexDirection: 'row',
  },
  boxBottomPart: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
  locationBox: {
    display: 'flex',
    width: '100%',
    paddingTop: 0,
  },
  textForImage: {
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },
  cardWithAvatar: {
    position: 'relative',
    left: 3,
    top: -47,
    width: 50,
    height: 50,
    backgroundColor: 'transparent',
    borderWidth: 5,
    borderColor: 'black',
  },
  dotForAvatar: {
    position: 'relative',
    left: 0,
    top: 0,
    width: 50,
    height: 50,
    backgroundColor: 'transparent',
    borderWidth: 5,
    borderColor: 'black',
  },
  dot: {
    display: 'block',
    height: 56,
    width: 56,
    backgroundColor: 'black',
    borderRadius: '50%',
  },
  username: {
    maxWidth: '64',
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  avatar: {
    display: 'flex',
    height: 56,
    width: 56,
  },
  commentBox: {
    display: 'block',
    height: 75,
    width: 200,
    fontFamily: 'Oswald',
    color: '#19857b',
    fontSize: 12,
    textAlign: 'left',
  },
  fullWidthCommentBox: {
    display: 'flex',
    height: '100%',
    width: '100%',
    fontFamily: 'Oswald',
    color: '#19857b',
    fontSize: 12,
    textAlign: 'left',
  },
  customBox: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 4,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
}));

const ReviewCardWithoutImage = ({
  avatar,
  displayName,
  location,
  reviewComment,
  rating,
  fullWidth,
  showAvatar,
  showLocation,
}) => {
  const classes = useStyles();

  const renderReviewComment = () => {
    if (fullWidth) {
      return (
        <div className={classes.fullWidthCommentBox}>
          <Typography align={'left'} color={'secondary'} variant={'caption'}>
            {reviewComment}
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.commentBox}>
        <Box component="div" classes={{ root: classes.customBox }}>
          {fitTextForDisplay(reviewComment, 390, '14pt Oswald')}
        </Box>
      </div>
    );
  };

  return (
    <div className={classes.box}>
      <div style={{ flex: 1, paddingBottom: fullWidth ? 20 : 0 }}>
        {showAvatar && (
          <div className={classes.boxTopPart}>
            <div>
              <Avatar className={classes.avatar} src={avatar} />
            </div>
            <NoWrapTypography value={displayName} />
          </div>
        )}
        <div className={classes.boxBottomPart}>
          {rating && renderStars(rating)}
          {reviewComment && renderReviewComment()}
          {showLocation && (
            <div className={classes.locationBox}>
              <Typography align={'left'} color={'secondary'} variant={'caption'} noWrap={true}>
                {location}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ReviewCardWithoutImage.propTypes = {
  reviewComment: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  location: PropTypes.string,
  avatar: PropTypes.string,
  rating: PropTypes.number,
  fullWidth: PropTypes.bool,
  showAvatar: PropTypes.bool,
  showLocation: PropTypes.bool,
};

ReviewCardWithoutImage.defaultProps = {
  showAvatar: true,
  showLocation: true,
};

export default ReviewCardWithoutImage;
