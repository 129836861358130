import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import RentingImage from '../../components/rentingImage';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { locationFor } from '../../utils/location';
import { languageToLocale } from '../../utils/locale';
import { RENT } from '../../routes';
import { useHistory, withRouter } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import notavailable from '../../assets/noimageavailable.png';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    paddingBottom: 30,
  },
  newItemsText: {
    flex: 1,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
  },
  newItemBox: {
    display: 'block',
    backgroundColor: 'white',
    border: '1px lightgray solid',
    padding: 8,
    marginLeft: 4,
    marginRight: 4,
    width: 200,
    cursor: 'pointer',
    borderRadius: 3,
  },
}));

const NewItems = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const user = useSelector((state) => state.authentication.user);

  const { loadNewItems } = useRematchDispatch((dispatch) => ({
    loadNewItems: dispatch.home.loadNewItems,
  }));

  useEffect(() => {
    /* istanbul ignore next */
    loadNewItems(user != null ? user.uid : undefined);
  }, [loadNewItems, user]);

  const locations = useSelector((state) => state.locations.locations);
  const newItems = useSelector((state) => state.home.newItems);
  const locale = useSelector((state) => state.authentication.locale);

  const goToAd = (ad) => {
    history.push(RENT.replace(':adId', ad.uid));
  };

  const theme = useTheme();
  let isMobile;

  let largeScreen = true;
  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
    largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  }

  const hasSearch = () => {
    return newItems !== undefined && newItems.length > 0;
  };

  const renderContainerContent = () => {
    return (
      <>
        {newItems &&
          newItems.map((ad, index) => {
            const { title, location, images, price, unit, mainImageWidth, mainImageHeight } = ad;
            if (images) {
              return (
                <Grid item key={`newItem-${ad.uid}`}>
                  <div className={classes.newItemBox}>
                    <RentingImage
                      id={`newitemimage-${index}`}
                      title={title}
                      location={locationFor(locations, location).name[languageToLocale(locale)]}
                      image={/* istanbul ignore next */ images[0] ? images[0] : notavailable}
                      price={price}
                      unit={unit}
                      mainImageWidth={mainImageWidth}
                      mainImageHeight={mainImageHeight}
                      noBorder={true}
                      onAdClick={() => goToAd(ad)}
                    />
                  </div>
                  <div style={{ paddingBottom: 10 }} />
                </Grid>
              );
            }
            return <span key={`newItem-${ad.uid}`} />;
          })}
      </>
    );
  };

  const renderContainer = () => {
    /* istanbul ignore next */
    if (largeScreen) {
      return (
        <div>
          <Grid container>{renderContainerContent()}</Grid>
        </div>
      );
    }
    /* istanbul ignore next */
    return (
      <Grid container direction={'column'} alignItems="center">
        {renderContainerContent()}
      </Grid>
    );
  };

  return (
    <div className={classes.container}>
      {hasSearch() && (
        <div className={classes.newItemsText}>
          <Typography color={'primary'} align={'left'} variant={'h6'}>
            {t('home_new_items')}
          </Typography>
        </div>
      )}
      {renderContainer()}
    </div>
  );
};

export default withRouter(NewItems);
