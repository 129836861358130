import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import useRematchDispatch from '../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import LoadingComponent from './loadingComponent';
import ListReviews from './listReviews';

const useStyles = makeStyles(() => ({
  lastRentals: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  noReviews: {
    marginLeft: 20,
    marginTop: 0,
  },
}));

const ListLastReviews = ({ adId, maxResults }) => {
  const classes = useStyles();

  const lastRentals = useSelector((state) => state.rent.lastRentals);
  const [loading, setLoading] = useState(true);

  const { loadLastRentals } = useRematchDispatch((dispatch) => ({
    loadLastRentals: dispatch.rent.loadLastRentals,
  }));

  useEffect(() => {
    async function doLoadLasRentals() {
      await loadLastRentals({ adId, maxResults });
      setLoading(false);
    }
    doLoadLasRentals();
  }, [adId, maxResults, loadLastRentals]);

  const getReviews = () => {
    if (lastRentals !== undefined && lastRentals[adId] !== undefined) {
      return lastRentals[adId];
    }
    return [];
  };

  const renderComments = () => {
    if (loading) {
      return <LoadingComponent isLoading={loading} />;
    }
    return <ListReviews reviews={getReviews()} />;
  };

  return <div className={classes.lastRentals}>{renderComments()}</div>;
};

ListLastReviews.propTypes = {
  adId: PropTypes.string.isRequired,
  maxResults: PropTypes.number,
};

ListLastReviews.defaultProps = {
  maxResults: undefined,
};

export default ListLastReviews;
