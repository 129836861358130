import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from '../../components/loading';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import ListReviews from '../../components/listReviews';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  reviewsContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: 20,
  },
}));

const Reviews = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);
  const userReviews = useSelector((state) => state.profile.userReviews);
  const { loadUserReviews, unregisterLoadUserReviews } = useRematchDispatch((dispatch) => ({
    loadUserReviews: dispatch.profile.loadUserReviews,
    unregisterLoadUserReviews: dispatch.profile.unregisterLoadUserReviews,
  }));

  const renderContent = () => {
    if (loading) {
      return <></>;
    }

    return <ListReviews reviews={userReviews} />;
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (user !== undefined && userReviews !== undefined) {
      setLoading(false);
    }
  }, [user, userReviews]);

  useEffect(() => {
    /* istanbul ignore next */
    if (user) {
      loadUserReviews({ uid: user.uid, maxResults: 50 });
    }

    return () => {
      unregisterLoadUserReviews();
    };
  }, [user, loadUserReviews, unregisterLoadUserReviews]);

  return (
    <div className={classes.reviewsContainer}>
      <Loading isLoading={loading}>{renderContent()}</Loading>
    </div>
  );
};

export default Reviews;
