/* istanbul ignore file */
const cropIsValid = (pixelCrop) => {
  return (
    pixelCrop &&
    pixelCrop.width !== undefined &&
    pixelCrop.height !== undefined &&
    pixelCrop.x !== undefined &&
    pixelCrop.y !== undefined
  );
};

export default async function getCroppedImg(imageSrc, pixelCrop) {
  if (cropIsValid(pixelCrop)) {
    let image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = 1200;
    canvas.height = 1200;

    const widthRatio = 1200 / image.width;
    const heightRatio = 1200 / image.height;

    // draw rotated image and store data.
    const smallerRatio = Math.min(widthRatio, heightRatio);
    if (smallerRatio >= 1) {
      ctx.drawImage(image, 0, 0, image.width / smallerRatio, image.height / smallerRatio);
    } else {
      ctx.drawImage(image, 0, 0, image.width * smallerRatio, image.height * smallerRatio);
    }

    const { x, y, width, height } = pixelCrop;
    let newX;
    let newY;
    let newWidth;
    let newHeight;

    if (smallerRatio >= 1) {
      newX = x / smallerRatio;
      newY = y / smallerRatio;
      newWidth = width / smallerRatio;
      newHeight = width / smallerRatio;
    } else {
      newX = x * smallerRatio;
      newY = y * smallerRatio;
      newWidth = width * smallerRatio;
      newHeight = width * smallerRatio;
    }
    const data = ctx.getImageData(Math.trunc(newX), Math.trunc(newY), Math.trunc(newWidth), Math.trunc(newHeight));
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = newWidth;
    canvas.height = newHeight;

    ctx.putImageData(data, 0, 0);

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, 'image/jpeg');
    });
  }
}

const fileDropped = async (acceptedFile, callback) => {
  const reader = new FileReader();

  reader.onloadend = async () => {
    const binaryStr = reader.result;
    callback(binaryStr);
  };

  if (acceptedFile.name.toLowerCase().endsWith('.heic')) {
    // get image as blob url
    const blobURL = URL.createObjectURL(acceptedFile);

    // convert "fetch" the new blob url
    const blobRes = await fetch(blobURL);

    // convert response to blob
    const blob = await blobRes.blob();

    const heic2any = require('heic2any');
    // convert to PNG - response is blob
    const conversionResult = await heic2any({ blob });
    reader.readAsDataURL(conversionResult);
  } else {
    reader.readAsDataURL(acceptedFile);
  }
};

const cropImage = async (imageRef, crop, callback) => {
  if (imageRef && crop.width && crop.height) {
    const croppedImageUrl = await getCroppedImg(imageRef, crop, 'file.jpeg');
    callback(croppedImageUrl);
  }
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export { getCroppedImg, fileDropped, cropImage };
