import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DASHBOARD, COMPLETE } from '../../routes';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import ContactInfo from '../../components/contactInfo';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { useAdServiceContext } from '../../services/ServicesProvider';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ResizableImageBox from '../../components/resizableImageBox';
import { useTheme } from '@material-ui/styles';
import notavailable from '../../assets/noimageavailable.png';
import { AdDescription } from '../../components/adDescription';
import { Overcharge } from './overcharge';
import { ErrorSnackbar } from '../../components/errorSnackbar';
import { ReviewPanel } from '../../components/reviewPanel';
import { UserAndRentalDates } from '../../components/userAndDatesInfoBlock';
import ReviewCardWithoutImage from '../../components/reviewCardWithoutImage';
import { RENT_STATUS } from '../../models/rentStatus';
import BasePage from '../../components/basePage';
import OwnerBillingInformation from '../../components/ownerBillingInformation';
import LoadingButton from '../../components/loadingButton';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 25,
    paddingRight: 25,
    boxSizing: 'border-box',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imageAndCalendar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullFlex: {
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  overcharge: {
    paddingTop: 20,
    width: '100%',
  },
  button: {
    width: 150,
  },
}));

const CompleteRental = ({ history, rentId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState(false);
  const [ownerPayout, setOwnerPayout] = useState();

  const ad = useSelector((state) => state.ads.currentAd);
  const renterUser = useSelector((state) => state.rent.user);

  const rental = useSelector((state) => state.rent.currentRental);
  const { loadAd, clearAd, loadRental, loadUser } = useRematchDispatch((dispatch) => ({
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
    loadRental: dispatch.rent.loadRental,
    loadUser: dispatch.rent.loadUser,
  }));
  const adService = useAdServiceContext();

  const [explanation, setExplanation] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [charge, setCharge] = useState(0);

  const completeReturn = async () => {
    setLoading(true);
    try {
      await adService.completeReturn({ id: rentId, rating, review });
      setLoading(false);
      history.push(DASHBOARD);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const openDispute = () => {
    adService.openDispute({
      user: rental.owner,
      from: renterUser.uid,
      fromName: renterUser.displayName,
      avatar: /* istanbul ignore next */ ad && ad.images[0] ? ad.images[0] : notavailable,
      id: rentId,
      adId: ad.uid,
      charge,
      explanation,
      disputeStartDate: moment().toDate(),
    });
    history.push(DASHBOARD);
  };

  useEffect(() => {
    loadRental({ id: rentId });
  }, [rentId, loadRental]);

  useEffect(() => {
    if (rental) {
      adService.markAsRead(rental);
      loadAd({ id: rental.ad });
      loadUser({ id: rental.user });
    }
  }, [rental, loadAd, loadUser, adService]);

  useEffect(() => {
    return () => {
      clearAd();
    };
  }, [clearAd]);

  useEffect(() => {
    if (rental !== undefined && ad !== undefined && renterUser !== undefined && ownerPayout !== undefined) {
      setPageLoading(false);
    }
  }, [rental, ad, renterUser, ownerPayout]);

  useEffect(() => {
    async function doComputePayout(rentId) {
      const ownerPayout = await adService.getOwnerPayout(rentId);
      setOwnerPayout(ownerPayout);
    }

    /* istanbul ignore next */
    if (rental) {
      doComputePayout(rental.uid);
    }
  }, [rental, adService]);

  const handleClose = () => {
    setError(false);
  };

  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  const ratingIsSet = () => rating !== 0;

  const renderBasedOnOvercharge = () => {
    if (charge === 0) {
      return (
        <>
          <ReviewPanel rating={rating} review={review} onRatingChanged={setRating} onReviewChanged={setReview} />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              disabled={!ratingIsSet()}
              data-testid={'completeReturn'}
              variant="contained"
              color="primary"
              onClick={completeReturn}
            >
              <LoadingButton loading={loading} content={t('complete_button')} />
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={classes.overcharge}>
          <Typography color={'primary'}>{t('complete_dispute_overcharge_explanation')}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            disabled={explanation === ''}
            data-testid={'dispute'}
            variant="contained"
            color="primary"
            onClick={openDispute}
          >
            {t('complete_open_dispute')}
          </Button>
        </div>
      </>
    );
  };

  const getCalendarStyle = () => {
    return {
      width: '100%',
      height: '100%',
      paddingTop: 30,
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 30,
      paddingRight: /* istanbul ignore next */ isMobile ? 0 : 30,
      backgroundColor: '#f7f7f5',
      boxSizing: 'border-box',
    };
  };

  const renderContent = () => {
    if (pageLoading) {
      return <></>;
    }

    return (
      <>
        <ErrorSnackbar
          open={error}
          onCloseSnackbar={handleClose}
          rentId={rental.uid}
          errorMessage={t('cant_return_item', { rentId })}
        />
        <AdDescription ad={ad} />
        <div data-testid={'pageloaded'} className={classes.imageAndCalendar}>
          <Grid container>
            <Grid item>
              <div className={classes.fullFlex}>
                <ResizableImageBox ad={ad} center={false} />
              </div>
            </Grid>
            <Grid item>
              <div style={getCalendarStyle()}>
                <ContactInfo user={renterUser} />
                <UserAndRentalDates rental={rental} />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: 30 }}>
                  <OwnerBillingInformation ownerPayout={ownerPayout} />
                </div>

                <div style={{ display: 'flex', width: '100%', paddingTop: 30 }}>
                  <ReviewCardWithoutImage
                    displayName={'test'}
                    reviewComment={rental.review}
                    rating={rental.rating}
                    fullWidth={true}
                    showAvatar={false}
                    showLocation={false}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.fullFlex}>
          {rental.status === RENT_STATUS.PENDING_OWNER_REVIEW && (
            <Overcharge
              ad={ad}
              charge={charge}
              onChargeChanged={setCharge}
              explanation={explanation}
              onExplanationChanged={setExplanation}
            />
          )}
          {renderBasedOnOvercharge()}
        </div>
      </>
    );
  };

  return (
    <BasePage loading={loading} pagePath={COMPLETE}>
      {renderContent()}
    </BasePage>
  );
};

CompleteRental.propTypes = {
  rentId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CompleteRental);
