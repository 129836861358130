import { createModel } from '@rematch/core';
import COLLECTIONS from '../collections';
import { firebaseService } from '../services/firebaseServiceInstance';

export const createUserModel = () =>
  createModel({
    state: {
      user: undefined,
      userListener: undefined,
    },

    reducers: {
      updateUser(state, user) {
        return { ...state, user };
      },
      updateUserListener(state, userListener) {
        return { ...state, userListener };
      },
      reset(state) {
        return { ...state, user: undefined, userListener: undefined };
      },
    },

    effects: (dispatch) => ({
      async loadUser({ uid }) {
        const userLoadedPromise = new Promise(async (resolve) => {
          const userListener = await firebaseService.onSnapshotById(COLLECTIONS.users, uid, (userDocument) => {
            dispatch.user.updateUser({ ...userDocument.data(), ...{ uid: userDocument.id } });
            dispatch.rent.loadPendingRentals();
            dispatch.rent.loadDisputeRentals();
            dispatch.inbox.registerLoadCurrentUserMessages();
            resolve();
          });
          dispatch.user.updateUserListener(userListener);
        });
        await userLoadedPromise;
      },

      async logout(payload, rootState) {
        if (rootState.user.userListener) {
          rootState.user.userListener();
        }
        dispatch.ads.unregisterPendingApprovalAds();
        dispatch.ads.unregisterDisputeAds();
        dispatch.inbox.unregisterLoadCurrentUserMessages();

        await firebaseService.logout();
        await firebaseService.signOut();

        dispatch.authentication.reset();
        dispatch.ads.reset();
        dispatch.inbox.reset();
        dispatch.rent.reset();
        dispatch.home.reset();
        dispatch.user.reset();
        dispatch.profile.reset();
      },

      async changeLocale(locale, rootState) {
        if (rootState.user.user) {
          dispatch.user.updateUser({ ...rootState.user.user, ...{ language: locale } });
          await firebaseService.writeToCollection(COLLECTIONS.users, rootState.user.user.uid, {
            ...rootState.user.user,
            ...{ language: locale },
          });
        }
      },
    }),
  });
