import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import UseCreditsButton from './useCreditsButton';
import PropTypes from 'prop-types';
import { CREDITS } from '../../payoutModes';
import UnlinkAccountButton from './unlinkAccountButton';
import { useProfileServiceContext } from '../../services/ServicesProvider';

const useStyles = makeStyles(() => ({
  linked: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
  },
}));

const AccountLinkedToBank = ({ onUseCredits, onUnlinkAccount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);

  const handleUseCredit = () => {
    onUseCredits({ payoutMode: CREDITS });
  };

  const handleUnlinkAccount = () => {
    onUnlinkAccount();
  };

  return (
    <div className={classes.linked}>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('account_linked', { bankName: user.bankName })}
      </Typography>
      <UnlinkAccountButton onClick={handleUnlinkAccount} />
      <UseCreditsButton onClick={handleUseCredit} payoutMode={CREDITS} />
    </div>
  );
};

AccountLinkedToBank.propTypes = {
  onUseCredits: PropTypes.func.isRequired,
  onUnlinkAccount: PropTypes.func.isRequired,
};

export default AccountLinkedToBank;
