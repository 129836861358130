import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  selectRoot: {
    '&:focus': {
      backgroundColor: 'white',
    },
    justifyContent: 'left',
    backgroundColor: 'white',
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
    border: '1px black solid',
    display: 'flex',
    fontSize: '0.85em',
    paddingLeft: 5,
  },
}));

const times = [
  { display: '0:00', value: 0 },
  { display: '1:00', value: 1 },
  { display: '2:00', value: 2 },
  { display: '3:00', value: 3 },
  { display: '4:00', value: 4 },
  { display: '5:00', value: 5 },
  { display: '6:00', value: 6 },
  { display: '7:00', value: 7 },
  { display: '8:00', value: 8 },
  { display: '9:00', value: 9 },
  { display: '10:00', value: 10 },
  { display: '11:00', value: 11 },
  { display: '12:00', value: 12 },
  { display: '13:00', value: 13 },
  { display: '14:00', value: 14 },
  { display: '15:00', value: 15 },
  { display: '16:00', value: 16 },
  { display: '17:00', value: 17 },
  { display: '18:00', value: 18 },
  { display: '19:00', value: 19 },
  { display: '20:00', value: 20 },
  { display: '21:00', value: 21 },
  { display: '22:00', value: 22 },
  { display: '23:00', value: 23 },
];

const TimeSelector = ({ value, onSelectTime, id }) => {
  const classes = useStyles();

  return (
    <Select
      align={'left'}
      classes={{ root: classes.selectRoot }}
      id={id}
      value={value}
      onChange={(event) => onSelectTime(event.target.value)}
    >
      {times &&
        times.map((time) => {
          /* istanbul ignore next */
          const { display, value } = time;
          return (
            <MenuItem key={value} value={value} style={{ backgroundColor: 'white' }}>
              {display}
            </MenuItem>
          );
        })}
    </Select>
  );
};

TimeSelector.propTypes = {
  value: PropTypes.number.isRequired,
  onSelectTime: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default TimeSelector;
