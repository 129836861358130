/* istanbul ignore file */
import AD from './adFields';
import RENT from './rentFields';

export default {
  mostRecentRented: { id: 'mostRecentRented', field: RENT.startDate, name: 'most_recent', defaultOrder: 'desc' },
  mostRecent: { id: 'mostRecent', field: AD.createdDate, name: 'most_recent', defaultOrder: 'desc' },
  oldest: { id: 'oldest', field: AD.createdDate, name: 'oldest', defaultOrder: 'asc' },
  lowestPrice: { id: 'lowestPrice', field: AD.price, name: 'lowest_price', defaultOrder: 'asc' },
  mostExpensive: { id: 'mostExpensive', field: AD.price, name: 'most_expensive', defaultOrder: 'desc' },
};
