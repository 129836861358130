import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  saveButton: {
    marginRight: 20,
  },
  tabPanel: {
    width: '75%',
    paddingTop: 20,
  },
}));

const SaveCancelButtonsContainer = ({ somethingChanged, canSave, initialProfileComplete, onSave, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.buttonContainer}>
      <Button
        disabled={!somethingChanged || !canSave}
        className={classes.saveButton}
        data-testid={'saveButton'}
        variant="contained"
        color="primary"
        onClick={onSave}
      >
        {t('action_save')}
      </Button>
      <Button
        disabled={!initialProfileComplete}
        data-testid={'cancelButton'}
        variant="contained"
        color="primary"
        onClick={onCancel}
      >
        {t('action_cancel')}
      </Button>
    </div>
  );
};

SaveCancelButtonsContainer.propTypes = {
  somethingChanged: PropTypes.bool.isRequired,
  canSave: PropTypes.bool.isRequired,
  initialProfileComplete: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SaveCancelButtonsContainer;
