import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    paddingTop: 20,
    paddingButton: 20,
    display: 'flex',
    justifyContent: 'left',
  },
  button: {
    width: 100,
    marginRight: 20,
  },
}));

const NavigationButtons = ({ showPrevious, showNext, onPreviousPress, onNextPress }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.buttonGroup}>
      {showPrevious && (
        <Button className={classes.button} variant="contained" color="primary" onClick={onPreviousPress}>
          {t('component_navigation_previous')}
        </Button>
      )}
      {showNext && (
        <Button className={classes.button} variant="contained" color="primary" onClick={onNextPress}>
          {t('component_navigation_next')}
        </Button>
      )}
    </div>
  );
};

NavigationButtons.propTypes = {
  showPrevious: PropTypes.bool,
  showNext: PropTypes.bool,
  onPreviousPress: PropTypes.func.isRequired,
  onNextPress: PropTypes.func.isRequired,
};

NavigationButtons.defaultProps = {
  showPrevious: true,
  showNext: true,
};

export default NavigationButtons;
