import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import getUserLocale from 'get-user-locale';
import useRematchDispatch from '../hooks/useRematchDispatch';
import Cookies from 'universal-cookie';

const LanguageSelector = () => {
  const locale = useSelector((state) => state.authentication.locale);
  const { toggleLocale } = useRematchDispatch((dispatch) => ({
    toggleLocale: dispatch.authentication.toggleLocale,
  }));

  const { i18n } = useTranslation();
  const cookies = new Cookies();
  if (locale === undefined) {
    const languageCookie = cookies.get('louetoutca.locale');
    if (languageCookie) {
      toggleLocale({ from: 'selector', value: languageCookie });
    } else {
      const userLocale = getUserLocale();
      if (userLocale.startsWith('fr')) {
        toggleLocale({ from: 'selector', value: 'french' });
      } else {
        toggleLocale({ from: 'selector', value: 'english' });
      }
    }
  }

  useEffect(() => {
    if (locale) {
      const cookies = new Cookies();
      cookies.set('louetoutca.locale', locale, { path: '/' });
      i18n.changeLanguage(languageToLocale(locale));
    }
  }, [locale, i18n]);

  return <></>;
};

const languageToLocale = (language) => {
  if (language === 'french') {
    return 'fr';
  }
  return 'en';
};

export default LanguageSelector;
