import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { languageToLocale } from '../utils/locale';

const useStyles = makeStyles(() => ({
  selector: {
    display: 'flex',
    width: '100%',
    justifyContent: 'left',
  },
}));

const CategorySelector = ({ category, onSelectCategory, showAll }) => {
  const classes = useStyles();
  const categories = useSelector((state) => state.categories.categories);
  const locale = useSelector((state) => state.authentication.locale);

  const [categoriesOptions, setCategoriesOptions] = useState([]);

  useEffect(() => {
    if (showAll) {
      setCategoriesOptions([{ code: '-1', en: 'Everything', fr: 'Tout', uid: '-1' }].concat(categories));
    } else {
      setCategoriesOptions(categories);
    }
  }, [categories, showAll]);

  return (
    <Select
      align={'left'}
      className={classes.selector}
      id="category"
      value={categoriesOptions.length > 0 ? category : ''}
      fullWidth
      onChange={(event) => onSelectCategory(event.target.value)}
    >
      {categoriesOptions &&
        categoriesOptions
          .filter((c) => c !== undefined)
          .map((category) => {
            /* istanbul ignore next */
            const { code } = category;
            return (
              <MenuItem key={code} value={code} style={{ backgroundColor: 'white' }}>
                {category[languageToLocale(locale)]}
              </MenuItem>
            );
          })}
    </Select>
  );
};

CategorySelector.propTypes = {
  category: PropTypes.string.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
};

CategorySelector.defaultProps = {
  showAll: false,
};

export default CategorySelector;
