import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
  line: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 8,
  },
  label: {
    width: 150,
  },
}));

const InformativeLabel = ({ label, value, onClick }) => {
  const classes = useStyles();

  const renderValue = () => {
    if (onClick === undefined) {
      return (
        <Typography color={'textPrimary'} align={'left'} variant={'body2'}>
          {value}
        </Typography>
      );
    } else {
      return (
        <Link data-testid={'informative-label-link'} onClick={onClick} style={{ cursor: 'pointer' }}>
          <Typography color={'textSecondary'} align={'left'} variant={'body2'}>
            {value}
          </Typography>
        </Link>
      );
    }
  };

  return (
    <div className={classes.line}>
      <div className={classes.label}>
        <Typography color={'primary'} align={'left'} variant={'body2'}>
          {label}:
        </Typography>
      </div>
      {renderValue()}
    </div>
  );
};

InformativeLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export { InformativeLabel };
