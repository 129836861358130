import { createModel } from '@rematch/core';
import { firebaseService } from '../services/firebaseServiceInstance';
import COLLECTIONS from '../collections';

export const createSettingsModel = () =>
  createModel({
    state: {
      underMaintenance: false,
      listener: undefined,
    },

    reducers: {
      updateMaintenance(state, underMaintenance) {
        return { ...state, underMaintenance };
      },
      updateListener(state, listener) {
        return { ...state, listener };
      },
    },

    effects: (dispatch) => ({
      async loadSettings() {
        const listener = await firebaseService.onSnapshotById(COLLECTIONS.globals, 'current', (settings) => {
          if (settings && settings.exists()) {
            dispatch.settings.updateMaintenance(settings.data().underMaintenance);
          }
        });
        dispatch.settings.updateListener(listener);
      },

      async unregisterSettingsListener(_, rootState) {
        if (rootState.settings.listener) {
          rootState.settings.listener();
          dispatch.settings.updateListener(undefined);
        }
      },
    }),
  });
