import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const loadingButtonTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
  },
});

const LoadingButton = ({ loading, content }) => {
  const renderButtonContent = () => {
    if (loading) {
      return (
        <MuiThemeProvider theme={loadingButtonTheme}>
          <CircularProgress size={24} color={'primary'} />
        </MuiThemeProvider>
      );
    }
    return content;
  };

  return renderButtonContent();
};

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};

export default LoadingButton;
