import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LinkAccountButton from './linkAccountButton';
import PropTypes from 'prop-types';
import { LINK } from '../../payoutModes';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  linked: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
  },
  checkIcon: {
    paddingTop: 5,
    display: 'flex',
  },
}));

const AccountUseCredits = ({ onLinkAccount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);

  const switchToLinkAccount = () => {
    onLinkAccount({ payoutMode: LINK });
  };

  return (
    <div className={classes.linked}>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('account_using_credits')}
      </Typography>
      <Typography color={'primary'} align={'left'} variant={'h6'}>
        {t('available_credits', { balance: user.credits.toFixed(2) })}
      </Typography>
      <LinkAccountButton onClick={switchToLinkAccount} payoutMode={LINK} />
    </div>
  );
};

AccountUseCredits.propTypes = {
  onLinkAccount: PropTypes.func.isRequired,
};

export default AccountUseCredits;
