import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    paddingBottom: 10,
  },
}));

const InputTextField = ({ title, initialProperty, onChange, testid, large }) => {
  const classes = useStyles();

  const [property, setProperty] = useState(initialProperty);

  useEffect(() => {
    setProperty(initialProperty);
  }, [initialProperty]);

  const onPropertyChange = (event) => {
    setProperty(event.target.value);
    onChange(event.target.value);
  };

  const ensureField = (field) => {
    return field ? field : '';
  };

  return (
    <div className={classes.mainContainer}>
      <Typography color={'primary'} align={'left'} variant={large ? 'h6' : 'body1'}>
        {title}
      </Typography>
      <TextField
        fullWidth
        inputProps={{ 'data-testid': testid }}
        value={ensureField(property)}
        variant="outlined"
        onChange={onPropertyChange}
      />
    </div>
  );
};

InputTextField.propTypes = {
  title: PropTypes.string,
  initialProperty: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  testid: PropTypes.string,
  large: PropTypes.bool,
};

InputTextField.defaultProps = {
  large: true,
};

export default InputTextField;
