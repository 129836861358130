import React from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { EDIT_AD } from '../../routes';
import { withRouter } from 'react-router-dom';
import ItemDescription from './ItemDescription';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  button: {
    width: 90,
  },
  border: {
    height: 25,
  },
  buttons: {
    display: 'flex',
    paddingTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  spacing: {
    paddingRight: 10,
  },
  buttonSeparator: {
    width: 10,
    height: '100%',
  },
}));

const DashboardItems = ({ history, items, onDeactivateAd, onReactivateAd, onDeleteAd }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const goToEditAd = (adId) => {
    history.push(EDIT_AD.replace(':adId', adId));
  };

  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  const getBoxTheme = () => {
    return {
      border: '1px lightgray solid',
      borderRadius: 3,
      marginBottom: 20,
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 8,
      paddingRight: /* istanbul ignore next */ isMobile ? 0 : 8,
      paddingTop: 7,
      paddingBottom: 7,
      boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.75)',
      width: 200,
    };
  };

  const renderButtons = (ad) => {
    if (ad.active === true) {
      return (
        <div className={classes.buttons}>
          <Button
            data-testid={`deactivate-ad-${ad.uid}`}
            variant="contained"
            color="primary"
            onClick={() => onDeactivateAd(ad)}
            className={classes.button}
          >
            {t('dashboard_make_inactive')}
          </Button>
        </div>
      );
    }
    return (
      <>
        <div className={classes.buttons}>
          <Button
            data-testid={`reactivate-ad-${ad.uid}`}
            variant="contained"
            color="primary"
            onClick={() => onReactivateAd(ad)}
            className={classes.button}
          >
            {t('dashboard_make_reactivate')}
          </Button>
          <div className={classes.buttonSeparator} />
          <Button
            data-testid={`delete-ad-${ad.uid}`}
            variant="contained"
            color="primary"
            onClick={() => onDeleteAd(ad)}
            className={classes.button}
          >
            {t('dashboard_remove_ad')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Grid container spacing={/* istanbul ignore next */ isMobile ? 0 : 10}>
      {items &&
        items.map((item, index) => {
          return (
            <Grid item key={`${item.uid} - ${index}`}>
              <div key={`${item.uid} - ${index}`} style={getBoxTheme()}>
                <ItemDescription ad={item} onClick={() => goToEditAd(item.uid)} />
                <div className={classes.buttons}>{renderButtons(item)}</div>
              </div>
            </Grid>
          );
        })}
    </Grid>
  );
};

DashboardItems.propTypes = {
  items: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  onDeactivateAd: PropTypes.func,
  onReactivateAd: PropTypes.func,
  onDeleteAd: PropTypes.func,
};

export default withRouter(DashboardItems);
