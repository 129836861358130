import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Header from '../../components/header';
import toolbox from '../../assets/tools-transparent.png';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageLogo: {
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    left: 0,
    top: 34,
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },
  textContent: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roundedBox: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 10,
  },
}));

const ErrorScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <Header />
      </div>
      <img src={toolbox} className={classes.imageLogo} alt={''} />
      <div className={classes.content}>
        <div className={classes.textContent}>
          <div className={classes.roundedBox}>
            <Typography color={'error'} align={'center'} variant={'h3'}>
              {t('error_page_not_found')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ErrorScreen);
