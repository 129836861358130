import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  snackbar: {
    backgroundColor: '#8b0000',
  },
}));

const ErrorSnackbar = ({ open, onCloseSnackbar, autoHideDuration, errorMessage }) => {
  const classes = useStyles();

  return (
    <Snackbar
      classes={{
        root: classes.root,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onCloseSnackbar}
      open={open}
      autoHideDuration={autoHideDuration}
    >
      <SnackbarContent
        className={classes.snackbar}
        message={<span>{errorMessage}</span>}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={onCloseSnackbar}>
              <CloseIcon data-testid={'closeSnackbar'} fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Snackbar>
  );
};

ErrorSnackbar.propTypes = {
  open: PropTypes.bool,
  onCloseSnackbar: PropTypes.func.isRequired,
  rentId: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
};

ErrorSnackbar.defaultProps = {
  open: false,
  autoHideDuration: 5000,
};

export { ErrorSnackbar };
