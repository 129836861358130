import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DASHBOARD, RETURN } from '../../routes';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import ResizableImageBox from '../../components/resizableImageBox';
import ContactInfo from '../../components/contactInfo';
import { useAdServiceContext } from '../../services/ServicesProvider';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { AdDescription } from '../../components/adDescription';
import { ReviewPanel } from '../../components/reviewPanel';
import { UserAndRentalDates } from '../../components/userAndDatesInfoBlock';
import Button from '@material-ui/core/Button';
import { ErrorSnackbar } from '../../components/errorSnackbar';
import RentBillingInformation from '../../components/rentBillingInformation';
import BasePage from '../../components/basePage';
import LoadingButton from '../../components/loadingButton';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingLeft: 25,
    paddingRight: 25,
    boxSizing: 'border-box',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  imageAndCalendar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullFlex: {
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: 150,
  },
}));

const ReturnItem = ({ history, rentId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const ad = useSelector((state) => state.ads.currentAd);
  const ownerUser = useSelector((state) => state.rent.owner);
  const [error, setError] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState();

  const rental = useSelector((state) => state.rent.currentRental);
  const { loadAd, clearAd, loadRental, loadOwner } = useRematchDispatch((dispatch) => ({
    loadAd: dispatch.ads.loadAd,
    clearAd: dispatch.ads.clearAd,
    loadRental: dispatch.rent.loadRental,
    loadOwner: dispatch.rent.loadOwner,
  }));
  const adService = useAdServiceContext();

  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);

  const returnItem = async () => {
    setLoading(true);
    try {
      await adService.returnItem({ id: rentId, rating, review });
      setLoading(false);
      history.push(DASHBOARD);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRental({ id: rentId });
  }, [rentId, loadRental]);

  useEffect(() => {
    if (rental) {
      adService.markAsRead(rental);
      loadAd({ id: rental.ad });
      loadOwner({ id: rental.owner });
    }

    return () => {
      clearAd();
    };
  }, [rental, loadAd, loadOwner, adService, clearAd]);

  useEffect(() => {
    async function doComputeAmounts(rentId) {
      const paymentInfo = await adService.getPaymentInformation(rentId);
      setPaymentInfo(paymentInfo);
      setPageLoading(false);
    }

    if (rental !== undefined && ad !== undefined && ownerUser !== undefined) {
      doComputeAmounts(rental.uid);
    }
  }, [rental, ad, ownerUser, adService]);

  const renderButtons = () => {
    return (
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          disabled={!ratingIsSet()}
          data-testid={'completeReturn'}
          variant="contained"
          color="primary"
          onClick={returnItem}
        >
          <LoadingButton loading={loading} content={t('return_button')} />
        </Button>
      </div>
    );
  };

  const theme = useTheme();
  let isMobile;

  /* istanbul ignore next */
  if (theme) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    });
  }

  const getCalendarStyle = () => {
    return {
      width: '100%',
      height: '100%',
      paddingTop: 30,
      paddingLeft: /* istanbul ignore next */ isMobile ? 0 : 30,
      paddingRight: /* istanbul ignore next */ isMobile ? 0 : 30,
      backgroundColor: '#f7f7f5',
      boxSizing: 'border-box',
    };
  };

  const ratingIsSet = () => rating !== 0;

  const handleClose = () => {
    setError(false);
  };

  const renderContent = () => {
    if (pageLoading) {
      return <></>;
    }
    return (
      <>
        <ErrorSnackbar
          open={error}
          onCloseSnackbar={handleClose}
          rentId={rental.uid}
          errorMessage={t('cant_return_item', { rentId })}
        />
        <AdDescription ad={ad} />
        <div data-testid={'pageloaded'} className={classes.imageAndCalendar}>
          <Grid container>
            <Grid item>
              <div className={classes.fullFlex}>
                <ResizableImageBox ad={ad} center={false} />
              </div>
            </Grid>
            <Grid item>
              <div style={getCalendarStyle()}>
                <ContactInfo user={ownerUser} />
                <UserAndRentalDates rental={rental} />
                <div style={{ display: 'flex', width: '100%', paddingTop: 30 }}>
                  <RentBillingInformation paymentInfo={paymentInfo} showReimburseSecurityDeposit={true} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.fullFlex}>
          <ReviewPanel rating={rating} review={review} onRatingChanged={setRating} onReviewChanged={setReview} />
        </div>
        {renderButtons()}
      </>
    );
  };

  return (
    <BasePage pagePath={RETURN} loading={loading}>
      {renderContent()}
    </BasePage>
  );
};

ReturnItem.propTypes = {
  rentId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ReturnItem);
