import React, { useEffect, useState } from 'react';
import { INBOX } from '../../routes';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../components/tabPanel';
import MessageType from '../../models/messageTypes';
import Threads from './threads';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../../hooks/useRematchDispatch';
import Messages from './messages';
import { useMessageServiceContext } from '../../services/ServicesProvider';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import BasePage from '../../components/basePage';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minWidth: 390,
  },
  header: {
    width: '100%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 25,
    paddingRight: 25,
    boxSizing: 'border-box',
  },
  topText: {
    width: '100%',
    flex: 1,
    paddingLeft: 25,
    boxSizing: 'border-box',
  },
  preview: {
    width: '100%',
    marginTop: 72,
  },
}));

const Inbox = ({ threadId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);
  const [allMessages, setAllMessages] = useState([]);
  const [disputeMessages, setDisputeMessages] = useState([]);
  const [requestMessages, setRequestMessages] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [selectedThread, setSelectedThread] = useState();

  const fromMessages = useSelector((state) => state.inbox.fromMessages);
  const toMessages = useSelector((state) => state.inbox.toMessages);

  const threadMessages = useSelector((state) => state.inbox.threadMessages);

  const user = useSelector((state) => state.user.user);

  const messageService = useMessageServiceContext();

  const { registerLoadThreadMessages, unregisterLoadThreadMessages } = useRematchDispatch((dispatch) => ({
    registerLoadThreadMessages: dispatch.inbox.registerLoadThreadMessages,
    unregisterLoadThreadMessages: dispatch.inbox.unregisterLoadThreadMessages,
  }));

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedThread(undefined);
    setShowMessages(false);
  };

  useEffect(() => {
    const allMessages = [...fromMessages, ...toMessages];
    allMessages.sort((a, b) => b.date - a.date);

    setAllMessages(allMessages);
    setDisputeMessages(allMessages.filter((m) => m.type === MessageType.DISPUTE));
    setRequestMessages(allMessages.filter((m) => m.type === MessageType.REQUEST));
  }, [fromMessages, toMessages, setDisputeMessages, setRequestMessages]);

  const selectItem = (thread) => {
    if (thread) {
      const isOwner = user.uid === thread.user;
      if (isOwner) {
        messageService.markAsReadByOwner({ threadId: thread.uid });
      } else {
        messageService.markAsReadByUser({ threadId: thread.uid });
      }
      registerLoadThreadMessages({ threadId: thread.uid });
      setSelectedThread(thread);
    } else {
      unregisterLoadThreadMessages();
      setSelectedThread(undefined);
    }
  };

  useEffect(() => {
    setShowMessages(threadMessages && threadMessages.length > 0);
  }, [threadMessages]);

  const createMessage = (message) => {
    messageService.createNewMessageInThread({
      threadId: selectedThread.uid,
      user: user.uid,
      message,
      isOwner: user.uid === selectedThread.user,
    });
  };

  useEffect(() => {
    if (threadId && allMessages && allMessages.length > 0) {
      const thread = allMessages.find((thread) => thread.uid === threadId);
      registerLoadThreadMessages({ threadId: thread.uid });
      setSelectedThread(thread);
    }
  }, [threadId, allMessages, registerLoadThreadMessages, setSelectedThread]);

  useEffect(() => {
    if (selectedThread) {
      const isOwner = selectedThread.user === user.uid;
      /* istanbul ignore next */
      if (selectedThread.unreadByOwner && isOwner) {
        /* istanbul ignore next */
        messageService.markAsReadByOwner({ threadId: selectedThread.uid });
      }
      if (isOwner === false && selectedThread.unreadByUser) {
        messageService.markAsReadByUser({ threadId: selectedThread.uid });
      }
    }
  }, [allMessages, selectedThread, user, messageService]);

  const renderMessages = () => {
    if (showMessages) {
      return (
        <Grid style={{ paddingLeft: 10 }} item xs={10} sm={8} md={6}>
          <Messages
            items={threadMessages}
            onSendMessage={createMessage}
            ownerId={selectedThread ? selectedThread.user : undefined}
            renterId={selectedThread ? selectedThread.from : undefined}
          />
        </Grid>
      );
    }
    return (
      <Typography style={{ paddingLeft: 10 }} color={'primary'} align={'left'} variant={'body1'}>
        {t('inbox_please_select_a_message')}
      </Typography>
    );
  };

  return (
    <BasePage loading={false} pagePath={INBOX}>
      <div className={classes.topText}>
        <Typography color={'primary'} align={'left'} variant={'h5'}>
          {t('inbox_title')}
        </Typography>
      </div>
      <div className={classes.mainContainer}>
        <Tabs data-testid={'tabs'} value={selectedTab} onChange={handleChange}>
          <Tab label={t('inbox_tabs_all_messages')} />
          <Tab label={t('inbox_tabs_requests')} />
          <Tab label={t('inbox_tabs_disputes')} />
        </Tabs>

        <Grid container>
          <Grid item xs={2} sm={4} md={4}>
            <TabPanel value={selectedTab} index={0}>
              <Threads items={allMessages} onItemSelected={selectItem} />
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
              <Threads items={requestMessages} onItemSelected={selectItem} />
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
              <Threads items={disputeMessages} onItemSelected={selectItem} />
            </TabPanel>
          </Grid>
          {renderMessages()}
        </Grid>
      </div>
    </BasePage>
  );
};

Inbox.propTypes = {
  history: PropTypes.object.isRequired,
  threadId: PropTypes.string,
};

export default withRouter(Inbox);
