import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import noimageavailable from '../../assets/noimageavailable.png';
import SelectAndCrop from './selectAndCropImage';
import PropTypes from 'prop-types';
import HighlightOff from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { useAdServiceContext } from '../../services/ServicesProvider';

const useStyles = makeStyles(() => ({
  noimageavailable: {
    height: 150,
    width: 150,
  },
  imageUploadComponent: {
    position: 'relative',
    paddingBottom: 10,
    paddingRight: 10,
  },
  deleteButtonContainer: {
    position: 'absolute',
    left: 125,
    top: -10,
    color: 'red',
  },
}));

const useIconButtonStyle = makeStyles(() => ({
  button: {
    color: 'red',
  },
}));

const UploadImage = ({
  onImageSelected,
  onImageRemoved,
  imageDefinedInitialState,
  imageUploadedInitialState,
  image,
}) => {
  const classes = useStyles();
  const iconButtonClasses = useIconButtonStyle();

  const adService = useAdServiceContext();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(image ? image : noimageavailable);
  const [imagedDefined, setImagedDefined] = useState(imageDefinedInitialState ? imageDefinedInitialState : false);

  useEffect(() => {
    setImageUrl(image ? image : noimageavailable);
  }, [image]);

  useEffect(() => {
    setImagedDefined(imageDefinedInitialState);
  }, [imageDefinedInitialState]);

  useEffect(() => {
    if (image !== '' && image !== undefined) {
      setImageUrl(image);
    }
  }, [image]);

  const openPopup = () => {
    if (!imagedDefined) {
      setModalIsOpen(true);
    }
  };

  const closeDialog = () => {
    setModalIsOpen(false);
  };

  /* istanbul ignore next */
  const imageSelected = async (fileUrl) => {
    /* istanbul ignore next */
    setUploading(true);
    /* istanbul ignore next */
    await adService.uploadImage(fileUrl, (downloadURL) => {
      setModalIsOpen(false);
      onImageSelected(downloadURL);
      setUploading(false);
    });
  };

  const removeImage = () => {
    setImagedDefined(false);
    setImageUrl(noimageavailable);
    onImageRemoved();
  };

  const renderImageBox = () => {
    if (imagedDefined) {
      return (
        <div className={classes.deleteButtonContainer}>
          <IconButton
            edge={'start'}
            color="primary"
            data-testid={'deleteImage'}
            component="span"
            classes={{ root: iconButtonClasses.button }}
            onClick={removeImage}
          >
            <HighlightOff />
          </IconButton>
        </div>
      );
    }
    return (
      <SelectAndCrop
        opened={modalIsOpen}
        onClose={closeDialog}
        imageUploadedInitialState={imageUploadedInitialState}
        onImageSelected={imageSelected}
        uploading={uploading}
      />
    );
  };

  return (
    <div data-testid={'uploadImage'} className={classes.imageUploadComponent}>
      <img
        data-testid={'imageComponent'}
        alt={''}
        src={imageUrl}
        className={classes.noimageavailable}
        onClick={openPopup}
      />
      {renderImageBox()}
    </div>
  );
};

UploadImage.propTypes = {
  onImageSelected: PropTypes.func.isRequired,
  onImageRemoved: PropTypes.func.isRequired,
  imageDefinedInitialState: PropTypes.bool,
  imageUploadedInitialState: PropTypes.bool,
  image: PropTypes.string,
};

export default UploadImage;
