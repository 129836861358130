import React, { useEffect } from 'react';
import useRematchDispatch from '../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import { MAINTENANCE, WELCOME } from '../routes';
import { withRouter } from 'react-router-dom';

const MaintenanceWatcher = ({ history }) => {
  const underMaintenance = useSelector((state) => state.settings.underMaintenance);

  const { loadSettings, unregisterSettingsListener } = useRematchDispatch((dispatch) => ({
    loadSettings: dispatch.settings.loadSettings,
    unregisterSettingsListener: dispatch.settings.unregisterSettingsListener,
  }));

  useEffect(() => {
    loadSettings();

    return () => {
      unregisterSettingsListener();
    };
  }, [loadSettings, unregisterSettingsListener]);

  useEffect(() => {
    if (underMaintenance === true) {
      history.push(MAINTENANCE);
    } else {
      if (history.location.pathname === MAINTENANCE) {
        history.push(WELCOME);
      }
    }
  }, [underMaintenance, history]);

  return <></>;
};

export default withRouter(MaintenanceWatcher);
