import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { mouseOverStyle } from './buttonStyles';
import { useSelector } from 'react-redux';
import { languageToLocale } from '../utils/locale';
import { getPriceAndDescription } from '../utils/priceUtils';
import { useTranslation } from 'react-i18next';
import notavailable from '../assets/noimageavailable.png';

const useStyles = makeStyles(() => ({
  image: {
    width: 200,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textForImage: {
    boxSizing: 'border-box',
    width: 250,
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const box = {
  display: 'block',
  width: 200,
  height: 280,
  backgroundColor: 'white',
  cursor: 'pointer',
};

const RentingImage = ({
  onAdClick,
  image,
  title,
  location,
  category,
  price,
  unit,
  noBorder,
  testid,
  showUnit,
  id,
  mainImageWidth,
  mainImageHeight,
}) => {
  const classes = useStyles();

  const categories = useSelector((state) => state.categories.categories);
  const locale = useSelector((state) => state.authentication.locale);
  const { t } = useTranslation();

  const boxStyle = () => {
    if (noBorder) {
      return { ...box, ...mouseOverStyle };
    }
    return { ...box };
  };

  const displayCategory = () => {
    if (categories && category) {
      const categoryFromSelector = categories.find((c) => c.code === category);
      return (
        <div style={{ display: 'flex', width: '100%' }}>
          <Typography align={'left'} color={'secondary'} variant={'caption'} noWrap={true}>
            {categoryFromSelector[languageToLocale(locale)]}
          </Typography>
        </div>
      );
    }
  };

  const getImageStyle = () => {
    if (mainImageWidth !== undefined && mainImageHeight !== undefined && mainImageWidth < mainImageHeight) {
      return {
        height: '100%',
        resizeMode: 'contain',
        maxHeight: 200,
        maxWidth: 200,
      };
    }
    return {
      width: '100%',
      resizeMode: 'contain',
      maxHeight: 200,
      maxWidth: 200,
    };
  };

  return (
    <div style={boxStyle()} data-testid={testid} id={id} onClick={onAdClick}>
      <div style={{ flex: 1 }}>
        <div className={classes.image}>
          <img
            src={image}
            style={getImageStyle()}
            alt={''}
            onError={
              /* istanbul ignore next */ ({ currentTarget }) => {
                /* istanbul ignore next */
                currentTarget.onerror = null; // prevents looping
                /* istanbul ignore next */
                currentTarget.src = notavailable;
              }
            }
          />
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <Typography align={'left'} color={'primary'} variant={'caption'} noWrap={true}>
            {title}
          </Typography>
        </div>
        {displayCategory()}
        <div style={{ display: 'flex', width: '100%' }}>
          <Typography align={'left'} color={'secondary'} variant={'caption'} noWrap={true}>
            {location}
          </Typography>
        </div>
        <div style={{ display: 'flex', width: '100%', paddingTop: 10 }}>
          {getPriceAndDescription(t, showUnit, price, unit)}
        </div>
      </div>
    </div>
  );
};

RentingImage.propTypes = {
  onAdClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  category: PropTypes.string,
  noBorder: PropTypes.bool,
  showUnit: PropTypes.bool,
  testid: PropTypes.string,
  id: PropTypes.string,
  mainImageWidth: PropTypes.number,
  mainImageHeight: PropTypes.number,
};

RentingImage.defaultProps = {
  noBorder: false,
  showUnit: true,
};

export default RentingImage;
