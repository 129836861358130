import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import TimeSelector from '../../components/timeSelector';

const useStyles = makeStyles(() => ({
  arrow: {
    height: 21,
    paddingLeft: 10,
    paddingRight: 10,
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
}));

const DatePickers = ({ startDate, endDate, startTime, endTime, onDateChanged, upcomingRentals, useHours }) => {
  const classes = useStyles();

  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(startTime);
  const [selectedEndTime, setSelectedEndTime] = useState(endTime);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  useEffect(() => {
    const datesToDisable = [];
    upcomingRentals.forEach((ur) => {
      const { startDate, endDate } = ur;
      let momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);
      while (momentStartDate.isBefore(momentEndDate)) {
        datesToDisable.push(momentStartDate.toDate());
        momentStartDate = momentStartDate.add(1, 'days');
      }
      datesToDisable.push(momentEndDate.toDate());
      setDisabledDates(datesToDisable);
    });
  }, [upcomingRentals]);

  const selectStartTime = (value) => {
    setSelectedStartTime(value);
  };

  const selectStartDate = (value) => {
    setSelectedStartDate(value);
  };

  const selectEndTime = (value) => {
    setSelectedEndTime(value);
  };

  const selectEndDate = (value) => {
    setSelectedEndDate(value);
  };

  useEffect(() => {
    onDateChanged({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
    });
  }, [selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, onDateChanged]);

  return (
    <Grid container>
      <Grid item>
        <div className={classes.row}>
          <DatePicker
            id={'datepicker-start'}
            selected={selectedStartDate}
            dateFormat="dd/MM/yyyy"
            minDate={moment().toDate()}
            maxDate={moment()
              .add(1, 'year')
              .toDate()}
            excludeDates={disabledDates}
            onChange={selectStartDate}
          />
          {useHours && (
            <TimeSelector id={'timepicker-start'} value={selectedStartTime} onSelectTime={selectStartTime} />
          )}
        </div>
      </Grid>
      <Grid item>
        <div className={classes.arrow}>
          <Typography align={'left'} color={'primary'} variant={'body2'} noWrap={true}>
            {'->'}
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <div className={classes.row}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            id={'datepicker-end'}
            selected={selectedEndDate}
            minDate={moment().toDate()}
            maxDate={moment()
              .add(1, 'year')
              .toDate()}
            excludeDates={disabledDates}
            onChange={selectEndDate}
          />
          {useHours && <TimeSelector id={'timepicker-end'} value={selectedEndTime} onSelectTime={selectEndTime} />}
        </div>
      </Grid>
    </Grid>
  );
};

DatePickers.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  onDateChanged: PropTypes.func.isRequired,
  upcomingRentals: PropTypes.array,
  useHours: PropTypes.bool.isRequired,
};

DatePickers.defaultProps = {
  upcomingRentals: [],
};

export default DatePickers;
