import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRematchDispatch from '../hooks/useRematchDispatch';

const UserLoader = () => {
  const loggedInUser = useSelector((state) => state.authentication.user);
  const user = useSelector((state) => state.user.user);
  const pendingOwnerItems = useSelector((state) => state.rent.pendingRentalsOwner);
  const pendingUserItems = useSelector((state) => state.rent.pendingRentalsUser);
  const disputeItemsOwner = useSelector((state) => state.rent.disputeRentalsOwner);
  const disputeItemsUser = useSelector((state) => state.rent.disputeRentalsUser);

  const {
    loadUser,
    toggleLocale,
    registerPendingApprovalAds,
    registerDisputeAds,
    registerLoadCurrentUserMessages,
  } = useRematchDispatch((dispatch) => ({
    loadUser: dispatch.user.loadUser,
    toggleLocale: dispatch.authentication.toggleLocale,
    registerPendingApprovalAds: dispatch.ads.registerPendingApprovalAds,
    registerDisputeAds: dispatch.ads.registerDisputeAds,
    registerLoadCurrentUserMessages: dispatch.inbox.registerLoadCurrentUserMessages,
  }));

  useEffect(() => {
    if (loggedInUser) {
      if (!user || (user && user.uid !== loggedInUser.uid)) {
        loadUser({ uid: loggedInUser.uid });
      }
    }
  }, [loggedInUser, loadUser, user]);

  useEffect(() => {
    if (user) {
      registerPendingApprovalAds({ pendingApprovalItems: [...pendingOwnerItems, ...pendingUserItems] });
      registerDisputeAds({ disputeItems: [...disputeItemsOwner, ...disputeItemsUser] });
      registerLoadCurrentUserMessages();
      toggleLocale({ from: 'loader', value: user.language });
    }
  }, [
    user,
    toggleLocale,
    pendingOwnerItems,
    pendingUserItems,
    disputeItemsOwner,
    disputeItemsUser,
    registerPendingApprovalAds,
    registerDisputeAds,
    registerLoadCurrentUserMessages,
  ]);

  return <></>;
};

export default UserLoader;
