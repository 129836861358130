const WELCOME = '/';
const DASHBOARD = '/dashboard';
const LOGIN = '/login';
const SEARCH_RESULTS = '/search';
const POST_AD = '/postad';
const EDIT_AD = '/ad/:adId';
const RENT = '/rent/:adId';
const COMPLETE = '/complete/:rentId';
const RETURN = '/return/:rentId';
const INBOX = '/inbox';
const INBOX_SPECIFIC = '/inbox/:threadId';
const PAY_WALL = '/pay/:rentId';
const EDIT_PROFILE = '/profile';
const PROFILE_CREATED = '/profile_created';
const CONFIRM_RENT = '/rent/:rentId/confirm';
const PROFILE_VIEW = '/profile/:userId';
const DISPUTE = '/rent/:rentId/dispute';
const CANCEL_RENTAL = '/rent/:rentId/cancel';
const HELP = '/help';
const BOOKING_VIEW = '/booking/:rentId';
const MAINTENANCE = '/maintenance';

export {
  WELCOME,
  DASHBOARD,
  LOGIN,
  SEARCH_RESULTS,
  POST_AD,
  RENT,
  COMPLETE,
  EDIT_AD,
  INBOX,
  INBOX_SPECIFIC,
  PAY_WALL,
  EDIT_PROFILE,
  PROFILE_CREATED,
  CONFIRM_RENT,
  RETURN,
  PROFILE_VIEW,
  DISPUTE,
  CANCEL_RENTAL,
  HELP,
  BOOKING_VIEW,
  MAINTENANCE,
};
