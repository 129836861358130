import React from 'react';
import PropTypes from 'prop-types';
import BillInformationLine from './billInformationLine';
import { useTranslation } from 'react-i18next';

const OwnerBillingInformation = ({ ownerPayout, isCancelled, cancelledRefundAmount }) => {
  const { t } = useTranslation();
  const { price, charge, serviceFee, payment } = ownerPayout;

  const hasOvercharge = () => {
    return ownerPayout && ownerPayout.charge > 0;
  };

  const renderPayoutAmount = () => {
    if (isCancelled) {
      return <BillInformationLine price={cancelledRefundAmount} title={t('complete_payout_payment')} color={'error'} />;
    }
    return <BillInformationLine price={payment} title={t('complete_payout_payment')} color={'error'} />;
  };

  return (
    <>
      <BillInformationLine price={price} title={t('complete_payout_price')} />
      {hasOvercharge() && <BillInformationLine price={charge} title={t('complete_payout_charge')} />}
      <BillInformationLine price={serviceFee} title={t('complete_payout_servicefee')} />
      {renderPayoutAmount()}
    </>
  );
};

OwnerBillingInformation.propTypes = {
  ownerPayout: PropTypes.object.isRequired,
  isCancelled: PropTypes.bool,
  cancelledRefundAmount: PropTypes.number,
};

OwnerBillingInformation.defaultProps = {
  isCancelled: false,
  cancelledRefundAmount: 0,
};

export default OwnerBillingInformation;
